import { createModule } from "@/di/module";
import { PackagingModuleId } from "@/app/modules/packaging/packaging.module";
import { SubModuleModule } from "@/app/modular-content/modules/sub-module.module";
import { suffixComponentName } from "@/app/modular-content/functions/suffix-component-name";
import { TranslationPrefixToken } from "@/app/translation/tokens/translation-prefix.token";
import EditPalletsComponent from "@/app/sub-modules/pallets/components/edit-pallets.component.vue";
import DisplayPalletsComponent from "@/app/sub-modules/pallets/components/display-pallets.component.vue";
import DisplayPalletsViewComponent from "@/app/sub-modules/pallets/components/display-pallets-view.component.vue";
export const PalletsSubModule = (domainId) => {
    const id = "pallets";
    const moduleId = PackagingModuleId;
    const componentSuffixes = [domainId, moduleId, id];
    const editComponent = suffixComponentName(EditPalletsComponent, ...componentSuffixes);
    const displayComponent = suffixComponentName(DisplayPalletsComponent, ...componentSuffixes);
    const displayViewComponent = suffixComponentName(DisplayPalletsViewComponent, ...componentSuffixes);
    return createModule({
        components: [editComponent, displayComponent, displayViewComponent],
        imports: [
            SubModuleModule({
                id,
                moduleId,
                domainId,
                icon: "dashboard",
                dataLocalised: true,
                components: {
                    edit: editComponent,
                    display: displayComponent,
                    displayView: displayViewComponent,
                },
            }),
        ],
        providers: [{ token: TranslationPrefixToken, useValue: "sub-modules.pallets" }],
    });
};
