var _a;
import { __decorate, __metadata } from "tslib";
import { service } from "@/di/decorators/service.decorator";
import { DomainRouteService } from "@/app/modular-content/services/domain-route.service";
let SelectedULPFilterService = class SelectedULPFilterService {
    routeService;
    constructor(routeService) {
        this.routeService = routeService;
    }
    createEqualsFilter() {
        return [
            {
                propertyName: "UnloadingPointId",
                propertyValue: this.routeService.getCurrentEntityId(),
            },
        ];
    }
};
SelectedULPFilterService = __decorate([
    service(),
    __metadata("design:paramtypes", [typeof (_a = typeof DomainRouteService !== "undefined" && DomainRouteService) === "function" ? _a : Object])
], SelectedULPFilterService);
export { SelectedULPFilterService };
