import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, resolveDynamicComponent as _resolveDynamicComponent, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-579331c4"), n = n(), _popScopeId(), n);
const _hoisted_1 = ["data-qa-id"];
const _hoisted_2 = {
    key: 0,
    class: "configure-submodules"
};
const _hoisted_3 = {
    key: 0,
    class: "enabled-submodules"
};
const _hoisted_4 = {
    key: 2,
    class: "no-submodules"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_LoadingSpinner = _resolveComponent("LoadingSpinner");
    const _component_Icon = _resolveComponent("Icon");
    const _component_AppButton = _resolveComponent("AppButton");
    const _component_NoPermissionsInfo = _resolveComponent("NoPermissionsInfo");
    const _component_CollapsiblePanel = _resolveComponent("CollapsiblePanel");
    return (_openBlock(), _createElementBlock("div", {
        class: "sub-modules-edit",
        "data-qa-id": _ctx.$options.name
    }, [
        (_ctx.isLoading)
            ? (_openBlock(), _createBlock(_component_LoadingSpinner, {
                key: 0,
                class: "loader",
                center: ""
            }))
            : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                (!_ctx.hasTemporaryMode && _ctx.someOptional && !_ctx.isForbidden(_ctx.errorResponse))
                    ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                        (!_ctx.inheritsSubmodules)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.translate("enabled-count", { enabled: _ctx.enabledSubmodules, total: _ctx.submodules.length })), 1))
                            : _createCommentVNode("", true),
                        (!_ctx.inheritsSubmodules)
                            ? (_openBlock(), _createBlock(_component_AppButton, {
                                key: 1,
                                class: "p-button-secondary",
                                "data-qa-id": "configure-sub-modules-button",
                                onClick: _ctx.configureSubModules
                            }, {
                                default: _withCtx(() => [
                                    _createVNode(_component_Icon, { name: "settings" }),
                                    _createTextVNode(" " + _toDisplayString(_ctx.translate("buttons.configure")), 1)
                                ]),
                                _: 1
                            }, 8, ["onClick"]))
                            : _createCommentVNode("", true)
                    ]))
                    : _createCommentVNode("", true),
                (_ctx.isForbidden(_ctx.errorResponse))
                    ? (_openBlock(), _createBlock(_component_NoPermissionsInfo, { key: 1 }))
                    : (_ctx.areSubmodulesDisabled())
                        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                            _createElementVNode("h3", null, _toDisplayString(_ctx.translate("no-submodule.header")), 1),
                            _createElementVNode("p", null, _toDisplayString(_ctx.translate("no-submodule.description")), 1),
                            _createVNode(_component_AppButton, {
                                "data-qa-id": "configure-sub-modules-button",
                                onClick: _ctx.configureSubModules
                            }, {
                                default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.translate("buttons.add-submodule")), 1)
                                ]),
                                _: 1
                            }, 8, ["onClick"])
                        ]))
                        : (_openBlock(true), _createElementBlock(_Fragment, { key: 3 }, _renderList(_ctx.submodules, (submodule) => {
                            return (_openBlock(), _createElementBlock(_Fragment, {
                                key: submodule.getMetadata().id
                            }, [
                                (_ctx.isEnabled(submodule.getMetadata().id) && !_ctx.hiddenState[submodule.getSubModuleId()])
                                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                        (!_ctx.isConfirmActive(submodule))
                                            ? (_openBlock(), _createBlock(_component_CollapsiblePanel, {
                                                key: 0,
                                                title: _ctx.subModuleTitle(submodule),
                                                "no-header": _ctx.hasTemporaryMode,
                                                publicInfo: submodule.getMetadata().publicInformation && !_ctx.company?.managedByExternalCompany,
                                                subModuleHeaderText: _ctx.subModuleTitle(submodule),
                                                subModuleId: submodule.getMetadata().id,
                                                subModuleDescription: _ctx.subModuleDescription(submodule.getMetadata()),
                                                "data-qa-id": submodule.getMetadata().id
                                            }, {
                                                default: _withCtx(() => [
                                                    (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.component(submodule)), {
                                                        submodule: submodule,
                                                        onUpdate: _ctx.resetHiddenState
                                                    }, null, 40, ["submodule", "onUpdate"]))
                                                ]),
                                                _: 2
                                            }, 1032, ["title", "no-header", "publicInfo", "subModuleHeaderText", "subModuleId", "subModuleDescription", "data-qa-id"]))
                                            : _createCommentVNode("", true)
                                    ], 64))
                                    : _createCommentVNode("", true)
                            ], 64));
                        }), 128))
            ], 64))
    ], 8, _hoisted_1));
}
