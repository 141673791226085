import { toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-7800ccec"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "display-sealing" };
const _hoisted_2 = { class: "section" };
const _hoisted_3 = { class: "row" };
const _hoisted_4 = { class: "col-6 column-header globe-icon-wrapper" };
const _hoisted_5 = { class: "col-6 column-description" };
const _hoisted_6 = {
    key: 0,
    class: "row"
};
const _hoisted_7 = { class: "col-6 offset-6 mt-1 column-description" };
const _hoisted_8 = {
    key: 0,
    class: "section"
};
const _hoisted_9 = { class: "row" };
const _hoisted_10 = { class: "col-6 column-header" };
const _hoisted_11 = { class: "col-6 column-description" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_GlobeIcon = _resolveComponent("GlobeIcon");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
                _createElementVNode("div", _hoisted_4, [
                    _createTextVNode(_toDisplayString(_ctx.translate("sealing-of-vehicle-connections")) + " ", 1),
                    (_ctx.isRequiredInSomeCases)
                        ? (_openBlock(), _createBlock(_component_GlobeIcon, {
                            key: 0,
                            size: "md"
                        }))
                        : _createCommentVNode("", true)
                ]),
                _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.getValue(_ctx.model?.sealingOfVehicleConnections, "SealingOfVehicleConnections")), 1)
            ]),
            (_ctx.isRequiredInSomeCases)
                ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                    _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.getLocaleValue(_ctx.model?.sealingOfVehicleInSomeCases)), 1)
                ]))
                : _createCommentVNode("", true)
        ]),
        (_ctx.sealingRequired)
            ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                _createElementVNode("div", _hoisted_9, [
                    _createElementVNode("div", _hoisted_10, _toDisplayString(_ctx.translate("sealing-executed-by")), 1),
                    _createElementVNode("div", _hoisted_11, _toDisplayString(_ctx.getValue(_ctx.model?.executedBy, "SealingIsDoneByWhom")), 1)
                ])
            ]))
            : _createCommentVNode("", true)
    ]));
}
