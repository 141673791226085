var _a;
import { __decorate, __metadata, __param } from "tslib";
import { service } from "@/di/decorators/service.decorator";
import { ParentDomainIdToken, UrlFactoryToken } from "@/app/modular-content/token/modular-content.token";
import { inject } from "@/di/decorators/inject.decorator";
import { RouterService } from "@/app/shared/services/router.service";
import { UnloadingPointDomainId } from "@/app/domains/unloading-points/unloading-points.constants";
import { LoadingPointDomainId } from "@/app/domains/loading-points/loading-points.constants";
import { AssignedProductClustersListId } from "@/app/domains/assigned-product-clusters/assigned-product-cluster.constants";
import { RouteMetaListId } from "@/app/modular-content/modular-content.constants";
import { AssignedProductsListId } from "@/app/domains/assigned-products/assigned-products.constants";
const ParentDomainSearchUrlEnum = {
    [LoadingPointDomainId]: {
        [AssignedProductsListId]: "loadingpoints/products/search",
        [AssignedProductClustersListId]: "loadingpoints/productclusters/search",
    },
    [UnloadingPointDomainId]: {
        [AssignedProductsListId]: "unloadingpoints/products/search",
        [AssignedProductClustersListId]: "unloadingpoints/productclusters/search",
    },
};
const ParentDomainUrlEnum = {
    [LoadingPointDomainId]: "loadingpoints/products/search",
    [UnloadingPointDomainId]: "unloadingpoints/products/search",
};
let ParentDomainUrlFactoryService = class ParentDomainUrlFactoryService {
    routerService;
    parentDomainId;
    constructor(routerService, parentDomainId) {
        this.routerService = routerService;
        this.parentDomainId = parentDomainId;
    }
    createUrl() {
        const listId = this.routerService.getMeta(RouteMetaListId);
        return ParentDomainSearchUrlEnum[this.parentDomainId]?.[listId] ?? ParentDomainUrlEnum[this.parentDomainId];
    }
};
ParentDomainUrlFactoryService = __decorate([
    service({ token: UrlFactoryToken }),
    __param(1, inject(ParentDomainIdToken)),
    __metadata("design:paramtypes", [typeof (_a = typeof RouterService !== "undefined" && RouterService) === "function" ? _a : Object, String])
], ParentDomainUrlFactoryService);
export { ParentDomainUrlFactoryService };
