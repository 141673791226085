import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-000cee62"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
    class: "products-table-cell-product-name",
    "data-qa-id": "product-info-table-cell"
};
const _hoisted_2 = { class: "content-wrapper" };
const _hoisted_3 = { class: "image-placeholder" };
const _hoisted_4 = { class: "name" };
const _hoisted_5 = {
    class: "label text-wrap",
    "data-qa-id": "name"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_Icon = _resolveComponent("Icon");
    const _component_Id = _resolveComponent("Id");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
                _createVNode(_component_Icon, { name: _ctx.icon }, null, 8, ["name"])
            ]),
            _createElementVNode("div", _hoisted_4, [
                _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.product.name), 1),
                _createVNode(_component_Id, {
                    class: "id",
                    "with-prefix": false
                }, {
                    default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.product.id), 1)
                    ]),
                    _: 1
                })
            ])
        ]),
        _renderSlot(_ctx.$slots, "badge", {}, undefined, true)
    ]));
}
