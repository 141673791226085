import { createModule } from "@/di/module";
import { ModalModule } from "@/app/modals/modules/modal.module";
import { SubModuleModule } from "@/app/modular-content/modules/sub-module.module";
import { suffixComponentName } from "@/app/modular-content/functions/suffix-component-name";
import EditSiteNavigationComponent from "@/app/sub-modules/site-navigation/components/edit-site-navigation.component.vue";
import DisplaySiteNavigationComponent from "@/app/sub-modules/site-navigation/components/display-site-navigation.component.vue";
import AttachmentModalComponent from "@/app/shared/components/attachment-widget/attachment-modal.component.vue";
import { AttachmentModalId } from "@/app/shared/components/attachment-widget/attachment.constants";
import { TranslationPrefixToken } from "@/app/translation/tokens/translation-prefix.token";
import { GeneralInformationModuleId } from "@/app/modules/general-information/general-information.module";
export const SiteNavigationSubModule = (domainId, options) => {
    const id = "siteNavigation";
    const moduleId = GeneralInformationModuleId;
    const componentSuffixes = [domainId, moduleId, id];
    const editComponent = suffixComponentName(EditSiteNavigationComponent, ...componentSuffixes);
    const displayComponent = suffixComponentName(DisplaySiteNavigationComponent, ...componentSuffixes);
    return createModule({
        components: [editComponent, displayComponent, AttachmentModalComponent],
        imports: [
            ModalModule(AttachmentModalId, AttachmentModalComponent),
            SubModuleModule({
                id,
                moduleId,
                domainId,
                icon: "near_me",
                dataLocalised: true,
                publicInformation: options?.publicInformation,
                components: {
                    edit: editComponent,
                    display: displayComponent,
                },
            }),
        ],
        providers: [{ token: TranslationPrefixToken, useValue: "sub-modules.site-navigation" }],
    });
};
