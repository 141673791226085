import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-c193e570"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "display-access-restrictions" };
const _hoisted_2 = { class: "additional-restrictions-container" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_DisplayAccessRestrictionShared = _resolveComponent("DisplayAccessRestrictionShared");
    const _component_EmptyStateMessage = _resolveComponent("EmptyStateMessage");
    const _component_DisplayAttachments = _resolveComponent("DisplayAttachments");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (!_ctx.isEmpty)
            ? (_openBlock(), _createBlock(_component_DisplayAccessRestrictionShared, {
                key: 0,
                "access-restriction": _ctx.model
            }, null, 8, ["access-restriction"]))
            : (_ctx.isEmpty)
                ? (_openBlock(), _createBlock(_component_EmptyStateMessage, { key: 1 }, {
                    default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.translate("empty")), 1)
                    ]),
                    _: 1
                }))
                : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_DisplayAttachments, {
                attachments: _ctx.additionalRestrictionsLocalised,
                title: _ctx.translate('additional-restrictions')
            }, null, 8, ["attachments", "title"])
        ])
    ]));
}
