import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-c951662a"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "display-european-cleaning-document" };
const _hoisted_2 = {
    key: 0,
    class: "column-description mb-0"
};
const _hoisted_3 = { key: 1 };
const _hoisted_4 = { class: "row" };
const _hoisted_5 = { class: "col-6 column-header" };
const _hoisted_6 = { class: "col-6 column-description" };
const _hoisted_7 = { class: "row" };
const _hoisted_8 = { class: "col-6 column-header" };
const _hoisted_9 = { class: "col-6 column-description" };
const _hoisted_10 = { class: "row" };
const _hoisted_11 = { class: "col-6 column-header" };
const _hoisted_12 = { class: "col-6 column-description" };
const _hoisted_13 = { class: "row" };
const _hoisted_14 = { class: "col-6 column-header" };
const _hoisted_15 = { class: "col-6 column-description" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_DisplayAttachments = _resolveComponent("DisplayAttachments");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_ctx.noSpecialRequirements)
            ? (_openBlock(), _createElementBlock("p", _hoisted_2, _toDisplayString(_ctx.translate("phrases.no-special-requirements")), 1))
            : (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createElementVNode("div", _hoisted_4, [
                    _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.translate("if-cleaning-required")), 1),
                    _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.getBooleanInfo(_ctx.model?.isECDMandatory, "yes", "no")), 1)
                ]),
                _createElementVNode("div", _hoisted_7, [
                    _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.translate("ecd-required")), 1),
                    _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.getBooleanInfo(_ctx.model?.ecdRequiredForEachCompartment, "yes", "no")), 1)
                ]),
                _createElementVNode("div", _hoisted_10, [
                    _createElementVNode("div", _hoisted_11, _toDisplayString(_ctx.translate("ecd-not-older-than")), 1),
                    _createElementVNode("div", _hoisted_12, _toDisplayString(_ctx.getSingularOrPluralInfo(_ctx.model?.ecdNotOlderThan, "temporal.hours.label-single", "temporal.hours.label")), 1)
                ]),
                _createElementVNode("div", _hoisted_13, [
                    _createElementVNode("div", _hoisted_14, _toDisplayString(_ctx.translate("ecd-validity")), 1),
                    _createElementVNode("div", _hoisted_15, _toDisplayString(_ctx.getSingularOrPluralInfo(_ctx.model?.ecdValidity, "temporal.days.label-single", "temporal.days.label")), 1)
                ]),
                _createVNode(_component_DisplayAttachments, {
                    attachments: _ctx.filterByLocale(_ctx.model?.ecdAdditionalRequirements),
                    title: _ctx.translate('ecd-additional-requirements')
                }, null, 8, ["attachments", "title"])
            ]))
    ]));
}
