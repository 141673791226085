import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-2c81f252"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "row" };
const _hoisted_2 = { class: "col-12 px-4" };
const _hoisted_3 = { class: "name" };
const _hoisted_4 = { class: "row" };
const _hoisted_5 = { class: "col-12 col-xl-6 px-4" };
const _hoisted_6 = { class: "pb-4" };
const _hoisted_7 = { class: "label" };
const _hoisted_8 = { class: "address-details" };
const _hoisted_9 = {
    key: 0,
    class: "address-postal"
};
const _hoisted_10 = { class: "ms-3" };
const _hoisted_11 = {
    key: 0,
    class: "pb-4"
};
const _hoisted_12 = { class: "label globe-icon-wrapper" };
const _hoisted_13 = { key: 0 };
const _hoisted_14 = { class: "bold" };
const _hoisted_15 = { class: "px-3" };
const _hoisted_16 = { class: "col-12 col-xl-6 px-4 border-left" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_DisplayAddress = _resolveComponent("DisplayAddress");
    const _component_DisplayLocation = _resolveComponent("DisplayLocation");
    const _component_GlobeIcon = _resolveComponent("GlobeIcon");
    const _component_EmptyStateMessage = _resolveComponent("EmptyStateMessage");
    const _component_OpeningHoursTable = _resolveComponent("OpeningHoursTable");
    return (_ctx.model?.length)
        ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.model, (gate) => {
            return (_openBlock(), _createElementBlock("div", {
                key: gate.name,
                class: "display-gates"
            }, [
                _createElementVNode("div", _hoisted_1, [
                    _createElementVNode("div", _hoisted_2, [
                        _createElementVNode("h4", _hoisted_3, _toDisplayString(gate.name), 1)
                    ])
                ]),
                _createElementVNode("div", _hoisted_4, [
                    _createElementVNode("div", _hoisted_5, [
                        _createElementVNode("div", _hoisted_6, [
                            _createElementVNode("h5", _hoisted_7, _toDisplayString(_ctx.translate("address")) + ":", 1),
                            _createElementVNode("div", _hoisted_8, [
                                (gate.postalAddress)
                                    ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                                        _createVNode(_component_DisplayAddress, {
                                            address: gate.postalAddress,
                                            short: "",
                                            formatted: ""
                                        }, null, 8, ["address"])
                                    ]))
                                    : _createCommentVNode("", true),
                                _createElementVNode("div", _hoisted_10, [
                                    _createVNode(_component_DisplayLocation, {
                                        location: gate.location
                                    }, null, 8, ["location"])
                                ])
                            ])
                        ]),
                        (gate?.drivingLanes?.length)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                                _createElementVNode("h5", _hoisted_12, [
                                    _createTextVNode(_toDisplayString(_ctx.translate("lanes")) + " ", 1),
                                    _createVNode(_component_GlobeIcon, { size: "md" })
                                ]),
                                (_ctx.drivingLanesLocalised(gate?.drivingLanes).length)
                                    ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.drivingLanesLocalised(gate?.drivingLanes), (lane) => {
                                            return (_openBlock(), _createElementBlock("div", {
                                                class: "font-style",
                                                key: lane.laneNumber
                                            }, [
                                                _createElementVNode("span", _hoisted_14, _toDisplayString(lane.laneNumber) + ":", 1),
                                                _createElementVNode("span", _hoisted_15, _toDisplayString(lane.lanePurpose), 1)
                                            ]));
                                        }), 128))
                                    ]))
                                    : (_openBlock(), _createBlock(_component_EmptyStateMessage, { key: 1 }, {
                                        default: _withCtx(() => [
                                            _createTextVNode("-")
                                        ]),
                                        _: 1
                                    }))
                            ]))
                            : _createCommentVNode("", true)
                    ]),
                    _createElementVNode("div", _hoisted_16, [
                        (gate.openingHours)
                            ? (_openBlock(), _createBlock(_component_OpeningHoursTable, {
                                key: 0,
                                "opening-hours": gate.openingHours,
                                title: ""
                            }, null, 8, ["opening-hours"]))
                            : _createCommentVNode("", true)
                    ])
                ])
            ]));
        }), 128))
        : (_openBlock(), _createBlock(_component_EmptyStateMessage, { key: 1 }, {
            default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.translate("empty")), 1)
            ]),
            _: 1
        }));
}
