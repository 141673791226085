import { createModule } from "@/di/module";
import { GeneralInformationModuleId } from "@/app/modules/general-information/general-information.module";
import { SubModuleModule } from "@/app/modular-content/modules/sub-module.module";
import { suffixComponentName } from "@/app/modular-content/functions/suffix-component-name";
import { TranslationPrefixToken } from "@/app/translation/tokens/translation-prefix.token";
import EditUlpBasicInformationComponent from "@/app/sub-modules/basic-information/un-loading-point/components/edit-ulp-basic-information.component.vue";
import DisplayUlpBasicInformationComponent from "@/app/sub-modules/basic-information/un-loading-point/components/display-ulp-basic-information.component.vue";
import { BasicInformationSubModuleId } from "@/app/sub-modules/basic-information/basic-information.types";
export const UlpBasicInformationSubModule = (domainId) => {
    const id = BasicInformationSubModuleId;
    const moduleId = GeneralInformationModuleId;
    const componentSuffixes = [domainId, moduleId, id];
    const editComponent = suffixComponentName(EditUlpBasicInformationComponent, ...componentSuffixes);
    const displayComponent = suffixComponentName(DisplayUlpBasicInformationComponent, ...componentSuffixes);
    return createModule({
        components: [editComponent, displayComponent],
        imports: [
            SubModuleModule({
                id,
                moduleId,
                domainId,
                icon: "info",
                hideDisplayTitle: true,
                dataLocalised: false,
                editTitle: "sub-modules.basic-information.un-loading-point.name",
                configurationTranslationPrefix: "sub-modules.basic-information.un-loading-point",
                components: {
                    edit: editComponent,
                    display: displayComponent,
                },
            }),
        ],
        providers: [{ token: TranslationPrefixToken, useValue: "sub-modules.basic-information.un-loading-point" }],
    });
};
