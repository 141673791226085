import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createVNode as _createVNode, withCtx as _withCtx, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-dd952d48"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "edit-site-navigation" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_LoadingSpinner = _resolveComponent("LoadingSpinner");
    const _component_AttachmentsInput = _resolveComponent("AttachmentsInput");
    const _component_AppForm = _resolveComponent("AppForm");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_ctx.isLoading)
            ? (_openBlock(), _createBlock(_component_LoadingSpinner, {
                key: 0,
                center: ""
            }))
            : (_openBlock(), _createBlock(_component_AppForm, {
                key: 1,
                modelValue: _ctx.model,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => ((_ctx.model) = $event))
            }, {
                default: _withCtx(() => [
                    _createVNode(_component_AttachmentsInput, {
                        modelValue: _ctx.model,
                        title: _ctx.translate('title'),
                        name: "siteNavigation",
                        "empty-state-msg": _ctx.translate('empty')
                    }, null, 8, ["modelValue", "title", "empty-state-msg"])
                ]),
                _: 1
            }, 8, ["modelValue"]))
    ]));
}
