import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, renderSlot as _renderSlot, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-65fd46e4"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "radio-button" };
const _hoisted_2 = {
    key: 1,
    class: "radio-wrapper"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_RadioButton = _resolveComponent("RadioButton");
    const _component_InputLabel = _resolveComponent("InputLabel");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_ctx.dictionary)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: _normalizeClass(["radio-wrapper", { 'vertical-display': _ctx.verticalDisplay }])
            }, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.dictionary, (item) => {
                    return (_openBlock(), _createElementBlock("div", {
                        class: "options-wrapper",
                        key: item.id
                    }, [
                        _createVNode(_component_InputLabel, {
                            class: _normalizeClass(["radio-label", { 'next-line': item.id === _ctx.optionSlotDisplayCondition }]),
                            disabled: _ctx.disabled
                        }, {
                            default: _withCtx(() => [
                                _createVNode(_component_RadioButton, {
                                    class: "radio-input",
                                    value: item.id,
                                    modelValue: _ctx.model,
                                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => ((_ctx.model) = $event)),
                                    disabled: _ctx.disabled,
                                    "data-qa-id": _ctx.dictionaryId,
                                    "data-qa-value": item.id
                                }, null, 8, ["value", "modelValue", "disabled", "data-qa-id", "data-qa-value"]),
                                _createTextVNode(" " + _toDisplayString(_ctx.dictionaryTranslate(_ctx.dictionaryId, item)) + " ", 1),
                                (item.id === _ctx.optionSlotDisplayCondition)
                                    ? _renderSlot(_ctx.$slots, "default", { key: 0 }, undefined, true)
                                    : _createCommentVNode("", true)
                            ]),
                            _: 2
                        }, 1032, ["class", "disabled"])
                    ]));
                }), 128))
            ], 2))
            : (_openBlock(), _createElementBlock("div", _hoisted_2, [
                _createVNode(_component_InputLabel, {
                    class: "radio-label",
                    disabled: _ctx.disabled
                }, {
                    default: _withCtx(() => [
                        _createVNode(_component_RadioButton, {
                            class: "radio-input",
                            name: _ctx.name,
                            value: false,
                            modelValue: _ctx.model,
                            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event) => ((_ctx.model) = $event)),
                            disabled: _ctx.disabled,
                            "data-qa-id": _ctx.name,
                            "data-qa-value": false
                        }, null, 8, ["name", "modelValue", "disabled", "data-qa-id"]),
                        _createTextVNode(" " + _toDisplayString(_ctx.translate(_ctx.falsyLabel)), 1)
                    ]),
                    _: 1
                }, 8, ["disabled"]),
                _createVNode(_component_InputLabel, {
                    class: "radio-label",
                    disabled: _ctx.disabled
                }, {
                    default: _withCtx(() => [
                        _createVNode(_component_RadioButton, {
                            class: "radio-input",
                            name: _ctx.name,
                            value: true,
                            modelValue: _ctx.model,
                            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event) => ((_ctx.model) = $event)),
                            disabled: _ctx.disabled,
                            "data-qa-id": _ctx.name,
                            "data-qa-value": true
                        }, null, 8, ["name", "modelValue", "disabled", "data-qa-id"]),
                        _createTextVNode(" " + _toDisplayString(_ctx.translate(_ctx.truthyLabel)), 1)
                    ]),
                    _: 1
                }, 8, ["disabled"])
            ]))
    ]));
}
