import { suffixComponentName } from "@/app/modular-content/functions/suffix-component-name";
import { SubModuleModule } from "@/app/modular-content/modules/sub-module.module";
import { TranslationPrefixToken } from "@/app/translation/tokens/translation-prefix.token";
import { createModule } from "@/di/module";
import EditContactDataComponent from "@/app/sub-modules/contact-data/components/edit-contact-data.component.vue";
import DisplayContactDataComponent from "@/app/sub-modules/contact-data/components/display-contact-data.component.vue";
import { GeneralInformationModuleId } from "@/app/modules/general-information/general-information.module";
import { ContactDataSubModuleId } from "@/app/sub-modules/contact-data/contact-data.constants";
export const ContactDataSubModule = (domainId, options) => {
    const id = ContactDataSubModuleId;
    const moduleId = GeneralInformationModuleId;
    const componentSuffixes = [domainId, moduleId, id];
    const editComponent = suffixComponentName(EditContactDataComponent, ...componentSuffixes);
    const displayComponent = suffixComponentName(DisplayContactDataComponent, ...componentSuffixes);
    return createModule({
        components: [editComponent, displayComponent],
        imports: [
            SubModuleModule({
                id,
                moduleId,
                domainId,
                icon: "phone_enabled",
                dataLocalised: false,
                editGuards: options?.editGuards,
                components: {
                    edit: editComponent,
                    display: displayComponent,
                },
            }),
        ],
        providers: [
            {
                token: TranslationPrefixToken,
                useValue: "sub-modules.contact-data",
            },
        ],
    });
};
