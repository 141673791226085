import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-636224da"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "edit-check-in" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_LoadingSpinner = _resolveComponent("LoadingSpinner");
    const _component_CheckInInput = _resolveComponent("CheckInInput");
    const _component_CheckInInputList = _resolveComponent("CheckInInputList");
    const _component_AppForm = _resolveComponent("AppForm");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_ctx.isLoading)
            ? (_openBlock(), _createBlock(_component_LoadingSpinner, {
                key: 0,
                center: ""
            }))
            : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                (_ctx.temporaryMode)
                    ? (_openBlock(), _createBlock(_component_CheckInInput, {
                        key: 0,
                        dictionaries: _ctx.dictionaries,
                        errors: _ctx.errors
                    }, null, 8, ["dictionaries", "errors"]))
                    : _createCommentVNode("", true),
                _createElementVNode("div", {
                    class: _normalizeClass({ 'temporary-mode': _ctx.temporaryMode })
                }, [
                    _createVNode(_component_AppForm, {
                        modelValue: _ctx.model,
                        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event) => ((_ctx.model) = $event)),
                        initial: _ctx.initial
                    }, {
                        default: _withCtx(() => [
                            _createVNode(_component_CheckInInputList, {
                                name: "checkIns",
                                modelValue: _ctx.model.checkIns,
                                "onUpdate:modelValue": [
                                    _cache[0] || (_cache[0] = ($event) => ((_ctx.model.checkIns) = $event)),
                                    _cache[1] || (_cache[1] = ($event) => (_ctx.model.checkIns = $event))
                                ],
                                id: _ctx.confirmId,
                                dictionaries: _ctx.dictionaries
                            }, null, 8, ["modelValue", "id", "dictionaries"])
                        ]),
                        _: 1
                    }, 8, ["modelValue", "initial"])
                ], 2)
            ], 64))
    ]));
}
