import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-4cb76523"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "display-pallets" };
const _hoisted_2 = {
    key: 0,
    class: "column-description mb-0"
};
const _hoisted_3 = { class: "row" };
const _hoisted_4 = { class: "col-6 column-header" };
const _hoisted_5 = { class: "col-6 column-description" };
const _hoisted_6 = {
    key: 0,
    class: "row"
};
const _hoisted_7 = { class: "col-6 column-header" };
const _hoisted_8 = { class: "col-6 column-description" };
const _hoisted_9 = { class: "row" };
const _hoisted_10 = { class: "col-6 column-header" };
const _hoisted_11 = { class: "col-6 column-description" };
const _hoisted_12 = { class: "row" };
const _hoisted_13 = { class: "col-6 column-header" };
const _hoisted_14 = { class: "col-6 column-description" };
const _hoisted_15 = { class: "row" };
const _hoisted_16 = { class: "col-6 column-header" };
const _hoisted_17 = { class: "col-6 column-description" };
const _hoisted_18 = { class: "row" };
const _hoisted_19 = { class: "col-6 column-header" };
const _hoisted_20 = { class: "col-6 column-description" };
const _hoisted_21 = { class: "row" };
const _hoisted_22 = { class: "col-6 column-header" };
const _hoisted_23 = { class: "col-6 column-description" };
const _hoisted_24 = { class: "row" };
const _hoisted_25 = { class: "col-6 column-header" };
const _hoisted_26 = { class: "col-6 column-description" };
const _hoisted_27 = { class: "row" };
const _hoisted_28 = { class: "col-6 column-header" };
const _hoisted_29 = { class: "col-6 column-description" };
const _hoisted_30 = { class: "row" };
const _hoisted_31 = { class: "col-6 column-header" };
const _hoisted_32 = { class: "col-6 column-description" };
const _hoisted_33 = {
    key: 1,
    class: "row"
};
const _hoisted_34 = { class: "col-6 column-header" };
const _hoisted_35 = { class: "col-6 column-description" };
const _hoisted_36 = { class: "row" };
const _hoisted_37 = { class: "col-6 column-header" };
const _hoisted_38 = { class: "col-6 column-description" };
const _hoisted_39 = { class: "row mb-0" };
const _hoisted_40 = { class: "col-6 column-header" };
const _hoisted_41 = { class: "col-6 column-description" };
const _hoisted_42 = {
    key: 2,
    class: "row"
};
const _hoisted_43 = { class: "col-6 offset-6 column-value" };
const _hoisted_44 = { class: "row" };
const _hoisted_45 = { class: "col-6 column-header globe-icon-wrapper" };
const _hoisted_46 = { class: "col-6 column-description" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_GlobeIcon = _resolveComponent("GlobeIcon");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_ctx.noSpecialRequirements)
            ? (_openBlock(), _createElementBlock("p", _hoisted_2, _toDisplayString(_ctx.translate("no-special-pallet-requirements")), 1))
            : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                _createElementVNode("div", _hoisted_3, [
                    _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.translate("pallet-type")), 1),
                    _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.getValue(_ctx.model?.palletType, "PalletType")), 1)
                ]),
                (_ctx.isChemistrySelected)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                        _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.translate("chemistry-pallet")), 1),
                        _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.getValue(_ctx.model?.chemistryPallet, "ChemistryPallet")), 1)
                    ]))
                    : _createCommentVNode("", true),
                _createElementVNode("div", _hoisted_9, [
                    _createElementVNode("div", _hoisted_10, _toDisplayString(_ctx.translate("instructions-for-empty-pallets")), 1),
                    _createElementVNode("div", _hoisted_11, _toDisplayString(_ctx.getValue(_ctx.model?.instructionsForEmptyPallets, _ctx.dictionaryId)), 1)
                ]),
                _createElementVNode("div", _hoisted_12, [
                    _createElementVNode("div", _hoisted_13, _toDisplayString(_ctx.translate("IPPC-treatment")), 1),
                    _createElementVNode("div", _hoisted_14, _toDisplayString(_ctx.getBooleanInfo(_ctx.model?.ippcTreatment, "required", "not-required")), 1)
                ]),
                _createElementVNode("div", _hoisted_15, [
                    _createElementVNode("div", _hoisted_16, _toDisplayString(_ctx.translate("pallet-material")), 1),
                    _createElementVNode("div", _hoisted_17, _toDisplayString(_ctx.palletMaterialInfo()), 1)
                ]),
                _createElementVNode("div", _hoisted_18, [
                    _createElementVNode("div", _hoisted_19, _toDisplayString(_ctx.translate("new-pallets")), 1),
                    _createElementVNode("div", _hoisted_20, _toDisplayString(_ctx.getBooleanInfo(_ctx.model?.newPallets, "required", "not-required")), 1)
                ]),
                _createElementVNode("div", _hoisted_21, [
                    _createElementVNode("div", _hoisted_22, _toDisplayString(_ctx.translate("required-weight-per-pallet")), 1),
                    _createElementVNode("div", _hoisted_23, _toDisplayString(_ctx.valueWithUnit(_ctx.model?.requiredWeightPerPallet, "kg")), 1)
                ]),
                _createElementVNode("div", _hoisted_24, [
                    _createElementVNode("div", _hoisted_25, _toDisplayString(_ctx.translate("maximum-pallet-height")), 1),
                    _createElementVNode("div", _hoisted_26, _toDisplayString(_ctx.valueWithUnit(_ctx.model?.maximumHeight, "m")), 1)
                ]),
                _createElementVNode("div", _hoisted_27, [
                    _createElementVNode("div", _hoisted_28, _toDisplayString(_ctx.translate("free-space-between")), 1),
                    _createElementVNode("div", _hoisted_29, _toDisplayString(_ctx.valueWithUnit(_ctx.model?.freeSpaceBetweenFirstPalletAndTruckDoor, "m")), 1)
                ]),
                _createElementVNode("div", _hoisted_30, [
                    _createElementVNode("div", _hoisted_31, _toDisplayString(_ctx.translate("double-layer-loading")), 1),
                    _createElementVNode("div", _hoisted_32, _toDisplayString(_ctx.getBooleanInfo(_ctx.model?.doubleLayerLoading, "allowed", "not-allowed")), 1)
                ]),
                (_ctx.isDoubleLayerAllowed)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_33, [
                        _createElementVNode("div", _hoisted_34, _toDisplayString(_ctx.translate("second-layer-must-be-belted")), 1),
                        _createElementVNode("div", _hoisted_35, _toDisplayString(_ctx.getBooleanInfo(_ctx.model?.secondLayerMustBeBelted, "yes", "no")), 1)
                    ]))
                    : _createCommentVNode("", true),
                _createElementVNode("div", _hoisted_36, [
                    _createElementVNode("div", _hoisted_37, _toDisplayString(_ctx.translate("shrink-hood-pallet-securing")), 1),
                    _createElementVNode("div", _hoisted_38, _toDisplayString(_ctx.getBooleanInfo(_ctx.model?.shrinkHodPalletSecuring, "required", "not-required")), 1)
                ]),
                _createElementVNode("div", _hoisted_39, [
                    _createElementVNode("div", _hoisted_40, _toDisplayString(_ctx.translate("pallet-stretching")), 1),
                    _createElementVNode("div", _hoisted_41, _toDisplayString(_ctx.getBooleanInfo(_ctx.model?.palletStretching, "required", "not-required")), 1)
                ]),
                (_ctx.isPalletStretchingRequired)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_42, [
                        _createElementVNode("div", _hoisted_43, _toDisplayString(_ctx.getValue(_ctx.model?.stretchFilmType, "StretchFilmType")), 1)
                    ]))
                    : _createCommentVNode("", true),
                _createElementVNode("div", _hoisted_44, [
                    _createElementVNode("div", _hoisted_45, [
                        _createTextVNode(_toDisplayString(_ctx.translate("other-instructions")) + " ", 1),
                        _createVNode(_component_GlobeIcon, { size: "md" })
                    ]),
                    _createElementVNode("div", _hoisted_46, _toDisplayString(_ctx.getLocaleValue(_ctx.model?.otherInstructions)), 1)
                ])
            ], 64))
    ]));
}
