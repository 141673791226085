import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-135085b5"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "edit-preload-confirmation" };
const _hoisted_2 = { key: 0 };
const _hoisted_3 = { class: "row" };
const _hoisted_4 = { class: "col-xl-7" };
const _hoisted_5 = { class: "d-flex" };
const _hoisted_6 = { class: "col-6" };
const _hoisted_7 = { class: "col-6" };
const _hoisted_8 = { class: "row" };
const _hoisted_9 = { class: "col-xl-7" };
const _hoisted_10 = { class: "d-flex" };
const _hoisted_11 = { class: "col-6" };
const _hoisted_12 = { class: "col-6" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_LoadingSpinner = _resolveComponent("LoadingSpinner");
    const _component_AppCheckbox = _resolveComponent("AppCheckbox");
    const _component_FormField = _resolveComponent("FormField");
    const _component_InputLabel = _resolveComponent("InputLabel");
    const _component_RadioButton = _resolveComponent("RadioButton");
    const _component_InputNumber = _resolveComponent("InputNumber");
    const _component_FormError = _resolveComponent("FormError");
    const _component_AttachmentsInput = _resolveComponent("AttachmentsInput");
    const _component_AppForm = _resolveComponent("AppForm");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_ctx.isLoading)
            ? (_openBlock(), _createBlock(_component_LoadingSpinner, {
                key: 0,
                center: ""
            }))
            : (_openBlock(), _createBlock(_component_AppForm, {
                key: 1,
                modelValue: _ctx.model,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event) => ((_ctx.model) = $event)),
                initial: _ctx.initial,
                errors: _ctx.errors
            }, {
                default: _withCtx(() => [
                    _createVNode(_component_FormField, { class: "checkboxes" }, {
                        default: _withCtx(() => [
                            _createVNode(_component_AppCheckbox, { name: "noSpecialRequirements" }, {
                                default: _withCtx(() => [
                                    _createElementVNode("label", null, _toDisplayString(_ctx.translate("phrases.no-special-requirements")), 1)
                                ]),
                                _: 1
                            })
                        ]),
                        _: 1
                    }),
                    (!_ctx.noSpecialRequirements)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                            _createVNode(_component_InputLabel, null, {
                                default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.translate("pre-load-confirmation")), 1)
                                ]),
                                _: 1
                            }),
                            _createVNode(_component_FormField, null, {
                                default: _withCtx(() => [
                                    _createVNode(_component_RadioButton, {
                                        modelValue: _ctx.model.preLoadConfirmation,
                                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => ((_ctx.model.preLoadConfirmation) = $event)),
                                        truthyLabel: "phrases.required",
                                        falsyLabel: "phrases.not-required"
                                    }, null, 8, ["modelValue"])
                                ]),
                                _: 1
                            }),
                            _createElementVNode("div", _hoisted_3, [
                                _createElementVNode("div", _hoisted_4, [
                                    _createElementVNode("div", _hoisted_5, [
                                        _createElementVNode("div", _hoisted_6, [
                                            _createVNode(_component_InputLabel, null, {
                                                default: _withCtx(() => [
                                                    _createTextVNode(_toDisplayString(_ctx.translate("pre-load-not-older-than")), 1)
                                                ]),
                                                _: 1
                                            })
                                        ]),
                                        _createElementVNode("div", _hoisted_7, [
                                            _createVNode(_component_InputLabel, null, {
                                                default: _withCtx(() => [
                                                    _createTextVNode(_toDisplayString(_ctx.translate("pre-load-validity")), 1)
                                                ]),
                                                _: 1
                                            })
                                        ])
                                    ])
                                ])
                            ]),
                            _createElementVNode("div", _hoisted_8, [
                                _createElementVNode("div", _hoisted_9, [
                                    _createElementVNode("div", _hoisted_10, [
                                        _createVNode(_component_FormField, { class: "d-flex col-6" }, {
                                            default: _withCtx(() => [
                                                _createElementVNode("div", _hoisted_11, [
                                                    _createVNode(_component_InputNumber, {
                                                        name: "preLoadConfirmationNotOlderThan",
                                                        mode: "decimal",
                                                        min: 0,
                                                        maxIntegerDigits: 2,
                                                        maxFractionDigits: 2,
                                                        placeholder: _ctx.translate('temporal.hours.placeholder')
                                                    }, null, 8, ["placeholder"]),
                                                    _createVNode(_component_FormError, { name: "preLoadConfirmationNotOlderThan" })
                                                ]),
                                                _createVNode(_component_InputLabel, { class: "input-side-label" }, {
                                                    default: _withCtx(() => [
                                                        _createTextVNode(_toDisplayString(_ctx.translate("temporal.hours.label")), 1)
                                                    ]),
                                                    _: 1
                                                })
                                            ]),
                                            _: 1
                                        }),
                                        _createVNode(_component_FormField, { class: "d-flex col-6" }, {
                                            default: _withCtx(() => [
                                                _createElementVNode("div", _hoisted_12, [
                                                    _createVNode(_component_InputNumber, {
                                                        name: "preLoadConfirmationValidity",
                                                        mode: "decimal",
                                                        min: 0,
                                                        maxIntegerDigits: 2,
                                                        maxFractionDigits: 2,
                                                        placeholder: _ctx.translate('temporal.days.placeholder')
                                                    }, null, 8, ["placeholder"]),
                                                    _createVNode(_component_FormError, { name: "preLoadConfirmationValidity" })
                                                ]),
                                                _createVNode(_component_InputLabel, { class: "input-side-label" }, {
                                                    default: _withCtx(() => [
                                                        _createTextVNode(_toDisplayString(_ctx.translate("temporal.days.label")), 1)
                                                    ]),
                                                    _: 1
                                                })
                                            ]),
                                            _: 1
                                        })
                                    ])
                                ])
                            ]),
                            _createVNode(_component_AttachmentsInput, {
                                class: "mt-0",
                                attachments: _ctx.model.preLoadConfirmationAdditionalRequirements,
                                title: _ctx.translate('preload-additional-requirements'),
                                name: "preLoadConfirmationAdditionalRequirements"
                            }, null, 8, ["attachments", "title"])
                        ]))
                        : _createCommentVNode("", true)
                ]),
                _: 1
            }, 8, ["modelValue", "initial", "errors"]))
    ]));
}
