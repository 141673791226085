import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-8f823c30"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "display-shipment-pre-notification" };
const _hoisted_2 = { class: "section" };
const _hoisted_3 = { class: "row" };
const _hoisted_4 = { class: "col-6 column-header" };
const _hoisted_5 = { class: "col-6 bold-text" };
const _hoisted_6 = { key: 0 };
const _hoisted_7 = { class: "row info-row" };
const _hoisted_8 = { class: "col-6 column-header" };
const _hoisted_9 = { class: "col-6 bold-text" };
const _hoisted_10 = { class: "row info-row" };
const _hoisted_11 = { class: "col-6 column-header" };
const _hoisted_12 = { class: "col-6 column-body" };
const _hoisted_13 = { class: "row" };
const _hoisted_14 = { class: "col-2 column-header" };
const _hoisted_15 = { class: "col-2 bold-text" };
const _hoisted_16 = { class: "row" };
const _hoisted_17 = { class: "col-2 column-header" };
const _hoisted_18 = { class: "col-2" };
const _hoisted_19 = ["href"];
const _hoisted_20 = {
    key: 1,
    class: "bold-text"
};
const _hoisted_21 = { class: "row" };
const _hoisted_22 = { class: "col-2 column-header" };
const _hoisted_23 = { class: "col-2 bold-text" };
const _hoisted_24 = { class: "row info-row" };
const _hoisted_25 = { class: "col-6 column-header" };
const _hoisted_26 = { class: "col-6" };
const _hoisted_27 = ["href"];
const _hoisted_28 = {
    key: 1,
    class: "bold-text"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("h5", null, _toDisplayString(_ctx.translate("shipment-pre-notification-title")), 1),
        _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
                _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.translate("shipment-pre-notification")), 1),
                _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.requiredOption), 1)
            ]),
            (_ctx.isOptionRequired)
                ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                    _createElementVNode("div", _hoisted_7, [
                        _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.translate("delivery-latest-pre-notification")), 1),
                        _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.data?.hoursPriorToULP ? `${_ctx.data.hoursPriorToULP} ${_ctx.translate("hours")}` : "-"), 1)
                    ]),
                    _createElementVNode("div", _hoisted_10, [
                        _createElementVNode("div", _hoisted_11, _toDisplayString(_ctx.translate("delivery-pre-notification-via")), 1),
                        _createElementVNode("div", _hoisted_12, [
                            _createElementVNode("div", _hoisted_13, [
                                _createElementVNode("div", _hoisted_14, _toDisplayString(_ctx.translate("form-fields.telephone.label")), 1),
                                _createElementVNode("div", _hoisted_15, _toDisplayString(_ctx.data?.deliveryVia?.telephone || "-"), 1)
                            ]),
                            _createElementVNode("div", _hoisted_16, [
                                _createElementVNode("div", _hoisted_17, _toDisplayString(_ctx.translate("email")), 1),
                                _createElementVNode("div", _hoisted_18, [
                                    (_ctx.data?.deliveryVia?.email)
                                        ? (_openBlock(), _createElementBlock("a", {
                                            key: 0,
                                            href: `mailto:${_ctx.data.deliveryVia.email}`,
                                            target: "_blank",
                                            class: "url"
                                        }, _toDisplayString(_ctx.data.deliveryVia.email), 9, _hoisted_19))
                                        : (_openBlock(), _createElementBlock("span", _hoisted_20, "-"))
                                ])
                            ]),
                            _createElementVNode("div", _hoisted_21, [
                                _createElementVNode("div", _hoisted_22, _toDisplayString(_ctx.translate("fax")), 1),
                                _createElementVNode("div", _hoisted_23, _toDisplayString(_ctx.data?.deliveryVia?.faxNumber || "-"), 1)
                            ])
                        ])
                    ]),
                    _createElementVNode("div", _hoisted_24, [
                        _createElementVNode("div", _hoisted_25, _toDisplayString(_ctx.translate("shipment-documents")), 1),
                        _createElementVNode("div", _hoisted_26, [
                            (_ctx.data?.emailForDocuments)
                                ? (_openBlock(), _createElementBlock("a", {
                                    key: 0,
                                    href: `mailto:${_ctx.data.emailForDocuments}`,
                                    target: "_blank",
                                    class: "url"
                                }, _toDisplayString(_ctx.data.emailForDocuments), 9, _hoisted_27))
                                : (_openBlock(), _createElementBlock("span", _hoisted_28, "-"))
                        ])
                    ])
                ]))
                : _createCommentVNode("", true)
        ])
    ]));
}
