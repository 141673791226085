import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, withCtx as _withCtx } from "vue";
const _hoisted_1 = {
    key: 1,
    class: "summary"
};
const _hoisted_2 = { class: "sub-module-section" };
const _hoisted_3 = {
    key: 0,
    class: "sub-module-section"
};
const _hoisted_4 = {
    key: 1,
    class: "sub-module-section"
};
const _hoisted_5 = {
    key: 2,
    class: "sub-module-section"
};
const _hoisted_6 = {
    key: 3,
    class: "sub-module-section"
};
const _hoisted_7 = {
    key: 4,
    class: "sub-module-section"
};
const _hoisted_8 = {
    key: 5,
    class: "sub-module-section"
};
const _hoisted_9 = {
    key: 0,
    class: "sub-module-section"
};
const _hoisted_10 = {
    key: 1,
    class: "sub-module-section"
};
const _hoisted_11 = { class: "sub-module-section" };
const _hoisted_12 = { class: "sub-module-section" };
const _hoisted_13 = {
    key: 0,
    class: "sub-module-section"
};
const _hoisted_14 = {
    key: 1,
    class: "sub-module-section"
};
const _hoisted_15 = {
    key: 2,
    class: "sub-module-section"
};
const _hoisted_16 = {
    key: 3,
    class: "sub-module-section"
};
const _hoisted_17 = {
    key: 4,
    class: "sub-module-section"
};
const _hoisted_18 = {
    key: 5,
    class: "sub-module-section"
};
const _hoisted_19 = { class: "sub-module-section" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_LoadingSpinner = _resolveComponent("LoadingSpinner");
    const _component_DisplaySiteInformation = _resolveComponent("DisplaySiteInformation");
    const _component_SubModuleTitle = _resolveComponent("SubModuleTitle");
    const _component_DisplayPostalAddressView = _resolveComponent("DisplayPostalAddressView");
    const _component_DisplaySiteNavigationView = _resolveComponent("DisplaySiteNavigationView");
    const _component_DisplayAccessRestrictionsView = _resolveComponent("DisplayAccessRestrictionsView");
    const _component_DisplayOpeningHoursView = _resolveComponent("DisplayOpeningHoursView");
    const _component_DisplaySiteAdditionalInformationView = _resolveComponent("DisplaySiteAdditionalInformationView");
    const _component_DisplayProcessesView = _resolveComponent("DisplayProcessesView");
    const _component_ModuleSectionPanel = _resolveComponent("ModuleSectionPanel");
    const _component_DisplayParkingSpaceBasicView = _resolveComponent("DisplayParkingSpaceBasicView");
    const _component_DisplayInfrastructuresNearbyView = _resolveComponent("DisplayInfrastructuresNearbyView");
    const _component_DisplayCheckInView = _resolveComponent("DisplayCheckInView");
    const _component_DisplayGatesView = _resolveComponent("DisplayGatesView");
    const _component_DisplayGeneralSafetyRulesView = _resolveComponent("DisplayGeneralSafetyRulesView");
    const _component_DisplayTrafficRelatedRulesView = _resolveComponent("DisplayTrafficRelatedRulesView");
    const _component_DisplayVehicleAndPPESitesView = _resolveComponent("DisplayVehicleAndPPESitesView");
    const _component_DisplaySafetyTestView = _resolveComponent("DisplaySafetyTestView");
    const _component_DisplayAlarmsAndEmergencyView = _resolveComponent("DisplayAlarmsAndEmergencyView");
    const _component_DisplaySiteIncidentManagementView = _resolveComponent("DisplaySiteIncidentManagementView");
    const _component_DisplayWeighingBridgesView = _resolveComponent("DisplayWeighingBridgesView");
    return (_ctx.isLoading)
        ? (_openBlock(), _createBlock(_component_LoadingSpinner, {
            key: 0,
            center: ""
        }))
        : (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createElementVNode("div", null, [
                _createVNode(_component_ModuleSectionPanel, {
                    title: _ctx.translate('modules.general-information.display'),
                    verificationAllowed: _ctx.verificationAllowed,
                    hasFeedback: !!_ctx.feedbackBySection['generalInformation'],
                    hasData: _ctx.hasData(_ctx.model?.generalInformation),
                    onClick: _cache[0] || (_cache[0] = ($event) => (_ctx.flags.generalInformation = true)),
                    onFeedback: _cache[1] || (_cache[1] = ($event) => (_ctx.openSectionFeedback('generalInformation')))
                }, {
                    default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_2, [
                            _createVNode(_component_DisplaySiteInformation, {
                                model: _ctx.model?.generalInformation?.siteInformation
                            }, null, 8, ["model"])
                        ]),
                        (_ctx.model?.generalInformation?.address)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                                _createVNode(_component_SubModuleTitle, {
                                    icon: "place",
                                    headerText: _ctx.translate('sub-modules.address.name'),
                                    subModuleKey: "address"
                                }, null, 8, ["headerText"]),
                                _createVNode(_component_DisplayPostalAddressView, {
                                    model: _ctx.model?.generalInformation?.address
                                }, null, 8, ["model"])
                            ]))
                            : _createCommentVNode("", true),
                        (_ctx.model?.generalInformation?.siteNavigation)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                                _createVNode(_component_SubModuleTitle, {
                                    icon: "access_time",
                                    headerText: _ctx.translate('sub-modules.site-navigation.name'),
                                    subModuleKey: "site-navigation"
                                }, null, 8, ["headerText"]),
                                _createVNode(_component_DisplaySiteNavigationView, {
                                    model: _ctx.model?.generalInformation.siteNavigation
                                }, null, 8, ["model"])
                            ]))
                            : _createCommentVNode("", true),
                        (_ctx.model?.generalInformation?.accessRestrictions)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                                _createVNode(_component_SubModuleTitle, {
                                    icon: "local_shipping",
                                    headerText: _ctx.translate('sub-modules.access-restrictions.name'),
                                    subModuleKey: "access-restrictions"
                                }, null, 8, ["headerText"]),
                                _createVNode(_component_DisplayAccessRestrictionsView, {
                                    model: _ctx.model?.generalInformation?.accessRestrictions
                                }, null, 8, ["model"])
                            ]))
                            : _createCommentVNode("", true),
                        (_ctx.model?.generalInformation?.openingHours)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                                _createVNode(_component_SubModuleTitle, {
                                    icon: "access_time",
                                    headerText: _ctx.translate('sub-modules.opening-hours.sites.name'),
                                    subModuleKey: "opening-hours.sites"
                                }, null, 8, ["headerText"]),
                                _createVNode(_component_DisplayOpeningHoursView, {
                                    model: _ctx.model?.generalInformation?.openingHours,
                                    siteDomainPrefix: ""
                                }, null, 8, ["model"])
                            ]))
                            : _createCommentVNode("", true),
                        (_ctx.model?.generalInformation?.additionalInformation)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                                _createVNode(_component_SubModuleTitle, {
                                    icon: "info",
                                    headerText: _ctx.translate('sub-modules.additional-information.site.name'),
                                    subModuleKey: "additional-information.site"
                                }, null, 8, ["headerText"]),
                                _createVNode(_component_DisplaySiteAdditionalInformationView, {
                                    model: _ctx.model?.generalInformation?.additionalInformation
                                }, null, 8, ["model"])
                            ]))
                            : _createCommentVNode("", true),
                        (_ctx.model?.generalInformation?.processes)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                                _createVNode(_component_SubModuleTitle, {
                                    icon: "apps",
                                    headerText: _ctx.translate('sub-modules.processes.name'),
                                    subModuleKey: "processes"
                                }, null, 8, ["headerText"]),
                                _createVNode(_component_DisplayProcessesView, {
                                    model: _ctx.model?.generalInformation?.processes
                                }, null, 8, ["model"])
                            ]))
                            : _createCommentVNode("", true)
                    ]),
                    _: 1
                }, 8, ["title", "verificationAllowed", "hasFeedback", "hasData"]),
                _createVNode(_component_ModuleSectionPanel, {
                    title: _ctx.translate('modules.facilities.display'),
                    verificationAllowed: _ctx.verificationAllowed,
                    hasFeedback: !!_ctx.feedbackBySection['facilities'],
                    hasData: _ctx.hasData(_ctx.model?.facilities),
                    onClick: _cache[2] || (_cache[2] = ($event) => (_ctx.flags.facilities = true)),
                    onFeedback: _cache[3] || (_cache[3] = ($event) => (_ctx.openSectionFeedback('facilities')))
                }, {
                    default: _withCtx(() => [
                        (_ctx.model?.facilities?.parkingSpaces?.length)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                                _createVNode(_component_SubModuleTitle, {
                                    icon: "local_parking",
                                    headerText: _ctx.translate('sub-modules.parking-space.name'),
                                    subModuleKey: "parking-space"
                                }, null, 8, ["headerText"]),
                                _createVNode(_component_DisplayParkingSpaceBasicView, {
                                    model: _ctx.model?.facilities?.parkingSpaces
                                }, null, 8, ["model"])
                            ]))
                            : _createCommentVNode("", true),
                        (_ctx.model?.facilities?.nearbyInfrastructures)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                                _createVNode(_component_SubModuleTitle, {
                                    icon: "storefront",
                                    headerText: _ctx.translate('sub-modules.nearby-infrastructures.name'),
                                    subModuleKey: "nearby-infrastructures"
                                }, null, 8, ["headerText"]),
                                _createVNode(_component_DisplayInfrastructuresNearbyView, {
                                    model: _ctx.model?.facilities?.nearbyInfrastructures
                                }, null, 8, ["model"])
                            ]))
                            : _createCommentVNode("", true)
                    ]),
                    _: 1
                }, 8, ["title", "verificationAllowed", "hasFeedback", "hasData"]),
                _createVNode(_component_ModuleSectionPanel, {
                    title: _ctx.translate('modules.check-in.display'),
                    verificationAllowed: _ctx.verificationAllowed,
                    hasFeedback: !!_ctx.feedbackBySection['checkIn'],
                    hasData: !!_ctx.model?.checkIn?.checkIns?.length,
                    onClick: _cache[4] || (_cache[4] = ($event) => (_ctx.flags.checkIn = true)),
                    onFeedback: _cache[5] || (_cache[5] = ($event) => (_ctx.openSectionFeedback('checkIn')))
                }, {
                    default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_11, [
                            _createVNode(_component_SubModuleTitle, {
                                icon: "meeting_room",
                                headerText: _ctx.translate('sub-modules.check-in.name'),
                                subModuleKey: "check-in"
                            }, null, 8, ["headerText"]),
                            _createVNode(_component_DisplayCheckInView, {
                                model: _ctx.model?.checkIn?.checkIns
                            }, null, 8, ["model"])
                        ])
                    ]),
                    _: 1
                }, 8, ["title", "verificationAllowed", "hasFeedback", "hasData"]),
                (!_ctx.verificationAllowed)
                    ? (_openBlock(), _createBlock(_component_ModuleSectionPanel, {
                        key: 0,
                        title: _ctx.translate('modules.gates.display'),
                        hasData: !!_ctx.model?.gates?.gates?.length
                    }, {
                        default: _withCtx(() => [
                            _createElementVNode("div", _hoisted_12, [
                                _createVNode(_component_DisplayGatesView, {
                                    model: _ctx.model?.gates?.gates
                                }, null, 8, ["model"])
                            ])
                        ]),
                        _: 1
                    }, 8, ["title", "hasData"]))
                    : _createCommentVNode("", true),
                _createVNode(_component_ModuleSectionPanel, {
                    title: _ctx.translate('modules.safety-and-security.display'),
                    verificationAllowed: _ctx.verificationAllowed,
                    hasFeedback: !!_ctx.feedbackBySection['safetyAndSecurity'],
                    hasData: _ctx.hasData(_ctx.model?.safetyAndSecurity),
                    onClick: _cache[6] || (_cache[6] = ($event) => (_ctx.flags.safetyAndSecurity = true)),
                    onFeedback: _cache[7] || (_cache[7] = ($event) => (_ctx.openSectionFeedback('safetyAndSecurity')))
                }, {
                    default: _withCtx(() => [
                        (_ctx.model?.safetyAndSecurity?.generalSafetyRules)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                                _createVNode(_component_SubModuleTitle, {
                                    icon: "health_and_safety",
                                    headerText: _ctx.translate('sub-modules.general-safety-rules.name'),
                                    subModuleKey: "general-safety-rules"
                                }, null, 8, ["headerText"]),
                                _createVNode(_component_DisplayGeneralSafetyRulesView, {
                                    model: _ctx.model?.safetyAndSecurity?.generalSafetyRules
                                }, null, 8, ["model"])
                            ]))
                            : _createCommentVNode("", true),
                        (_ctx.model?.safetyAndSecurity?.trafficRules)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                                _createVNode(_component_SubModuleTitle, {
                                    icon: "traffic",
                                    headerText: _ctx.translate('sub-modules.traffic-rules.name'),
                                    subModuleKey: "traffic-rules"
                                }, null, 8, ["headerText"]),
                                _createVNode(_component_DisplayTrafficRelatedRulesView, {
                                    model: _ctx.model?.safetyAndSecurity?.trafficRules
                                }, null, 8, ["model"])
                            ]))
                            : _createCommentVNode("", true),
                        (_ctx.model?.safetyAndSecurity?.vehicleAndPersonalProtectiveEquipment)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                                _createVNode(_component_SubModuleTitle, {
                                    icon: "masks",
                                    headerText: _ctx.translate('sub-modules.ppe-requirement.name'),
                                    subModuleKey: "ppe-requirement"
                                }, null, 8, ["headerText"]),
                                _createVNode(_component_DisplayVehicleAndPPESitesView, {
                                    model: _ctx.model?.safetyAndSecurity?.vehicleAndPersonalProtectiveEquipment
                                }, null, 8, ["model"])
                            ]))
                            : _createCommentVNode("", true),
                        (_ctx.model?.safetyAndSecurity?.safetyTest)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
                                _createVNode(_component_SubModuleTitle, {
                                    icon: "content_paste",
                                    headerText: _ctx.translate('sub-modules.safety-test.name'),
                                    subModuleKey: "safety-test"
                                }, null, 8, ["headerText"]),
                                _createVNode(_component_DisplaySafetyTestView, {
                                    model: _ctx.model?.safetyAndSecurity?.safetyTest
                                }, null, 8, ["model"])
                            ]))
                            : _createCommentVNode("", true),
                        (_ctx.model?.safetyAndSecurity?.alarmAndEmergencyProcedures)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
                                _createVNode(_component_SubModuleTitle, {
                                    icon: "alarm",
                                    headerText: _ctx.translate('sub-modules.alarm-and-emergency-procedures.name'),
                                    subModuleKey: "alarm-and-emergency-procedures"
                                }, null, 8, ["headerText"]),
                                _createVNode(_component_DisplayAlarmsAndEmergencyView, {
                                    model: _ctx.model?.safetyAndSecurity?.alarmAndEmergencyProcedures
                                }, null, 8, ["model"])
                            ]))
                            : _createCommentVNode("", true),
                        (_ctx.model?.safetyAndSecurity?.siteIncidentManagement)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_18, [
                                _createVNode(_component_SubModuleTitle, {
                                    icon: "fire_extinguisher",
                                    headerText: _ctx.translate('sub-modules.site-incident-management.name'),
                                    subModuleKey: "site-incident-management"
                                }, null, 8, ["headerText"]),
                                _createVNode(_component_DisplaySiteIncidentManagementView, {
                                    model: _ctx.model?.safetyAndSecurity?.siteIncidentManagement
                                }, null, 8, ["model"])
                            ]))
                            : _createCommentVNode("", true)
                    ]),
                    _: 1
                }, 8, ["title", "verificationAllowed", "hasFeedback", "hasData"]),
                _createVNode(_component_ModuleSectionPanel, {
                    title: _ctx.translate('modules.weighing-bridges.display'),
                    verificationAllowed: _ctx.verificationAllowed,
                    hasFeedback: !!_ctx.feedbackBySection['weighingBridges'],
                    hasData: !!_ctx.model?.weighingBridges?.weighingBridges?.length,
                    onClick: _cache[8] || (_cache[8] = ($event) => (_ctx.flags.weighingBridges = true)),
                    onFeedback: _cache[9] || (_cache[9] = ($event) => (_ctx.openSectionFeedback('weighingBridges')))
                }, {
                    default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_19, [
                            _createVNode(_component_DisplayWeighingBridgesView, {
                                model: _ctx.model?.weighingBridges?.weighingBridges
                            }, null, 8, ["model"])
                        ])
                    ]),
                    _: 1
                }, 8, ["title", "verificationAllowed", "hasFeedback", "hasData"])
            ])
        ]));
}
