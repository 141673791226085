import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-2195b942"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "edit-pre-product" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_EditProductBasicInfoView = _resolveComponent("EditProductBasicInfoView");
    const _component_InputLabel = _resolveComponent("InputLabel");
    const _component_InputText = _resolveComponent("InputText");
    const _component_FormError = _resolveComponent("FormError");
    const _component_FormField = _resolveComponent("FormField");
    const _component_CollapsiblePanel = _resolveComponent("CollapsiblePanel");
    const _component_EditProductGroupAssignmentView = _resolveComponent("EditProductGroupAssignmentView");
    const _component_EditDgClassificationView = _resolveComponent("EditDgClassificationView");
    const _component_InputUrl = _resolveComponent("InputUrl");
    const _component_AppForm = _resolveComponent("AppForm");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_AppForm, {
            modelValue: _ctx.model,
            "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event) => ((_ctx.model) = $event)),
            initial: _ctx.initial,
            onContext: _cache[9] || (_cache[9] = ($event) => (_ctx.context.mainForm = $event))
        }, {
            default: _withCtx(() => [
                _createVNode(_component_CollapsiblePanel, {
                    title: _ctx.translate('pre-product-basics-title'),
                    descriptionIcon: false
                }, {
                    default: _withCtx(() => [
                        _createVNode(_component_EditProductBasicInfoView, {
                            modelValue: _ctx.model.basicInformation,
                            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => (_ctx.model.basicInformation = $event)),
                            initial: _ctx.initial.basicInformation,
                            errors: _ctx.errors,
                            onAreParametersValid: _cache[1] || (_cache[1] = ($event) => (_ctx.areParametersValid = $event)),
                            onContext: _cache[2] || (_cache[2] = ($event) => (_ctx.context.basicInfoForm = $event)),
                            onlyAttributes: ""
                        }, null, 8, ["modelValue", "initial", "errors"]),
                        _createVNode(_component_FormField, { class: "col-xl-7" }, {
                            default: _withCtx(() => [
                                _createVNode(_component_InputLabel, { required: "" }, {
                                    default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(_ctx.translate("pre-product-name")), 1)
                                    ]),
                                    _: 1
                                }),
                                _createVNode(_component_InputText, {
                                    name: "name",
                                    required: "",
                                    "min-length": "3",
                                    "max-length": "100",
                                    placeholder: _ctx.translate('pre-product-name')
                                }, null, 8, ["placeholder"]),
                                _createVNode(_component_FormError, { name: "name" })
                            ]),
                            _: 1
                        })
                    ]),
                    _: 1
                }, 8, ["title"]),
                _createVNode(_component_CollapsiblePanel, {
                    title: _ctx.translate('pre-product-group-assignment-title'),
                    descriptionIcon: false
                }, {
                    default: _withCtx(() => [
                        _createVNode(_component_EditProductGroupAssignmentView, {
                            modelValue: _ctx.model.productGroupAssignment,
                            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event) => (_ctx.model.productGroupAssignment = $event)),
                            initial: _ctx.initial.productGroupAssignment,
                            errors: _ctx.errors,
                            onContext: _cache[4] || (_cache[4] = ($event) => (_ctx.context.groupAssignmentForm = $event)),
                            onAreParametersValid: _cache[5] || (_cache[5] = ($event) => (_ctx.areParametersValid = $event))
                        }, null, 8, ["modelValue", "initial", "errors"])
                    ]),
                    _: 1
                }, 8, ["title"]),
                _createVNode(_component_CollapsiblePanel, {
                    title: _ctx.translate('dg-classification-title'),
                    descriptionIcon: false
                }, {
                    default: _withCtx(() => [
                        _createVNode(_component_EditDgClassificationView, {
                            modelValue: _ctx.model.dgClassification,
                            "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event) => (_ctx.model.dgClassification = $event)),
                            initial: _ctx.initial.dgClassification,
                            errors: _ctx.errors,
                            onContext: _cache[7] || (_cache[7] = ($event) => (_ctx.context.dgClassificationForm = $event))
                        }, null, 8, ["modelValue", "initial", "errors"])
                    ]),
                    _: 1
                }, 8, ["title"]),
                _createVNode(_component_CollapsiblePanel, {
                    title: _ctx.translate('link-to-sds-title'),
                    descriptionIcon: false
                }, {
                    default: _withCtx(() => [
                        _createVNode(_component_FormField, { class: "col-xl-7" }, {
                            default: _withCtx(() => [
                                _createVNode(_component_InputUrl, {
                                    name: "sdsLink",
                                    placeholder: _ctx.translate('link-to-sds-placeholder'),
                                    "url-with-optional-schema": ""
                                }, null, 8, ["placeholder"]),
                                _createVNode(_component_FormError, { name: "sdsLink" })
                            ]),
                            _: 1
                        })
                    ]),
                    _: 1
                }, 8, ["title"])
            ]),
            _: 1
        }, 8, ["modelValue", "initial"])
    ]));
}
