import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = { class: "display-product-additional-information" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_DisplayAttachments = _resolveComponent("DisplayAttachments");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_DisplayAttachments, {
            attachments: _ctx.filterByLocale(_ctx.model.attachments),
            title: _ctx.translate('global.phrases.additional-information')
        }, null, 8, ["attachments", "title"])
    ]));
}
