import { createModule } from "@/di/module";
import { domainTokensFactory } from "@/app/modular-content/factories/domain-tokens.factory";
import { SearchService } from "@/app/modular-content/services/search.service";
export const DomainSearchServiceModule = (options) => {
    const tokens = domainTokensFactory(options.id);
    return createModule({
        providers: [
            { token: tokens.search, useClass: SearchService },
            { token: SearchService, useToken: tokens.search },
        ],
    });
};
