import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-30d00b38"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "col-id" };
const _hoisted_2 = { class: "image-placeholder" };
const _hoisted_3 = { class: "name" };
const _hoisted_4 = { class: "label" };
const _hoisted_5 = {
    key: 0,
    class: "text-wrap"
};
const _hoisted_6 = { key: 1 };
const _hoisted_7 = {
    key: 0,
    class: "text-wrap"
};
const _hoisted_8 = { key: 1 };
const _hoisted_9 = {
    key: 0,
    class: "text-wrap"
};
const _hoisted_10 = { key: 1 };
const _hoisted_11 = {
    key: 0,
    class: "text-wrap"
};
const _hoisted_12 = { key: 1 };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_Icon = _resolveComponent("Icon");
    const _component_Column = _resolveComponent("Column");
    const _component_TableRowContextMenu = _resolveComponent("TableRowContextMenu");
    const _component_DataTable = _resolveComponent("DataTable");
    const _component_AppTable = _resolveComponent("AppTable");
    return (_openBlock(), _createBlock(_component_AppTable, {
        delegate: _ctx.delegate,
        onReload: _ctx.delegate.loadPage,
        isLoading: _ctx.isInitialLoading || _ctx.isDataLoading,
        isError: _ctx.isError,
        error: _ctx.errorResponse,
        contextTranslate: _ctx.translate,
        class: "webhooks-list"
    }, {
        table: _withCtx((slotProps) => [
            _createVNode(_component_DataTable, {
                value: slotProps.data.results,
                totalRecords: slotProps.data.count,
                tableFilters: _ctx.filters,
                filters: _ctx.filters,
                "onUpdate:filters": [
                    _cache[0] || (_cache[0] = ($event) => ((_ctx.filters) = $event)),
                    _ctx.onUpdateFilters
                ],
                lazy: true,
                rowHover: false,
                ref: "webhooksList",
                loading: _ctx.isInitialLoading || _ctx.isDataLoading,
                onPage: _cache[1] || (_cache[1] = ($event) => (_ctx.onPage($event))),
                first: _ctx.lazyParams.first
            }, {
                default: _withCtx(() => [
                    _createVNode(_component_Column, {
                        field: "id",
                        header: _ctx.translate('columns.id'),
                        style: { "min-width": "24rem" }
                    }, {
                        body: _withCtx(({ data }) => [
                            _createElementVNode("div", _hoisted_1, [
                                _createElementVNode("div", _hoisted_2, [
                                    _createVNode(_component_Icon, { name: "precision_manufacturing" })
                                ]),
                                _createElementVNode("div", _hoisted_3, [
                                    _createElementVNode("div", _hoisted_4, _toDisplayString(data.id ?? "-"), 1)
                                ])
                            ])
                        ]),
                        _: 1
                    }, 8, ["header"]),
                    _createVNode(_component_Column, {
                        field: "name",
                        header: _ctx.translate('columns.name'),
                        style: { "min-width": "10rem" }
                    }, {
                        body: _withCtx(({ data }) => [
                            (data.name?.length)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString(data.name), 1))
                                : (_openBlock(), _createElementBlock("div", _hoisted_6, "-"))
                        ]),
                        _: 1
                    }, 8, ["header"]),
                    _createVNode(_component_Column, {
                        field: "trigger",
                        header: _ctx.translate('columns.type'),
                        style: { "min-width": "10rem" }
                    }, {
                        body: _withCtx(({ data }) => [
                            (data.trigger?.length)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_7, _toDisplayString(_ctx.getValue(data.trigger, _ctx.webhookTrigger, "WebhookTrigger")), 1))
                                : (_openBlock(), _createElementBlock("div", _hoisted_8, "-"))
                        ]),
                        _: 1
                    }, 8, ["header"]),
                    _createVNode(_component_Column, {
                        field: "description",
                        header: _ctx.translate('columns.description'),
                        style: { "min-width": "10rem" }
                    }, {
                        body: _withCtx(({ data }) => [
                            (data.description?.length)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_9, _toDisplayString(data.description), 1))
                                : (_openBlock(), _createElementBlock("div", _hoisted_10, "-"))
                        ]),
                        _: 1
                    }, 8, ["header"]),
                    _createVNode(_component_Column, {
                        field: "signatureKey",
                        header: _ctx.translate('columns.signature-key'),
                        style: { "min-width": "10rem" }
                    }, {
                        body: _withCtx(({ data }) => [
                            (data.signatureKey?.length)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_11, _toDisplayString(data.signatureKey), 1))
                                : (_openBlock(), _createElementBlock("div", _hoisted_12, "-"))
                        ]),
                        _: 1
                    }, 8, ["header"]),
                    _createVNode(_component_Column, {
                        class: "not-resizable-column",
                        style: { "min-width": "6rem" }
                    }, {
                        body: _withCtx(({ data }) => [
                            (_ctx.roleCheck(_ctx.$permissions.isAdmin, data.companyId))
                                ? (_openBlock(), _createBlock(_component_TableRowContextMenu, {
                                    key: 0,
                                    onDelete: ($event) => (_ctx.delegate.deleteItem(data.id)),
                                    data: data,
                                    contextTranslate: _ctx.translate,
                                    customDeletion: "",
                                    customEdit: "",
                                    deleteModalHeader: _ctx.translate('row-context-menu.delete'),
                                    deleteModalMessage: _ctx.translate('delete-modal-message', { name: data.name })
                                }, null, 8, ["onDelete", "data", "contextTranslate", "deleteModalHeader", "deleteModalMessage"]))
                                : _createCommentVNode("", true)
                        ]),
                        _: 1
                    })
                ]),
                _: 2
            }, 1032, ["value", "totalRecords", "tableFilters", "filters", "onUpdate:filters", "loading", "first"])
        ]),
        _: 1
    }, 8, ["delegate", "onReload", "isLoading", "isError", "error", "contextTranslate"]));
}
