import { createModule } from "@/di/module";
import { GeneralInformationModuleId } from "@/app/modules/general-information/general-information.module";
import { SubModuleModule } from "@/app/modular-content/modules/sub-module.module";
import { suffixComponentName } from "@/app/modular-content/functions/suffix-component-name";
import { TranslationPrefixToken } from "@/app/translation/tokens/translation-prefix.token";
import EditBuildingBasicInformationComponent from "@/app/sub-modules/basic-information/building/components/edit-building-basic-information.component.vue";
import DisplayBuildingBasicInformationComponent from "@/app/sub-modules/basic-information/building/components/display-building-basic-information.component.vue";
export const BuildingBasicInformationSubModule = (domainId) => {
    const id = "basicInformation";
    const moduleId = GeneralInformationModuleId;
    const componentSuffixes = [domainId, moduleId, id];
    const editComponent = suffixComponentName(EditBuildingBasicInformationComponent, ...componentSuffixes);
    const displayComponent = suffixComponentName(DisplayBuildingBasicInformationComponent, ...componentSuffixes);
    return createModule({
        components: [editComponent, displayComponent],
        imports: [
            SubModuleModule({
                id,
                moduleId,
                domainId,
                icon: "info",
                hideDisplayTitle: true,
                dataLocalised: false,
                editTitle: "sub-modules.basic-information.building.name",
                configurationTranslationPrefix: "sub-modules.basic-information.building",
                components: {
                    edit: editComponent,
                    display: displayComponent,
                },
            }),
        ],
        providers: [{ token: TranslationPrefixToken, useValue: "sub-modules.basic-information.building" }],
    });
};
