import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-07450689"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "lashing-input" };
const _hoisted_2 = { class: "mt-4" };
const _hoisted_3 = { class: "globe-icon-wrapper-sm" };
const _hoisted_4 = { class: "inputs-box col-md-7" };
const _hoisted_5 = { class: "col-md-5" };
const _hoisted_6 = { class: "col-md-5" };
const _hoisted_7 = { class: "inputs-box col-md-7" };
const _hoisted_8 = { class: "col-md-5" };
const _hoisted_9 = { class: "col-md-5" };
const _hoisted_10 = { class: "inputs-box col-md-7" };
const _hoisted_11 = { class: "col-md-5" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_InputLabel = _resolveComponent("InputLabel");
    const _component_RadioButton = _resolveComponent("RadioButton");
    const _component_FormField = _resolveComponent("FormField");
    const _component_GlobeIcon = _resolveComponent("GlobeIcon");
    const _component_LocalisedInput = _resolveComponent("LocalisedInput");
    const _component_InputNumber = _resolveComponent("InputNumber");
    const _component_FormError = _resolveComponent("FormError");
    const _component_AppForm = _resolveComponent("AppForm");
    return (_openBlock(), _createBlock(_component_AppForm, {
        modelValue: _ctx.model,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event) => ((_ctx.model) = $event)),
        initial: _ctx.initial,
        errors: _ctx.errors
    }, {
        default: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
                _createElementVNode("h6", _hoisted_2, _toDisplayString(_ctx.translate("lashing")), 1),
                _createVNode(_component_FormField, null, {
                    default: _withCtx(() => [
                        _createVNode(_component_InputLabel, null, {
                            default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.translate("lashing-to-be-provided-by")), 1)
                            ]),
                            _: 1
                        }),
                        _createVNode(_component_RadioButton, {
                            name: "providedBy",
                            dictionary: _ctx.loadSecuringMaterialProvidedBy,
                            dictionaryId: "LoadSecuringMaterialProvidedBy"
                        }, null, 8, ["dictionary"])
                    ]),
                    _: 1
                }),
                _createVNode(_component_FormField, { class: "col-md-7" }, {
                    default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_3, [
                            _createVNode(_component_InputLabel, null, {
                                default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.translate("din-compliance")), 1)
                                ]),
                                _: 1
                            }),
                            _createVNode(_component_GlobeIcon, { size: "sm" })
                        ]),
                        _createVNode(_component_LocalisedInput, {
                            modelValue: _ctx.model.dinNorm,
                            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => ((_ctx.model.dinNorm) = $event)),
                            placeholder: _ctx.translate('din-compliance-placeholder')
                        }, null, 8, ["modelValue", "placeholder"])
                    ]),
                    _: 1
                }),
                _createVNode(_component_FormField, null, {
                    default: _withCtx(() => [
                        _createVNode(_component_InputLabel, null, {
                            default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.translate("carrier-ensures-number-of-lashing")), 1)
                            ]),
                            _: 1
                        }),
                        _createVNode(_component_RadioButton, {
                            name: "carrierToEnsureSufficientNumberOfLashingsToSecureCargo",
                            dictionary: _ctx.carrierDecidesAboutNumberOfToSecuringCargo,
                            dictionaryId: "CarrierDecidesAboutNumberOfToSecuringCargo"
                        }, null, 8, ["dictionary"])
                    ]),
                    _: 1
                }),
                (_ctx.isCompanyGuideline)
                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                        _createVNode(_component_FormField, null, {
                            default: _withCtx(() => [
                                _createElementVNode("div", _hoisted_4, [
                                    _createElementVNode("div", _hoisted_5, [
                                        _createVNode(_component_InputLabel, null, {
                                            default: _withCtx(() => [
                                                _createTextVNode(_toDisplayString(_ctx.translate("min-number-per-pallet")), 1)
                                            ]),
                                            _: 1
                                        }),
                                        _createVNode(_component_InputNumber, {
                                            name: "minimumNumberPerPallet",
                                            placeholder: _ctx.translate('enter-number-per-pallet'),
                                            id: "minimumNumberPerPallet",
                                            mode: "integer",
                                            min: 0,
                                            maxIntegerDigits: 3
                                        }, null, 8, ["placeholder"]),
                                        _createVNode(_component_FormError, { name: "minimumNumberPerPallet" })
                                    ]),
                                    _createElementVNode("div", _hoisted_6, [
                                        _createVNode(_component_InputLabel, null, {
                                            default: _withCtx(() => [
                                                _createTextVNode(_toDisplayString(_ctx.translate("min-number-per-truck")), 1)
                                            ]),
                                            _: 1
                                        }),
                                        _createVNode(_component_InputNumber, {
                                            name: "minimumNumberPerTruck",
                                            placeholder: _ctx.translate('enter-number-per-truck'),
                                            id: "minimumNumberPerTruck",
                                            mode: "integer",
                                            min: 0,
                                            maxIntegerDigits: 3
                                        }, null, 8, ["placeholder"]),
                                        _createVNode(_component_FormError, { name: "minimumNumberPerTruck" })
                                    ])
                                ])
                            ]),
                            _: 1
                        }),
                        _createVNode(_component_FormField, null, {
                            default: _withCtx(() => [
                                _createElementVNode("div", _hoisted_7, [
                                    _createElementVNode("div", _hoisted_8, [
                                        _createVNode(_component_InputLabel, null, {
                                            default: _withCtx(() => [
                                                _createTextVNode(_toDisplayString(_ctx.translate("min-length")), 1)
                                            ]),
                                            _: 1
                                        }),
                                        _createVNode(_component_InputNumber, {
                                            name: "minimumLength",
                                            placeholder: _ctx.translate('enter-min-length'),
                                            id: "minimumLength",
                                            mode: "decimal",
                                            min: 0
                                        }, null, 8, ["placeholder"]),
                                        _createVNode(_component_FormError, { name: "minimumLength" })
                                    ]),
                                    _createElementVNode("div", _hoisted_9, [
                                        _createVNode(_component_InputLabel, null, {
                                            default: _withCtx(() => [
                                                _createTextVNode(_toDisplayString(_ctx.translate("min-lashing-capacity")), 1)
                                            ]),
                                            _: 1
                                        }),
                                        _createVNode(_component_InputNumber, {
                                            name: "minimumLashingCapacity",
                                            placeholder: _ctx.translate('enter-capacity'),
                                            id: "minimumLashingCapacity",
                                            mode: "decimal",
                                            min: 10
                                        }, null, 8, ["placeholder"]),
                                        _createVNode(_component_FormError, { name: "minimumLashingCapacity" })
                                    ])
                                ])
                            ]),
                            _: 1
                        }),
                        _createVNode(_component_FormField, null, {
                            default: _withCtx(() => [
                                _createElementVNode("div", _hoisted_10, [
                                    _createElementVNode("div", _hoisted_11, [
                                        _createVNode(_component_InputLabel, null, {
                                            default: _withCtx(() => [
                                                _createTextVNode(_toDisplayString(_ctx.translate("min-tension-force")), 1)
                                            ]),
                                            _: 1
                                        }),
                                        _createVNode(_component_InputNumber, {
                                            name: "minimumTensionForceSTF",
                                            placeholder: _ctx.translate('enter-tension-force'),
                                            id: "minimumTensionForceSTF",
                                            mode: "decimal",
                                            min: 10
                                        }, null, 8, ["placeholder"]),
                                        _createVNode(_component_FormError, { name: "minimumTensionForceSTF" })
                                    ])
                                ])
                            ]),
                            _: 1
                        })
                    ], 64))
                    : _createCommentVNode("", true)
            ])
        ]),
        _: 1
    }, 8, ["modelValue", "initial", "errors"]));
}
