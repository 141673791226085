import { createModule } from "@/di/module";
import { TranslationPrefixToken } from "@/app/translation/tokens/translation-prefix.token";
import { CleaningRequirementsModuleId } from "@/app/modules/cleaning-requirements/cleaning-requirements.module";
import { suffixComponentName } from "@/app/modular-content/functions/suffix-component-name";
import EditRequiredEquipmentStateComponent from "@/app/sub-modules/required-equipment-state/components/edit-required-equipment-state.component.vue";
import DisplayRequiredEquipmentStateComponent from "@/app/sub-modules/required-equipment-state/components/display-required-equipment-state.component.vue";
import DisplayRequiredEquipmentStateViewComponent from "@/app/sub-modules/required-equipment-state/components/display-required-equipment-state-view.component.vue";
import { SubModuleModule } from "@/app/modular-content/modules/sub-module.module";
export const RequiredEquipmentStateSubModule = (domainId) => {
    const id = "requiredEquipmentState";
    const moduleId = CleaningRequirementsModuleId;
    const componentSuffixes = [domainId, moduleId, id];
    const editComponent = suffixComponentName(EditRequiredEquipmentStateComponent, ...componentSuffixes);
    const displayComponent = suffixComponentName(DisplayRequiredEquipmentStateComponent, ...componentSuffixes);
    const displayViewComponent = suffixComponentName(DisplayRequiredEquipmentStateViewComponent, ...componentSuffixes);
    return createModule({
        components: [editComponent, displayComponent, displayViewComponent],
        imports: [
            SubModuleModule({
                id,
                moduleId,
                domainId,
                icon: "construction",
                dataLocalised: false,
                components: {
                    edit: editComponent,
                    display: displayComponent,
                    displayView: displayViewComponent,
                },
            }),
        ],
        providers: [
            {
                token: TranslationPrefixToken,
                useValue: "sub-modules.required-equipment-state",
            },
        ],
    });
};
