import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_DisplayVehicleAndPPESitesView = _resolveComponent("DisplayVehicleAndPPESitesView");
    const _component_DisplayVehicleAndPPEUlpView = _resolveComponent("DisplayVehicleAndPPEUlpView");
    const _component_DisplayVehicleAndPPEApView = _resolveComponent("DisplayVehicleAndPPEApView");
    return (_ctx.isSitesDomain)
        ? (_openBlock(), _createBlock(_component_DisplayVehicleAndPPESitesView, {
            key: 0,
            model: _ctx.model
        }, null, 8, ["model"]))
        : (_ctx.isUlpDomain)
            ? (_openBlock(), _createBlock(_component_DisplayVehicleAndPPEUlpView, {
                key: 1,
                model: _ctx.model
            }, null, 8, ["model"]))
            : (_ctx.isApDomain)
                ? (_openBlock(), _createBlock(_component_DisplayVehicleAndPPEApView, {
                    key: 2,
                    model: _ctx.model
                }, null, 8, ["model"]))
                : _createCommentVNode("", true);
}
