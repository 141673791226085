import { suffixComponentName } from "@/app/modular-content/functions/suffix-component-name";
import { SubModuleModule } from "@/app/modular-content/modules/sub-module.module";
import DisplaySealing from "@/app/sub-modules/sealing/components/display-sealing.vue";
import DisplaySealingView from "@/app/sub-modules/sealing/components/display-sealing-view.vue";
import EditSealing from "@/app/sub-modules/sealing/components/edit-sealing.vue";
import { TranslationPrefixToken } from "@/app/translation/tokens/translation-prefix.token";
import { createModule } from "@/di/module";
import { InstructionsModuleId } from "@/app/modules/instructions/instructions.module";
export const SealingSubModule = (domainId) => {
    const id = "sealing";
    const moduleId = InstructionsModuleId;
    const componentSuffixes = [domainId, moduleId, id];
    const displayComponent = suffixComponentName(DisplaySealing, ...componentSuffixes);
    const displayViewComponent = suffixComponentName(DisplaySealingView, ...componentSuffixes);
    const editComponent = suffixComponentName(EditSealing, ...componentSuffixes);
    return createModule({
        components: [displayComponent, displayViewComponent, editComponent],
        imports: [
            SubModuleModule({
                id,
                moduleId,
                domainId,
                icon: "check_circle",
                dataLocalised: true,
                components: {
                    display: displayComponent,
                    displayView: displayViewComponent,
                    edit: editComponent,
                },
            }),
        ],
        providers: [
            {
                token: TranslationPrefixToken,
                useValue: "sub-modules.sealing",
            },
        ],
    });
};
