var _a, _b, _c, _d, _e;
import { __decorate, __metadata, __param } from "tslib";
import { service } from "@/di/decorators/service.decorator";
import { reactive } from "@/di/decorators/computed.decorator";
import { HttpClientService } from "@/app/http/services/http-client.service";
import { inject } from "@/di/decorators/inject.decorator";
import { UrlFactoryToken } from "@/app/modular-content/token/modular-content.token";
import _ from "lodash";
import { clone } from "@/app/shared/functions/clone";
import { UrlFactory } from "@/app/modular-content/type/url-factory.type";
import { error } from "@/app/shared/functions/error";
let ModuleConfigurationService = class ModuleConfigurationService {
    httpClient;
    urlFactory;
    enabledById = {};
    optionalById = {};
    hiddenById = {};
    constructor(httpClient, urlFactory) {
        this.httpClient = httpClient;
        this.urlFactory = urlFactory;
    }
    async loadConfiguration() {
        const configurations = await this.httpClient.get(this.urlFactory.createUrl());
        const idKey = findIdKey(configurations);
        this.enabledById = {};
        this.optionalById = {};
        configurations &&
            configurations?.forEach((it) => {
                const moduleId = _.get(it, idKey);
                this.enabledById[moduleId] = it.isVisible;
                this.optionalById[moduleId] = !it.isMandatory;
            });
    }
    allMandatory() {
        return !_.some(this.optionalById, _.identity);
    }
    async saveConfiguration(enabledById) {
        const url = this.urlFactory.createUrl();
        const current = await this.httpClient.get(url);
        const idKey = findIdKey(current);
        const configurations = _.map(current, (configuration) => {
            const id = _.get(configuration, idKey);
            return {
                ...configuration,
                isVisible: enabledById[id] ?? this.enabledById[id],
            };
        });
        /**
         * WORKAROUND: We can't assume PUT to respond with the saved entity and just use that to update our data, so we
         * instead just use the passed values and assume the request was processed properly.
         *
         * NOTE: This PUT request does not throw 400 if the request body is somehow invalid, e.g. trying to change values
         * that can not be changed or omitting fields.
         */
        await this.httpClient.put(url, configurations);
        this.enabledById = clone(enabledById);
    }
    isEnabled(moduleId) {
        return this.enabledById[moduleId] ?? true;
    }
    getEnabled() {
        return this.enabledById;
    }
    getOptional() {
        return this.optionalById;
    }
    isOptional(moduleId) {
        return this.optionalById[moduleId] ?? true;
    }
    setHidden(moduleId, hidden) {
        this.hiddenById[moduleId] = hidden;
    }
    resetHidden() {
        this.hiddenById = {};
    }
    isHidden(moduleId) {
        return this.hiddenById[moduleId] ?? false;
    }
};
__decorate([
    reactive(),
    __metadata("design:type", typeof (_c = typeof Record !== "undefined" && Record) === "function" ? _c : Object)
], ModuleConfigurationService.prototype, "enabledById", void 0);
__decorate([
    reactive(),
    __metadata("design:type", typeof (_d = typeof Record !== "undefined" && Record) === "function" ? _d : Object)
], ModuleConfigurationService.prototype, "optionalById", void 0);
__decorate([
    reactive(),
    __metadata("design:type", typeof (_e = typeof Record !== "undefined" && Record) === "function" ? _e : Object)
], ModuleConfigurationService.prototype, "hiddenById", void 0);
ModuleConfigurationService = __decorate([
    service(),
    __param(1, inject(UrlFactoryToken)),
    __metadata("design:paramtypes", [typeof (_a = typeof HttpClientService !== "undefined" && HttpClientService) === "function" ? _a : Object, typeof (_b = typeof UrlFactory !== "undefined" && UrlFactory) === "function" ? _b : Object])
], ModuleConfigurationService);
export { ModuleConfigurationService };
/**
 * WORKAROUND: The configuration for modules and submodules works exactly the same way, but the "id" key is named
 * differently for each endpoint, so we try to find the correct one by searching for a field that ends with "id".
 */
const findIdKey = (configurations) => {
    if (configurations.length === 0) {
        return "";
    }
    const firstEntry = configurations[0];
    const keys = _.keys(firstEntry);
    return (keys.find((it) => it.toLowerCase().endsWith("id")) ??
        error(`Unable to find an id parameter in ${JSON.stringify(firstEntry)}`));
};
