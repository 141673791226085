import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, withCtx as _withCtx, createElementVNode as _createElementVNode } from "vue";
const _hoisted_1 = {
    key: 1,
    class: "summary"
};
const _hoisted_2 = {
    key: 0,
    class: "sub-module-section"
};
const _hoisted_3 = {
    key: 1,
    class: "sub-module-section"
};
const _hoisted_4 = {
    key: 2,
    class: "sub-module-section"
};
const _hoisted_5 = {
    key: 3,
    class: "sub-module-section"
};
const _hoisted_6 = {
    key: 4,
    class: "sub-module-section"
};
const _hoisted_7 = {
    key: 5,
    class: "sub-module-section"
};
const _hoisted_8 = {
    key: 6,
    class: "sub-module-section"
};
const _hoisted_9 = {
    key: 0,
    class: "sub-module-section"
};
const _hoisted_10 = {
    key: 1,
    class: "sub-module-section"
};
const _hoisted_11 = {
    key: 2,
    class: "sub-module-section"
};
const _hoisted_12 = {
    key: 3,
    class: "sub-module-section"
};
const _hoisted_13 = {
    key: 4,
    class: "sub-module-section"
};
const _hoisted_14 = { class: "sub-module-section" };
const _hoisted_15 = {
    key: 0,
    class: "sub-module-section"
};
const _hoisted_16 = {
    key: 0,
    class: "sub-module-section"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_LoadingSpinner = _resolveComponent("LoadingSpinner");
    const _component_DisplayULPBasicInformationView = _resolveComponent("DisplayULPBasicInformationView");
    const _component_SubModuleTitle = _resolveComponent("SubModuleTitle");
    const _component_DisplayPostalAddressView = _resolveComponent("DisplayPostalAddressView");
    const _component_DisplayAccessRestrictionsView = _resolveComponent("DisplayAccessRestrictionsView");
    const _component_DisplayRegistrationView = _resolveComponent("DisplayRegistrationView");
    const _component_DisplayOpeningHoursView = _resolveComponent("DisplayOpeningHoursView");
    const _component_DisplayContactDataView = _resolveComponent("DisplayContactDataView");
    const _component_DisplayProcessesView = _resolveComponent("DisplayProcessesView");
    const _component_ModuleSectionPanel = _resolveComponent("ModuleSectionPanel");
    const _component_DisplayUnLoadingAreaView = _resolveComponent("DisplayUnLoadingAreaView");
    const _component_DisplayWorkingOnTopView = _resolveComponent("DisplayWorkingOnTopView");
    const _component_DisplayAvailableEquipmentView = _resolveComponent("DisplayAvailableEquipmentView");
    const _component_DisplayAvailableEquipmentPackedView = _resolveComponent("DisplayAvailableEquipmentPackedView");
    const _component_DisplayReceivingTankView = _resolveComponent("DisplayReceivingTankView");
    const _component_DisplaySlotBookingView = _resolveComponent("DisplaySlotBookingView");
    const _component_DisplayUnloadingShipmentPreNotificationView = _resolveComponent("DisplayUnloadingShipmentPreNotificationView");
    const _component_DisplayVehicleAndPPEUlpView = _resolveComponent("DisplayVehicleAndPPEUlpView");
    return (_ctx.isLoading)
        ? (_openBlock(), _createBlock(_component_LoadingSpinner, {
            key: 0,
            center: ""
        }))
        : (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createElementVNode("div", null, [
                _createVNode(_component_ModuleSectionPanel, {
                    title: _ctx.translate('modules.general-information.display'),
                    verificationAllowed: _ctx.verificationAllowed,
                    hasFeedback: !!_ctx.feedbackBySection['generalInformation'],
                    hasData: _ctx.hasData(_ctx.model?.generalInformation),
                    onClick: _cache[0] || (_cache[0] = ($event) => (_ctx.flags.generalInformation = true)),
                    onFeedback: _cache[1] || (_cache[1] = ($event) => (_ctx.openSectionFeedback('generalInformation')))
                }, {
                    default: _withCtx(() => [
                        (_ctx.model?.generalInformation?.basicInformation)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                                _createVNode(_component_DisplayULPBasicInformationView, {
                                    model: _ctx.model?.generalInformation?.basicInformation,
                                    hideBasicInfoHeader: ""
                                }, null, 8, ["model"])
                            ]))
                            : _createCommentVNode("", true),
                        (_ctx.model?.generalInformation?.address)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                                _createVNode(_component_SubModuleTitle, {
                                    icon: "place",
                                    headerText: _ctx.translate('sub-modules.address.name'),
                                    subModuleKey: "address"
                                }, null, 8, ["headerText"]),
                                _createVNode(_component_DisplayPostalAddressView, {
                                    model: _ctx.model?.generalInformation?.address
                                }, null, 8, ["model"])
                            ]))
                            : _createCommentVNode("", true),
                        (_ctx.model?.generalInformation?.accessRestrictions)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                                _createVNode(_component_SubModuleTitle, {
                                    icon: "local_shipping",
                                    headerText: _ctx.translate('sub-modules.access-restrictions.name'),
                                    subModuleKey: "access-restrictions"
                                }, null, 8, ["headerText"]),
                                _createVNode(_component_DisplayAccessRestrictionsView, {
                                    model: _ctx.model?.generalInformation?.accessRestrictions
                                }, null, 8, ["model"])
                            ]))
                            : _createCommentVNode("", true),
                        (_ctx.model?.generalInformation?.registration)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                                _createVNode(_component_SubModuleTitle, {
                                    icon: "fact_check",
                                    headerText: _ctx.translate('sub-modules.registration.name'),
                                    subModuleKey: "registration"
                                }, null, 8, ["headerText"]),
                                _createVNode(_component_DisplayRegistrationView, {
                                    model: _ctx.model?.generalInformation?.registration
                                }, null, 8, ["model"])
                            ]))
                            : _createCommentVNode("", true),
                        (_ctx.model?.generalInformation?.openingHours)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                                _createVNode(_component_SubModuleTitle, {
                                    icon: "access_time",
                                    headerText: _ctx.translate(_ctx.domainTranslationPrefix('hours', true)),
                                    subModuleKey: "opening-hours"
                                }, null, 8, ["headerText"]),
                                _createVNode(_component_DisplayOpeningHoursView, {
                                    model: _ctx.model?.generalInformation?.openingHours
                                }, null, 8, ["model"])
                            ]))
                            : _createCommentVNode("", true),
                        (_ctx.model?.generalInformation?.contactData)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                                _createVNode(_component_SubModuleTitle, {
                                    icon: "phone_enabled",
                                    headerText: _ctx.translate('sub-modules.contact-data.name'),
                                    subModuleKey: "contact-data"
                                }, null, 8, ["headerText"]),
                                _createVNode(_component_DisplayContactDataView, {
                                    model: _ctx.model?.generalInformation?.contactData
                                }, null, 8, ["model"])
                            ]))
                            : _createCommentVNode("", true),
                        (_ctx.model?.generalInformation?.processes)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                                _createVNode(_component_SubModuleTitle, {
                                    icon: "apps",
                                    headerText: _ctx.translate('sub-modules.processes.name'),
                                    subModuleKey: "processes"
                                }, null, 8, ["headerText"]),
                                _createVNode(_component_DisplayProcessesView, {
                                    model: _ctx.model?.generalInformation?.processes
                                }, null, 8, ["model"])
                            ]))
                            : _createCommentVNode("", true)
                    ]),
                    _: 1
                }, 8, ["title", "verificationAllowed", "hasFeedback", "hasData"]),
                _createVNode(_component_ModuleSectionPanel, {
                    title: _ctx.translate('modules.installations.display'),
                    verificationAllowed: _ctx.verificationAllowed,
                    hasFeedback: !!_ctx.feedbackBySection['installations'],
                    hasData: _ctx.hasData(_ctx.model?.installations),
                    onClick: _cache[2] || (_cache[2] = ($event) => (_ctx.flags.installations = true)),
                    onFeedback: _cache[3] || (_cache[3] = ($event) => (_ctx.openSectionFeedback('installations')))
                }, {
                    default: _withCtx(() => [
                        (_ctx.model?.installations?.unloadingLoadingArea)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                                _createVNode(_component_SubModuleTitle, {
                                    icon: "local_shipping",
                                    headerText: _ctx.translate(`sub-modules.unloading-loading-area.${_ctx.domainTranslationPrefix('point.name')}`),
                                    subModuleKey: `unloading-loading-area.${_ctx.domainTranslationPrefix('point')}`
                                }, null, 8, ["headerText", "subModuleKey"]),
                                _createVNode(_component_DisplayUnLoadingAreaView, {
                                    model: _ctx.model?.installations?.unloadingLoadingArea
                                }, null, 8, ["model"])
                            ]))
                            : _createCommentVNode("", true),
                        (_ctx.model?.installations?.workingOnTop)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                                _createVNode(_component_SubModuleTitle, {
                                    icon: "vertical_align_top",
                                    headerText: _ctx.translate('sub-modules.working-on-top.name'),
                                    subModuleKey: "working-on-top"
                                }, null, 8, ["headerText"]),
                                _createVNode(_component_DisplayWorkingOnTopView, {
                                    model: _ctx.model?.installations?.workingOnTop
                                }, null, 8, ["model"])
                            ]))
                            : _createCommentVNode("", true),
                        (_ctx.model?.installations?.availableEquipment)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                                _createVNode(_component_SubModuleTitle, {
                                    icon: "home_repair_service",
                                    headerText: _ctx.translate('sub-modules.available-equipment.name'),
                                    subModuleKey: "available-equipment"
                                }, null, 8, ["headerText"]),
                                _createVNode(_component_DisplayAvailableEquipmentView, {
                                    model: _ctx.model?.installations?.availableEquipment
                                }, null, 8, ["model"])
                            ]))
                            : _createCommentVNode("", true),
                        (_ctx.model?.installations?.availableEquipmentPacked)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                                _createVNode(_component_SubModuleTitle, {
                                    icon: "home_repair_service",
                                    headerText: _ctx.translate('sub-modules.available-equipment-packed.name'),
                                    subModuleKey: "available-equipment-packed"
                                }, null, 8, ["headerText"]),
                                _createVNode(_component_DisplayAvailableEquipmentPackedView, {
                                    model: _ctx.model?.installations?.availableEquipmentPacked
                                }, null, 8, ["model"])
                            ]))
                            : _createCommentVNode("", true),
                        (_ctx.model?.installations?.receivingTank)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                                _createVNode(_component_SubModuleTitle, {
                                    icon: "shopping_basket",
                                    headerText: _ctx.translate('sub-modules.receiving-tank.name'),
                                    subModuleKey: "receiving-tank"
                                }, null, 8, ["headerText"]),
                                _createVNode(_component_DisplayReceivingTankView, {
                                    model: _ctx.model?.installations?.receivingTank
                                }, null, 8, ["model"])
                            ]))
                            : _createCommentVNode("", true)
                    ]),
                    _: 1
                }, 8, ["title", "verificationAllowed", "hasFeedback", "hasData"]),
                _createVNode(_component_ModuleSectionPanel, {
                    title: _ctx.translate('modules.slot-booking.display'),
                    verificationAllowed: _ctx.verificationAllowed,
                    hasFeedback: !!_ctx.feedbackBySection['slotBooking'],
                    hasData: _ctx.hasData(_ctx.model?.slotBooking),
                    onClick: _cache[4] || (_cache[4] = ($event) => (_ctx.flags.slotBooking = true)),
                    onFeedback: _cache[5] || (_cache[5] = ($event) => (_ctx.openSectionFeedback('slotBooking')))
                }, {
                    default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_14, [
                            _createVNode(_component_SubModuleTitle, {
                                icon: "confirmation_number",
                                headerText: _ctx.translate('sub-modules.slot-booking.name'),
                                subModuleKey: "slot-booking"
                            }, null, 8, ["headerText"]),
                            _createVNode(_component_DisplaySlotBookingView, {
                                model: _ctx.model?.slotBooking?.slotBooking
                            }, null, 8, ["model"])
                        ]),
                        (_ctx.model?.slotBooking?.shipmentPreNotification)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                                _createVNode(_component_DisplayUnloadingShipmentPreNotificationView, {
                                    model: _ctx.model?.slotBooking?.shipmentPreNotification
                                }, null, 8, ["model"])
                            ]))
                            : _createCommentVNode("", true)
                    ]),
                    _: 1
                }, 8, ["title", "verificationAllowed", "hasFeedback", "hasData"]),
                _createVNode(_component_ModuleSectionPanel, {
                    title: _ctx.translate('modules.safety-and-security.display'),
                    verificationAllowed: _ctx.verificationAllowed,
                    hasFeedback: !!_ctx.feedbackBySection['safetyAndSecurity'],
                    hasData: _ctx.hasData(_ctx.model?.safetyAndSecurity),
                    onClick: _cache[6] || (_cache[6] = ($event) => (_ctx.flags.safetyAndSecurity = true)),
                    onFeedback: _cache[7] || (_cache[7] = ($event) => (_ctx.openSectionFeedback('safetyAndSecurity')))
                }, {
                    default: _withCtx(() => [
                        (_ctx.model?.safetyAndSecurity?.vehicleAndPersonalProtectiveEquipment)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
                                _createVNode(_component_SubModuleTitle, {
                                    icon: "masks",
                                    headerText: _ctx.translate('sub-modules.ppe-requirement.name'),
                                    subModuleKey: "ppe-requirement"
                                }, null, 8, ["headerText"]),
                                _createVNode(_component_DisplayVehicleAndPPEUlpView, {
                                    model: _ctx.model?.safetyAndSecurity?.vehicleAndPersonalProtectiveEquipment
                                }, null, 8, ["model"])
                            ]))
                            : _createCommentVNode("", true)
                    ]),
                    _: 1
                }, 8, ["title", "verificationAllowed", "hasFeedback", "hasData"])
            ])
        ]));
}
