import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, withModifiers as _withModifiers, createElementVNode as _createElementVNode, createSlots as _createSlots, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-42cce273"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
    key: 0,
    class: "text-wrap"
};
const _hoisted_2 = { key: 1 };
const _hoisted_3 = {
    key: 0,
    class: "text-wrap"
};
const _hoisted_4 = { key: 1 };
const _hoisted_5 = {
    key: 0,
    class: "tag"
};
const _hoisted_6 = { class: "d-flex flex-column" };
const _hoisted_7 = { key: 0 };
const _hoisted_8 = { key: 1 };
const _hoisted_9 = { class: "d-flex flex-column" };
const _hoisted_10 = { key: 0 };
const _hoisted_11 = { key: 1 };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_QuickCreateLaneButton = _resolveComponent("QuickCreateLaneButton");
    const _component_Column = _resolveComponent("Column");
    const _component_PRLBadge = _resolveComponent("PRLBadge");
    const _component_ProductsTableCellProductName = _resolveComponent("ProductsTableCellProductName");
    const _component_AppMultiSelect = _resolveComponent("AppMultiSelect");
    const _component_TableCellLink = _resolveComponent("TableCellLink");
    const _component_TableRowContextMenu = _resolveComponent("TableRowContextMenu");
    const _component_DataTable = _resolveComponent("DataTable");
    const _component_AppTable = _resolveComponent("AppTable");
    return (_openBlock(), _createBlock(_component_AppTable, {
        delegate: _ctx.delegate,
        onReload: _ctx.loadMethod,
        isLoading: _ctx.isInitialLoading || _ctx.isDataLoading,
        isError: _ctx.isError,
        error: _ctx.errorResponse,
        contextTranslate: _ctx.translate,
        class: "products-table"
    }, {
        table: _withCtx((slotProps) => [
            _createVNode(_component_DataTable, {
                value: slotProps.data.results,
                onRowClick: _ctx.navigateTo,
                columnsToggleDefault: _ctx.columnsToggleDefault,
                totalRecords: slotProps.data.count,
                tableFilters: _ctx.filters,
                filters: _ctx.filters,
                "onUpdate:filters": [
                    _cache[0] || (_cache[0] = ($event) => ((_ctx.filters) = $event)),
                    _ctx.onUpdateFilters
                ],
                filterDisplay: "row",
                visibleColumns: _ctx.visibleColumns,
                "onUpdate:visibleColumns": [
                    _cache[1] || (_cache[1] = ($event) => ((_ctx.visibleColumns) = $event)),
                    _ctx.delegate.setColumns
                ],
                lazy: true,
                ref: "productsTable",
                loading: _ctx.isInitialLoading || _ctx.isDataLoading,
                onPage: _cache[2] || (_cache[2] = ($event) => (_ctx.onPage($event))),
                first: _ctx.lazyParams.first,
                selection: _ctx.selectedProducts,
                "onUpdate:selection": _cache[3] || (_cache[3] = ($event) => ((_ctx.selectedProducts) = $event)),
                onRowUnselect: _cache[4] || (_cache[4] = (event) => event.originalEvent.stopPropagation()),
                dataKey: "databaseId"
            }, _createSlots({
                default: _withCtx(() => [
                    (_ctx.isAssignedProductTable && _ctx.hasCreateAccessForLanes)
                        ? (_openBlock(), _createBlock(_component_Column, {
                            key: 0,
                            selectionMode: "multiple",
                            class: "not-resizable-column",
                            style: { "min-width": "4rem" }
                        }))
                        : _createCommentVNode("", true),
                    _createVNode(_component_Column, {
                        field: "name",
                        header: _ctx.translate('columns.name'),
                        style: { "min-width": "16rem" }
                    }, {
                        body: _withCtx((slotProps) => [
                            _createVNode(_component_ProductsTableCellProductName, {
                                product: slotProps.data
                            }, {
                                badge: _withCtx(() => [
                                    (_ctx.isPrlList)
                                        ? (_openBlock(), _createBlock(_component_PRLBadge, {
                                            key: 0,
                                            productType: slotProps.data.productType,
                                            productId: slotProps.data.id,
                                            siteId: slotProps.data.siteId,
                                            loadingPointId: slotProps.data.loadingPointId ?? undefined,
                                            unloadingPointId: slotProps.data.unloadingPointId ?? undefined,
                                            managedByExternalCompany: slotProps.data.managedByExternalCompany
                                        }, null, 8, ["productType", "productId", "siteId", "loadingPointId", "unloadingPointId", "managedByExternalCompany"]))
                                        : _createCommentVNode("", true)
                                ]),
                                _: 2
                            }, 1032, ["product"])
                        ]),
                        _: 2
                    }, 1032, ["header"]),
                    (_ctx.isColumnVisible('id'))
                        ? (_openBlock(), _createBlock(_component_Column, {
                            key: 1,
                            field: "id",
                            header: _ctx.getColumnHeader('id'),
                            style: { "min-width": "10rem" }
                        }, {
                            body: _withCtx((slotProps) => [
                                _createTextVNode(_toDisplayString(slotProps.data.id ?? "-"), 1)
                            ]),
                            _: 2
                        }, 1032, ["header"]))
                        : _createCommentVNode("", true),
                    (_ctx.isColumnVisible('referenceNumber'))
                        ? (_openBlock(), _createBlock(_component_Column, {
                            key: 2,
                            field: "referenceNumber",
                            header: _ctx.getColumnHeader('referenceNumber'),
                            filterMatchMode: "contains",
                            style: { "min-width": "10rem" }
                        }, {
                            body: _withCtx(({ data }) => [
                                (data.referenceNumber?.length)
                                    ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString(data.referenceNumber), 1))
                                    : (_openBlock(), _createElementBlock("div", _hoisted_2, "-"))
                            ]),
                            _: 1
                        }, 8, ["header"]))
                        : _createCommentVNode("", true),
                    (_ctx.isColumnVisible('authorizationGroup'))
                        ? (_openBlock(), _createBlock(_component_Column, {
                            key: 3,
                            field: "authorizationGroup",
                            header: _ctx.getColumnHeader('authorizationGroup'),
                            filterMatchMode: "contains",
                            style: { "min-width": "10rem" }
                        }, {
                            body: _withCtx(({ data }) => [
                                (data.authorizationGroup?.length)
                                    ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(data.authorizationGroup), 1))
                                    : (_openBlock(), _createElementBlock("div", _hoisted_4, "-"))
                            ]),
                            _: 1
                        }, 8, ["header"]))
                        : _createCommentVNode("", true),
                    (_ctx.isColumnVisible('transportGroup'))
                        ? (_openBlock(), _createBlock(_component_Column, {
                            key: 4,
                            field: "transportGroup",
                            header: _ctx.getColumnHeader('transportGroup'),
                            filterMatchMode: "contains",
                            style: { "min-width": "13rem" },
                            showFilterMenu: false,
                            showClearButton: false
                        }, {
                            body: _withCtx(({ data }) => [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.dictionaries.transportGroup, (type) => {
                                    return (_openBlock(), _createElementBlock(_Fragment, {
                                        key: type.id
                                    }, [
                                        (_ctx.hasTransportGroupInfo(type, data.transportGroup))
                                            ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(_ctx.transportGroupInfo(type)), 1))
                                            : _createCommentVNode("", true)
                                    ], 64));
                                }), 128))
                            ]),
                            filter: _withCtx(({ filterModel, filterCallback }) => [
                                _createVNode(_component_AppMultiSelect, {
                                    modelValue: filterModel.value,
                                    "onUpdate:modelValue": ($event) => ((filterModel.value) = $event),
                                    onChange: ($event) => (filterCallback()),
                                    options: _ctx.dictionaries.transportGroup,
                                    optionLabel: "name",
                                    dictionaryId: "TransportGroup",
                                    optionValue: "id",
                                    display: "chip",
                                    placeholder: _ctx.translate('columns.filters.transport-group')
                                }, null, 8, ["modelValue", "onUpdate:modelValue", "onChange", "options", "placeholder"])
                            ]),
                            _: 1
                        }, 8, ["header"]))
                        : _createCommentVNode("", true),
                    (_ctx.isColumnVisible('productType'))
                        ? (_openBlock(), _createBlock(_component_Column, {
                            key: 5,
                            field: "productType",
                            header: _ctx.getColumnHeader('productType'),
                            filterMatchMode: "equals",
                            style: { "min-width": "10rem" },
                            showFilterMenu: false,
                            showClearButton: false
                        }, {
                            body: _withCtx(({ data }) => [
                                _createTextVNode(_toDisplayString(_ctx.productTypeInfo(data.productType)), 1)
                            ]),
                            filter: _withCtx(({ filterModel, filterCallback }) => [
                                _createVNode(_component_AppMultiSelect, {
                                    modelValue: filterModel.value,
                                    "onUpdate:modelValue": ($event) => ((filterModel.value) = $event),
                                    onChange: ($event) => (filterCallback()),
                                    options: _ctx.dictionaries.productType,
                                    optionLabel: "name",
                                    dictionaryId: "ProductType",
                                    optionValue: "id",
                                    display: "chip",
                                    placeholder: _ctx.translate('columns.filters.product-type')
                                }, null, 8, ["modelValue", "onUpdate:modelValue", "onChange", "options", "placeholder"])
                            ]),
                            _: 1
                        }, 8, ["header"]))
                        : _createCommentVNode("", true),
                    (_ctx.isColumnVisible('site'))
                        ? (_openBlock(), _createBlock(_component_Column, {
                            key: 6,
                            field: "site",
                            header: _ctx.getColumnHeader('site'),
                            filterMatchMode: "equals",
                            style: { "min-width": "10rem" },
                            showFilterMenu: false,
                            showClearButton: false
                        }, {
                            body: _withCtx(({ data }) => [
                                _createTextVNode(_toDisplayString(data.siteName ?? "-"), 1)
                            ]),
                            filter: _withCtx(({ filterModel, filterCallback }) => [
                                _createVNode(_component_AppMultiSelect, {
                                    modelValue: filterModel.value,
                                    "onUpdate:modelValue": ($event) => ((filterModel.value) = $event),
                                    onChange: ($event) => (filterCallback()),
                                    options: _ctx.availableSites,
                                    optionLabel: "value",
                                    optionValue: "value",
                                    display: "chip",
                                    placeholder: _ctx.translate('columns.filters.site')
                                }, null, 8, ["modelValue", "onUpdate:modelValue", "onChange", "options", "placeholder"])
                            ]),
                            _: 1
                        }, 8, ["header"]))
                        : _createCommentVNode("", true),
                    (_ctx.isColumnVisible('building'))
                        ? (_openBlock(), _createBlock(_component_Column, {
                            key: 7,
                            field: "building",
                            header: _ctx.getColumnHeader('building'),
                            filterMatchMode: "equals",
                            style: { "min-width": "10rem" },
                            showFilterMenu: false,
                            showClearButton: false
                        }, {
                            body: _withCtx(({ data }) => [
                                _createTextVNode(_toDisplayString(data.buildingName ?? "-"), 1)
                            ]),
                            filter: _withCtx(({ filterModel, filterCallback }) => [
                                _createVNode(_component_AppMultiSelect, {
                                    modelValue: filterModel.value,
                                    "onUpdate:modelValue": ($event) => ((filterModel.value) = $event),
                                    onChange: ($event) => (filterCallback()),
                                    options: _ctx.availableBuildings,
                                    optionLabel: "value",
                                    optionValue: "value",
                                    display: "chip",
                                    placeholder: _ctx.translate('columns.filters.building')
                                }, null, 8, ["modelValue", "onUpdate:modelValue", "onChange", "options", "placeholder"])
                            ]),
                            _: 1
                        }, 8, ["header"]))
                        : _createCommentVNode("", true),
                    (_ctx.isColumnVisible('loadingPoint'))
                        ? (_openBlock(), _createBlock(_component_Column, {
                            key: 8,
                            field: "loadingPoint",
                            header: _ctx.getColumnHeader('loadingPoint'),
                            filterMatchMode: "equals",
                            style: { "min-width": "10rem" },
                            showFilterMenu: false,
                            showClearButton: false
                        }, {
                            body: _withCtx(({ data }) => [
                                (data.loadingPointName)
                                    ? (_openBlock(), _createBlock(_component_TableCellLink, {
                                        key: 0,
                                        values: [data.loadingPointId, data.siteId],
                                        onClick: _withModifiers(($event) => (_ctx.linkService.goToEntity(data.companyId, _ctx.LoadingPointDomainId, data.loadingPointId, _ctx.SiteDomainId, data.siteId)), ["stop"])
                                    }, {
                                        default: _withCtx(() => [
                                            _createTextVNode(_toDisplayString(data.loadingPointName ?? "-"), 1)
                                        ]),
                                        _: 2
                                    }, 1032, ["values", "onClick"]))
                                    : _createCommentVNode("", true)
                            ]),
                            filter: _withCtx(({ filterModel, filterCallback }) => [
                                _createVNode(_component_AppMultiSelect, {
                                    modelValue: filterModel.value,
                                    "onUpdate:modelValue": ($event) => ((filterModel.value) = $event),
                                    onChange: ($event) => (filterCallback()),
                                    options: _ctx.availableLoadingorUnloadingPoints,
                                    optionLabel: "value",
                                    optionValue: "value",
                                    display: "chip",
                                    placeholder: _ctx.translate('columns.filters.loading-point')
                                }, null, 8, ["modelValue", "onUpdate:modelValue", "onChange", "options", "placeholder"])
                            ]),
                            _: 1
                        }, 8, ["header"]))
                        : _createCommentVNode("", true),
                    (_ctx.isColumnVisible('unloadingPoint'))
                        ? (_openBlock(), _createBlock(_component_Column, {
                            key: 9,
                            field: "unloadingPoint",
                            header: _ctx.getColumnHeader('unloadingPoint'),
                            filterMatchMode: "equals",
                            style: { "min-width": "10rem" },
                            showFilterMenu: false,
                            showClearButton: false
                        }, {
                            body: _withCtx(({ data }) => [
                                (data.unloadingPointName)
                                    ? (_openBlock(), _createBlock(_component_TableCellLink, {
                                        key: 0,
                                        values: [data.unloadingPointId, data.siteId],
                                        onClick: _withModifiers(($event) => (_ctx.linkService.goToEntity(data.companyId, _ctx.UnloadingPointDomainId, data.unloadingPointId, _ctx.SiteDomainId, data.siteId)), ["stop"])
                                    }, {
                                        default: _withCtx(() => [
                                            _createTextVNode(_toDisplayString(data.unloadingPointName ?? "-"), 1)
                                        ]),
                                        _: 2
                                    }, 1032, ["values", "onClick"]))
                                    : _createCommentVNode("", true)
                            ]),
                            filter: _withCtx(({ filterModel, filterCallback }) => [
                                _createVNode(_component_AppMultiSelect, {
                                    modelValue: filterModel.value,
                                    "onUpdate:modelValue": ($event) => ((filterModel.value) = $event),
                                    onChange: ($event) => (filterCallback()),
                                    options: _ctx.availableLoadingorUnloadingPoints,
                                    optionLabel: "value",
                                    optionValue: "value",
                                    display: "chip",
                                    placeholder: _ctx.translate('columns.filters.unloading-point')
                                }, null, 8, ["modelValue", "onUpdate:modelValue", "onChange", "options", "placeholder"])
                            ]),
                            _: 1
                        }, 8, ["header"]))
                        : _createCommentVNode("", true),
                    (_ctx.isColumnVisible('entityCreationDate'))
                        ? (_openBlock(), _createBlock(_component_Column, {
                            key: 10,
                            field: "entityCreationDate",
                            header: _ctx.getColumnHeader('entityCreationDate'),
                            dataType: "date",
                            style: { "min-width": "12rem" }
                        }, {
                            body: _withCtx(({ data }) => [
                                _createElementVNode("div", _hoisted_6, [
                                    (data.createdTime && data.createdBy)
                                        ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                                            _createElementVNode("div", null, _toDisplayString(_ctx.$utils.getDateValue(data.createdTime)), 1),
                                            _createElementVNode("div", null, _toDisplayString(_ctx.getByUserTranslation(data.createdBy)), 1)
                                        ]))
                                        : (_openBlock(), _createElementBlock("div", _hoisted_8, "-"))
                                ])
                            ]),
                            _: 1
                        }, 8, ["header"]))
                        : _createCommentVNode("", true),
                    (_ctx.isColumnVisible('updatedTime'))
                        ? (_openBlock(), _createBlock(_component_Column, {
                            key: 11,
                            field: "updatedTime",
                            header: _ctx.getColumnHeader('updatedTime'),
                            dataType: "date",
                            style: { "min-width": "12rem" }
                        }, {
                            body: _withCtx(({ data }) => [
                                _createElementVNode("div", _hoisted_9, [
                                    (data.updatedTime && data.updatedBy)
                                        ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                                            _createElementVNode("div", null, _toDisplayString(_ctx.$utils.getDateValue(data.updatedTime)), 1),
                                            _createElementVNode("div", null, _toDisplayString(_ctx.getByUserTranslation(data.updatedBy)), 1)
                                        ]))
                                        : (_openBlock(), _createElementBlock("div", _hoisted_11, "-"))
                                ])
                            ]),
                            _: 1
                        }, 8, ["header"]))
                        : _createCommentVNode("", true),
                    (_ctx.isContextMenuAvailable)
                        ? (_openBlock(), _createBlock(_component_Column, {
                            key: 12,
                            class: "not-resizable-column",
                            style: { "min-width": "6rem" }
                        }, {
                            body: _withCtx((slotProps) => [
                                (_ctx.roleCheck([..._ctx.$permissions.canCRUDEntity, _ctx.$roles.productManager], slotProps.data.companyId))
                                    ? (_openBlock(), _createBlock(_component_TableRowContextMenu, {
                                        key: 0,
                                        onDelete: _ctx.delegate.deleteItem,
                                        data: slotProps.data,
                                        contextTranslate: _ctx.translate
                                    }, null, 8, ["onDelete", "data", "contextTranslate"]))
                                    : _createCommentVNode("", true)
                            ]),
                            _: 2
                        }, 1024))
                        : _createCommentVNode("", true)
                ]),
                _: 2
            }, [
                (_ctx.isAssignedProductTable && _ctx.hasCreateAccessForLanes)
                    ? {
                        name: "header-buttons",
                        fn: _withCtx(() => [
                            _createVNode(_component_QuickCreateLaneButton, { "selected-product-data": _ctx.selectedProductData }, null, 8, ["selected-product-data"])
                        ]),
                        key: "0"
                    }
                    : undefined
            ]), 1032, ["value", "onRowClick", "columnsToggleDefault", "totalRecords", "tableFilters", "filters", "onUpdate:filters", "visibleColumns", "onUpdate:visibleColumns", "loading", "first", "selection"])
        ]),
        _: 1
    }, 8, ["delegate", "onReload", "isLoading", "isError", "error", "contextTranslate"]));
}
