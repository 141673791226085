import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-66018b85"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "display-documents" };
const _hoisted_2 = {
    key: 0,
    class: "description"
};
const _hoisted_3 = { class: "row section" };
const _hoisted_4 = { class: "title col-6" };
const _hoisted_5 = { class: "tags col-6" };
const _hoisted_6 = {
    key: 1,
    class: "description"
};
const _hoisted_7 = { class: "row section" };
const _hoisted_8 = { class: "title col-6" };
const _hoisted_9 = { class: "tags col-6" };
const _hoisted_10 = {
    key: 1,
    class: "description"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_DisplayAttachments = _resolveComponent("DisplayAttachments");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("h5", null, _toDisplayString(_ctx.translate("name")), 1),
        (_ctx.noSpecialDocumentRequirements)
            ? (_openBlock(), _createElementBlock("p", _hoisted_2, _toDisplayString(_ctx.translate("no-special-documents-required")), 1))
            : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                _createElementVNode("div", _hoisted_3, [
                    _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.translate("loading-documents")), 1),
                    _createElementVNode("div", _hoisted_5, [
                        (!!_ctx.documents?.documentsForLoading?.length)
                            ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.documents.documentsForLoading, (head, index) => {
                                return (_openBlock(), _createElementBlock("span", {
                                    key: head + index,
                                    class: "tag"
                                }, _toDisplayString(_ctx.getValue(head, "DocumentForUnloadingLoading")), 1));
                            }), 128))
                            : (_openBlock(), _createElementBlock("p", _hoisted_6, "-"))
                    ])
                ]),
                _createElementVNode("div", _hoisted_7, [
                    _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.translate("unloading-documents")), 1),
                    _createElementVNode("div", _hoisted_9, [
                        (!!_ctx.documents?.documentsForUnloading?.length)
                            ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.documents.documentsForUnloading, (body, index) => {
                                return (_openBlock(), _createElementBlock("span", {
                                    key: body + index,
                                    class: "tag"
                                }, _toDisplayString(_ctx.getValue(body, "DocumentForUnloadingLoading")), 1));
                            }), 128))
                            : (_openBlock(), _createElementBlock("p", _hoisted_10, "-"))
                    ])
                ]),
                _createVNode(_component_DisplayAttachments, {
                    class: "section",
                    attachments: _ctx.filterByLocale(_ctx.documents?.restrictionsOnDocuments),
                    title: _ctx.translate('additional-requirements-on-documents')
                }, null, 8, ["attachments", "title"])
            ], 64))
    ]));
}
