var _a, _b;
import { __decorate, __metadata, __param } from "tslib";
import { CancelEditModalId } from "@/app/core/core.constants";
import { ModalService } from "@/app/modals/services/modal.service";
import { modal } from "@/app/shared/decorators/inject-modal.decorator";
import { UserStorageService } from "@/app/shared/services/user-storage.service";
import { service } from "@/di/decorators/service.decorator";
import { firstValueFrom } from "rxjs";
import { supportedLanguages } from "@/app/translation/translation.constants";
const defaultDataLanguage = "en";
let DataLocalisationService = class DataLocalisationService {
    userStorageService;
    cancelModal;
    currentDataLanguage = defaultDataLanguage;
    constructor(userStorageService, cancelModal) {
        this.userStorageService = userStorageService;
        this.cancelModal = cancelModal;
    }
    onAppInit() {
        this.setDataLanguage(this.userStorageService.get("dataLanguage") ?? defaultDataLanguage);
    }
    setDataLanguage(dataLanguage) {
        this.currentDataLanguage = dataLanguage;
        this.userStorageService.set("dataLanguage", dataLanguage);
    }
    getDataLanguage() {
        return this.currentDataLanguage;
    }
    getSupportedDataLanguages() {
        return supportedLanguages;
    }
    getDataLanguagePrimary() {
        return defaultDataLanguage;
    }
    async cancel() {
        this.cancelModal.setVisible(true);
        const result = await firstValueFrom(this.cancelModal.onResultChange());
        return result.confirmed;
    }
};
DataLocalisationService = __decorate([
    service(),
    __param(1, modal(CancelEditModalId)),
    __metadata("design:paramtypes", [typeof (_a = typeof UserStorageService !== "undefined" && UserStorageService) === "function" ? _a : Object, typeof (_b = typeof ModalService !== "undefined" && ModalService) === "function" ? _b : Object])
], DataLocalisationService);
export { DataLocalisationService };
