import { toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, createVNode as _createVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-1a3ac40e"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "display-safety-test" };
const _hoisted_2 = { class: "section" };
const _hoisted_3 = { class: "row" };
const _hoisted_4 = { class: "col-6 column-header globe-icon-wrapper" };
const _hoisted_5 = { class: "col-6 column-description" };
const _hoisted_6 = {
    key: 0,
    class: "row"
};
const _hoisted_7 = { class: "col-6 offset-6 mt-1 column-description" };
const _hoisted_8 = { key: 0 };
const _hoisted_9 = { class: "row section" };
const _hoisted_10 = { class: "col-6 column-header" };
const _hoisted_11 = { class: "col-6 column-description" };
const _hoisted_12 = { class: "section" };
const _hoisted_13 = { class: "row section" };
const _hoisted_14 = { class: "col-6 column-header" };
const _hoisted_15 = { class: "col-6 column-description" };
const _hoisted_16 = {
    key: 0,
    class: "row section"
};
const _hoisted_17 = { class: "col-6 column-header" };
const _hoisted_18 = { class: "col-6" };
const _hoisted_19 = ["href"];
const _hoisted_20 = {
    key: 1,
    class: "column-description"
};
const _hoisted_21 = { key: 1 };
const _hoisted_22 = { class: "row section" };
const _hoisted_23 = { class: "col-6 column-header" };
const _hoisted_24 = { class: "col-6 column-description" };
const _hoisted_25 = { class: "col-3" };
const _hoisted_26 = { class: "row section" };
const _hoisted_27 = { class: "col-6 column-header" };
const _hoisted_28 = { class: "col-6" };
const _hoisted_29 = { class: "row section" };
const _hoisted_30 = { class: "col-6 column-header" };
const _hoisted_31 = { class: "col-6 column-description" };
const _hoisted_32 = { key: 2 };
const _hoisted_33 = {
    key: 0,
    class: "row section"
};
const _hoisted_34 = { class: "col-6 offset-6 column-description" };
const _hoisted_35 = {
    key: 1,
    class: "row section"
};
const _hoisted_36 = { class: "col-6 offset-6" };
const _hoisted_37 = ["href"];
const _hoisted_38 = {
    key: 2,
    class: "row section"
};
const _hoisted_39 = { class: "col-6 offset-6" };
const _hoisted_40 = ["href"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_GlobeIcon = _resolveComponent("GlobeIcon");
    const _component_DisplayAttachments = _resolveComponent("DisplayAttachments");
    const _component_DisplayAddress = _resolveComponent("DisplayAddress");
    const _component_DisplayLocation = _resolveComponent("DisplayLocation");
    const _component_EmptyStateMessage = _resolveComponent("EmptyStateMessage");
    const _component_OpeningHoursTable = _resolveComponent("OpeningHoursTable");
    const _component_Icon = _resolveComponent("Icon");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
                _createElementVNode("div", _hoisted_4, [
                    _createTextVNode(_toDisplayString(_ctx.translate("safety-test-required")) + " ", 1),
                    (_ctx.isRequiredOnlyInCaseOf)
                        ? (_openBlock(), _createBlock(_component_GlobeIcon, {
                            key: 0,
                            size: "md"
                        }))
                        : _createCommentVNode("", true)
                ]),
                _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.requirementStatus), 1)
            ]),
            (_ctx.isRequiredOnlyInCaseOf && _ctx.model?.requiredText)
                ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                    _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.getLocaleValue(_ctx.model.requiredText)), 1)
                ]))
                : _createCommentVNode("", true)
        ]),
        (_ctx.requirementAvailable)
            ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                _createElementVNode("div", _hoisted_9, [
                    _createElementVNode("div", _hoisted_10, _toDisplayString(_ctx.translate("test-language")), 1),
                    _createElementVNode("div", _hoisted_11, _toDisplayString(_ctx.languagesInfo), 1)
                ]),
                _createElementVNode("div", _hoisted_12, [
                    _createVNode(_component_DisplayAttachments, {
                        attachments: _ctx.instructionsLocalised,
                        title: _ctx.translate('test-instructions')
                    }, null, 8, ["attachments", "title"])
                ]),
                _createElementVNode("div", _hoisted_13, [
                    _createElementVNode("div", _hoisted_14, _toDisplayString(_ctx.translate("safety-test-procedure")), 1),
                    _createElementVNode("div", _hoisted_15, _toDisplayString(_ctx.safetyTestProcedureInfo), 1)
                ]),
                (_ctx.model?.procedure.isOnline)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
                        _createElementVNode("div", _hoisted_17, _toDisplayString(_ctx.translate("link-to-online-test")), 1),
                        _createElementVNode("div", _hoisted_18, [
                            (_ctx.model.procedure.onlineLink)
                                ? (_openBlock(), _createElementBlock("a", {
                                    key: 0,
                                    href: _ctx.model.procedure.onlineLink,
                                    target: "_blank",
                                    class: "column-link-value"
                                }, _toDisplayString(_ctx.model.procedure.onlineLink), 9, _hoisted_19))
                                : (_openBlock(), _createElementBlock("span", _hoisted_20, "-"))
                        ])
                    ]))
                    : _createCommentVNode("", true),
                (_ctx.model?.procedure.isInPresence)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_21, [
                        _createElementVNode("div", _hoisted_22, [
                            _createElementVNode("div", _hoisted_23, _toDisplayString(_ctx.translate("safety-test-adress")), 1),
                            _createElementVNode("div", _hoisted_24, [
                                _createVNode(_component_DisplayAddress, {
                                    short: "",
                                    address: _ctx.model.procedure.inPresencePostalAddress
                                }, null, 8, ["address"])
                            ]),
                            _createElementVNode("div", _hoisted_25, [
                                _createVNode(_component_DisplayLocation, {
                                    class: "map-button",
                                    location: _ctx.model.procedure.inPresenceLocation
                                }, null, 8, ["location"])
                            ])
                        ]),
                        _createElementVNode("div", _hoisted_26, [
                            _createElementVNode("div", _hoisted_27, _toDisplayString(_ctx.translate("safety-test-opening-hours")), 1),
                            _createElementVNode("div", _hoisted_28, [
                                (_ctx.noOpeningHoursDefined)
                                    ? (_openBlock(), _createBlock(_component_EmptyStateMessage, { key: 0 }, {
                                        default: _withCtx(() => [
                                            _createTextVNode(_toDisplayString(_ctx.translate("phrases.empty-opening-hours")), 1)
                                        ]),
                                        _: 1
                                    }))
                                    : (_openBlock(), _createBlock(_component_OpeningHoursTable, {
                                        key: 1,
                                        "opening-hours": _ctx.model.procedure.inPresenceOpeningHours
                                    }, null, 8, ["opening-hours"]))
                            ])
                        ])
                    ]))
                    : _createCommentVNode("", true),
                _createElementVNode("div", _hoisted_29, [
                    _createElementVNode("div", _hoisted_30, _toDisplayString(_ctx.translate("slot-booking-for-safety-test")), 1),
                    _createElementVNode("div", _hoisted_31, _toDisplayString(_ctx.slotInfo), 1)
                ]),
                (_ctx.model?.slotBooking.isRequired)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_32, [
                        (_ctx.model.slotBooking.telephone)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_33, [
                                _createElementVNode("div", _hoisted_34, _toDisplayString(_ctx.model.slotBooking.telephone), 1)
                            ]))
                            : _createCommentVNode("", true),
                        (_ctx.model.slotBooking.email)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_35, [
                                _createElementVNode("div", _hoisted_36, [
                                    _createElementVNode("a", {
                                        href: `mailto:${_ctx.model.slotBooking.email}`,
                                        target: "_blank",
                                        class: "column-link-value"
                                    }, _toDisplayString(_ctx.model.slotBooking.email), 9, _hoisted_37)
                                ])
                            ]))
                            : _createCommentVNode("", true),
                        (_ctx.model.slotBooking.link)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_38, [
                                _createElementVNode("div", _hoisted_39, [
                                    _createElementVNode("a", {
                                        href: _ctx.model.slotBooking.link,
                                        target: "_blank",
                                        class: "column-link-value"
                                    }, [
                                        _createTextVNode(_toDisplayString(_ctx.translate("booking-tool")) + " ", 1),
                                        _createVNode(_component_Icon, { name: "launch" })
                                    ], 8, _hoisted_40)
                                ])
                            ]))
                            : _createCommentVNode("", true)
                    ]))
                    : _createCommentVNode("", true)
            ]))
            : _createCommentVNode("", true)
    ]));
}
