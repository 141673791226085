import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-1ece965e"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "display-cargo-securing" };
const _hoisted_2 = {
    key: 0,
    class: "column-value mb-0"
};
const _hoisted_3 = { key: 1 };
const _hoisted_4 = { class: "row" };
const _hoisted_5 = { class: "col-6 column-header globe-icon-wrapper" };
const _hoisted_6 = { class: "col-6 column-value" };
const _hoisted_7 = { class: "row" };
const _hoisted_8 = { class: "col-6 column-header globe-icon-wrapper" };
const _hoisted_9 = { class: "col-6 column-value" };
const _hoisted_10 = { class: "row" };
const _hoisted_11 = { class: "col-6" };
const _hoisted_12 = { class: "row" };
const _hoisted_13 = { class: "col-6 column-header" };
const _hoisted_14 = { class: "col-6 column-value" };
const _hoisted_15 = {
    key: 0,
    class: "row"
};
const _hoisted_16 = { class: "col-6 column-header" };
const _hoisted_17 = { class: "col-6 column-value" };
const _hoisted_18 = { class: "row" };
const _hoisted_19 = { class: "col-6 column-header" };
const _hoisted_20 = { class: "col-6 column-value" };
const _hoisted_21 = { class: "row" };
const _hoisted_22 = { class: "col-6 column-header" };
const _hoisted_23 = {
    key: 0,
    class: "col-6 tags"
};
const _hoisted_24 = {
    key: 1,
    class: "col-6 column-value"
};
const _hoisted_25 = { class: "row" };
const _hoisted_26 = { class: "col-6 column-header globe-icon-wrapper" };
const _hoisted_27 = { class: "col-6 column-value" };
const _hoisted_28 = { class: "row" };
const _hoisted_29 = { class: "col-6" };
const _hoisted_30 = { class: "row" };
const _hoisted_31 = { class: "col-6 column-header" };
const _hoisted_32 = { class: "col-6 column-value" };
const _hoisted_33 = { class: "row" };
const _hoisted_34 = { class: "col-6 column-header globe-icon-wrapper" };
const _hoisted_35 = { class: "col-6 column-value" };
const _hoisted_36 = { class: "row" };
const _hoisted_37 = { class: "col-6 column-header globe-icon-wrapper" };
const _hoisted_38 = { class: "col-6 column-value" };
const _hoisted_39 = { class: "row" };
const _hoisted_40 = { class: "col-6 column-header globe-icon-wrapper" };
const _hoisted_41 = { class: "col-6 column-value" };
const _hoisted_42 = { class: "row" };
const _hoisted_43 = { class: "col-6 column-header globe-icon-wrapper" };
const _hoisted_44 = { class: "col-6 column-value" };
const _hoisted_45 = { class: "row" };
const _hoisted_46 = { class: "col-6" };
const _hoisted_47 = { class: "row" };
const _hoisted_48 = { class: "col-6 column-header" };
const _hoisted_49 = { class: "col-6 column-value" };
const _hoisted_50 = { class: "row" };
const _hoisted_51 = { class: "col-6 column-header" };
const _hoisted_52 = { class: "col-6 column-value" };
const _hoisted_53 = { class: "row" };
const _hoisted_54 = { class: "col-6 column-header" };
const _hoisted_55 = { class: "col-6 column-value" };
const _hoisted_56 = { class: "row" };
const _hoisted_57 = { class: "col-6 column-header" };
const _hoisted_58 = { class: "col-6 column-value" };
const _hoisted_59 = { class: "row" };
const _hoisted_60 = { class: "col-6 column-header" };
const _hoisted_61 = { class: "col-6 column-value" };
const _hoisted_62 = { class: "row" };
const _hoisted_63 = { class: "col-6 column-header" };
const _hoisted_64 = { class: "col-6 column-value" };
const _hoisted_65 = { class: "row" };
const _hoisted_66 = { class: "col-6 column-header" };
const _hoisted_67 = { class: "col-6 column-value" };
const _hoisted_68 = { class: "row" };
const _hoisted_69 = { class: "col-6 column-header" };
const _hoisted_70 = { class: "col-6 column-value" };
const _hoisted_71 = { class: "row" };
const _hoisted_72 = { class: "col-6" };
const _hoisted_73 = { class: "row" };
const _hoisted_74 = { class: "col-6 column-header" };
const _hoisted_75 = { class: "col-6 column-value" };
const _hoisted_76 = { class: "row" };
const _hoisted_77 = { class: "col-6 column-header globe-icon-wrapper" };
const _hoisted_78 = { class: "col-6 column-value" };
const _hoisted_79 = { class: "row" };
const _hoisted_80 = { class: "col-6 column-header" };
const _hoisted_81 = { class: "col-6 column-value" };
const _hoisted_82 = { class: "row" };
const _hoisted_83 = { class: "col-6 column-header" };
const _hoisted_84 = { class: "col-6 column-value" };
const _hoisted_85 = { class: "row" };
const _hoisted_86 = { class: "col-6 column-header" };
const _hoisted_87 = { class: "col-6 column-value" };
const _hoisted_88 = { class: "row" };
const _hoisted_89 = { class: "col-6 column-header" };
const _hoisted_90 = { class: "col-6 column-value" };
const _hoisted_91 = { class: "row" };
const _hoisted_92 = { class: "col-6 column-header" };
const _hoisted_93 = { class: "col-6 column-value" };
const _hoisted_94 = { class: "row" };
const _hoisted_95 = { class: "col-6 column-header" };
const _hoisted_96 = { class: "col-6 column-value" };
const _hoisted_97 = { class: "row" };
const _hoisted_98 = { class: "col-6" };
const _hoisted_99 = { class: "row" };
const _hoisted_100 = { class: "col-6 column-header" };
const _hoisted_101 = { class: "col-6 column-value" };
const _hoisted_102 = { class: "row" };
const _hoisted_103 = { class: "col-6 column-header globe-icon-wrapper" };
const _hoisted_104 = { class: "col-6 column-value" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_GlobeIcon = _resolveComponent("GlobeIcon");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_ctx.noSpecialRequirements)
            ? (_openBlock(), _createElementBlock("p", _hoisted_2, _toDisplayString(_ctx.translate("phrases.no-special-requirements")), 1))
            : (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createElementVNode("h6", null, _toDisplayString(_ctx.translate("min-requirements-below-3-5-t")), 1),
                _createElementVNode("div", _hoisted_4, [
                    _createElementVNode("div", _hoisted_5, [
                        _createTextVNode(_toDisplayString(_ctx.translate("min-requirements-below-3-5-t")) + " ", 1),
                        _createVNode(_component_GlobeIcon, { size: "md" })
                    ]),
                    _createElementVNode("div", _hoisted_6, [
                        _createElementVNode("div", null, _toDisplayString(_ctx.getLocaleValue(_ctx.model?.minimumRequirementsForVehiclesBelowThreeAndHalfTonsGVWR)), 1)
                    ])
                ]),
                _createElementVNode("h6", null, _toDisplayString(_ctx.translate("min-requirements-above-3-5-t")), 1),
                _createElementVNode("div", _hoisted_7, [
                    _createElementVNode("div", _hoisted_8, [
                        _createTextVNode(_toDisplayString(_ctx.translate("min-requirements-above-3-5-t")) + " ", 1),
                        _createVNode(_component_GlobeIcon, { size: "md" })
                    ]),
                    _createElementVNode("div", _hoisted_9, [
                        _createElementVNode("div", null, _toDisplayString(_ctx.getLocaleValue(_ctx.model?.minimumRequirementsForVehiclesAboveThreeAndHalfTonsGVWR)), 1)
                    ])
                ]),
                _createElementVNode("h6", null, _toDisplayString(_ctx.translate("cargo-securing-material")), 1),
                _createElementVNode("div", _hoisted_10, [
                    _createElementVNode("div", _hoisted_11, _toDisplayString(_ctx.translate("certified-cargo-securing-system")), 1)
                ]),
                _createElementVNode("div", _hoisted_12, [
                    _createElementVNode("div", _hoisted_13, _toDisplayString(_ctx.translate("certified-cargo-securing-system")), 1),
                    _createElementVNode("div", _hoisted_14, _toDisplayString(_ctx.getDictionaryValue(_ctx.model?.cargoSecuringMaterial?.certifiedCargoSecuringSystem?.isRequired, "CertifiedCargoSecuringSystemRequired")), 1)
                ]),
                (_ctx.model?.cargoSecuringMaterial?.certifiedCargoSecuringSystem?.isRequired === 'RequiredOnlyInCaseOf')
                    ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                        _createElementVNode("div", _hoisted_16, _toDisplayString(_ctx.translate("required-only-in-case-of")), 1),
                        _createElementVNode("div", _hoisted_17, _toDisplayString(_ctx.getValue(_ctx.model?.cargoSecuringMaterial?.certifiedCargoSecuringSystem?.requiredOnlyInCaseOf)), 1)
                    ]))
                    : _createCommentVNode("", true),
                _createElementVNode("div", _hoisted_18, [
                    _createElementVNode("div", _hoisted_19, _toDisplayString(_ctx.translate("system-to-be-provided-by")), 1),
                    _createElementVNode("div", _hoisted_20, _toDisplayString(_ctx.getDictionaryValue(_ctx.model?.cargoSecuringMaterial?.certifiedCargoSecuringSystem?.providedBy, "LoadSecuringMaterialProvidedBy")), 1)
                ]),
                _createElementVNode("div", _hoisted_21, [
                    _createElementVNode("div", _hoisted_22, _toDisplayString(_ctx.translate("system-specification")), 1),
                    (_ctx.model?.cargoSecuringMaterial?.certifiedCargoSecuringSystem?.systemSpecification?.length)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_23, [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.model?.cargoSecuringMaterial?.certifiedCargoSecuringSystem?.systemSpecification, (tag, index) => {
                                return (_openBlock(), _createElementBlock("span", {
                                    key: tag + index,
                                    class: "tag"
                                }, _toDisplayString(_ctx.getDictionaryValue(tag, "SystemSpecification")), 1));
                            }), 128))
                        ]))
                        : (_openBlock(), _createElementBlock("div", _hoisted_24, "-"))
                ]),
                _createElementVNode("div", _hoisted_25, [
                    _createElementVNode("div", _hoisted_26, [
                        _createTextVNode(_toDisplayString(_ctx.translate("additional-information")) + " ", 1),
                        _createVNode(_component_GlobeIcon, { size: "md" })
                    ]),
                    _createElementVNode("div", _hoisted_27, [
                        _createElementVNode("div", null, _toDisplayString(_ctx.getLocaleValue(_ctx.model?.cargoSecuringMaterial?.certifiedCargoSecuringSystem?.additionalInformation)), 1)
                    ])
                ]),
                _createElementVNode("div", _hoisted_28, [
                    _createElementVNode("div", _hoisted_29, _toDisplayString(_ctx.translate("slat-boards")), 1)
                ]),
                _createElementVNode("div", _hoisted_30, [
                    _createElementVNode("div", _hoisted_31, _toDisplayString(_ctx.translate("slat-boards-to-be-provided-by")), 1),
                    _createElementVNode("div", _hoisted_32, _toDisplayString(_ctx.getDictionaryValue(_ctx.model?.cargoSecuringMaterial?.slatBoards?.providedBy, "LoadSecuringMaterialProvidedBy")), 1)
                ]),
                _createElementVNode("div", _hoisted_33, [
                    _createElementVNode("div", _hoisted_34, [
                        _createTextVNode(_toDisplayString(_ctx.translate("tarpaulin-trailers")) + " ", 1),
                        _createVNode(_component_GlobeIcon, { size: "md" })
                    ]),
                    _createElementVNode("div", _hoisted_35, [
                        _createElementVNode("div", null, _toDisplayString(_ctx.getLocaleValue(_ctx.model?.cargoSecuringMaterial?.slatBoards?.tarpaulinTrailers)), 1)
                    ])
                ]),
                _createElementVNode("div", _hoisted_36, [
                    _createElementVNode("div", _hoisted_37, [
                        _createTextVNode(_toDisplayString(_ctx.translate("slat-boards-curtain-side-trailers")) + " ", 1),
                        _createVNode(_component_GlobeIcon, { size: "md" })
                    ]),
                    _createElementVNode("div", _hoisted_38, [
                        _createElementVNode("div", null, _toDisplayString(_ctx.getLocaleValue(_ctx.model?.cargoSecuringMaterial?.slatBoards?.curtainSideTrailers)), 1)
                    ])
                ]),
                _createElementVNode("div", _hoisted_39, [
                    _createElementVNode("div", _hoisted_40, [
                        _createTextVNode(_toDisplayString(_ctx.translate("tarpaulin-superstructures")) + " ", 1),
                        _createVNode(_component_GlobeIcon, { size: "md" })
                    ]),
                    _createElementVNode("div", _hoisted_41, [
                        _createElementVNode("div", null, _toDisplayString(_ctx.getLocaleValue(_ctx.model?.cargoSecuringMaterial?.slatBoards?.tarpaulinSuperstructures)), 1)
                    ])
                ]),
                _createElementVNode("div", _hoisted_42, [
                    _createElementVNode("div", _hoisted_43, [
                        _createTextVNode(_toDisplayString(_ctx.translate("box-truck")) + " ", 1),
                        _createVNode(_component_GlobeIcon, { size: "md" })
                    ]),
                    _createElementVNode("div", _hoisted_44, [
                        _createElementVNode("div", null, _toDisplayString(_ctx.getLocaleValue(_ctx.model?.cargoSecuringMaterial?.slatBoards?.boxTruck)), 1)
                    ])
                ]),
                _createElementVNode("div", _hoisted_45, [
                    _createElementVNode("div", _hoisted_46, _toDisplayString(_ctx.translate("anti-slip-mats")), 1)
                ]),
                _createElementVNode("div", _hoisted_47, [
                    _createElementVNode("div", _hoisted_48, _toDisplayString(_ctx.translate("anti-slip-mats-to-be-provided-by")), 1),
                    _createElementVNode("div", _hoisted_49, _toDisplayString(_ctx.getDictionaryValue(_ctx.model?.cargoSecuringMaterial?.antiSlipMats?.providedBy, "LoadSecuringMaterialProvidedBy")), 1)
                ]),
                _createElementVNode("div", _hoisted_50, [
                    _createElementVNode("div", _hoisted_51, _toDisplayString(_ctx.translate("carrier-ensures-number-of-anti-slip-mats")), 1),
                    _createElementVNode("div", _hoisted_52, _toDisplayString(_ctx.getDictionaryValue(_ctx.model?.cargoSecuringMaterial?.antiSlipMats?.carrierToEnsureSufficientNumberOfAntiSlipMatsToSecureCargo, "CarrierDecidesAboutNumberOfToSecuringCargo")), 1)
                ]),
                (_ctx.isCompanyGuideline(_ctx.model?.cargoSecuringMaterial?.antiSlipMats?.carrierToEnsureSufficientNumberOfAntiSlipMatsToSecureCargo))
                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                        _createElementVNode("div", _hoisted_53, [
                            _createElementVNode("div", _hoisted_54, _toDisplayString(_ctx.translate("min-number-per-pallet")), 1),
                            _createElementVNode("div", _hoisted_55, _toDisplayString(_ctx.getNumericValue(_ctx.model?.cargoSecuringMaterial?.antiSlipMats?.minimumNumberPerPallet)), 1)
                        ]),
                        _createElementVNode("div", _hoisted_56, [
                            _createElementVNode("div", _hoisted_57, _toDisplayString(_ctx.translate("min-number-per-truck")), 1),
                            _createElementVNode("div", _hoisted_58, _toDisplayString(_ctx.getNumericValue(_ctx.model?.cargoSecuringMaterial?.antiSlipMats?.minimumNumberPerTruck)), 1)
                        ]),
                        _createElementVNode("div", _hoisted_59, [
                            _createElementVNode("div", _hoisted_60, _toDisplayString(_ctx.translate("min-size")), 1),
                            _createElementVNode("div", _hoisted_61, _toDisplayString(_ctx.getDimensions(_ctx.model?.cargoSecuringMaterial?.antiSlipMats?.minimumLengthSize, _ctx.model?.cargoSecuringMaterial?.antiSlipMats?.minimumWidthSize)), 1)
                        ]),
                        _createElementVNode("div", _hoisted_62, [
                            _createElementVNode("div", _hoisted_63, _toDisplayString(_ctx.translate("alt-size")), 1),
                            _createElementVNode("div", _hoisted_64, _toDisplayString(_ctx.getDimensions(_ctx.model?.cargoSecuringMaterial?.antiSlipMats?.alternativeLengthSize, _ctx.model?.cargoSecuringMaterial?.antiSlipMats?.alternativeWidthSize)), 1)
                        ]),
                        _createElementVNode("div", _hoisted_65, [
                            _createElementVNode("div", _hoisted_66, _toDisplayString(_ctx.translate("min-thickness")), 1),
                            _createElementVNode("div", _hoisted_67, _toDisplayString(_ctx.getNumericValueWithUnit(_ctx.model?.cargoSecuringMaterial?.antiSlipMats?.minimumThickness, "global.measurement.millimeter")), 1)
                        ]),
                        _createElementVNode("div", _hoisted_68, [
                            _createElementVNode("div", _hoisted_69, _toDisplayString(_ctx.translate("friction-coeficient")), 1),
                            _createElementVNode("div", _hoisted_70, _toDisplayString(_ctx.getNumericValueWithUnit(_ctx.model?.cargoSecuringMaterial?.antiSlipMats?.frictionCoefcient, "global.measurement.micro")), 1)
                        ])
                    ], 64))
                    : _createCommentVNode("", true),
                _createElementVNode("div", _hoisted_71, [
                    _createElementVNode("div", _hoisted_72, _toDisplayString(_ctx.translate("lashing")), 1)
                ]),
                _createElementVNode("div", _hoisted_73, [
                    _createElementVNode("div", _hoisted_74, _toDisplayString(_ctx.translate("lashing-to-be-provided-by")), 1),
                    _createElementVNode("div", _hoisted_75, _toDisplayString(_ctx.getDictionaryValue(_ctx.model?.cargoSecuringMaterial?.lashing?.providedBy, "LoadSecuringMaterialProvidedBy")), 1)
                ]),
                _createElementVNode("div", _hoisted_76, [
                    _createElementVNode("div", _hoisted_77, [
                        _createTextVNode(_toDisplayString(_ctx.translate("din-compliance")) + " ", 1),
                        _createVNode(_component_GlobeIcon, { size: "md" })
                    ]),
                    _createElementVNode("div", _hoisted_78, [
                        _createElementVNode("div", null, _toDisplayString(_ctx.getLocaleValue(_ctx.model?.cargoSecuringMaterial?.lashing?.dinNorm)), 1)
                    ])
                ]),
                _createElementVNode("div", _hoisted_79, [
                    _createElementVNode("div", _hoisted_80, _toDisplayString(_ctx.translate("carrier-ensures-number-of-lashing")), 1),
                    _createElementVNode("div", _hoisted_81, _toDisplayString(_ctx.getDictionaryValue(_ctx.model?.cargoSecuringMaterial?.lashing?.carrierToEnsureSufficientNumberOfLashingsToSecureCargo, "CarrierDecidesAboutNumberOfToSecuringCargo")), 1)
                ]),
                (_ctx.isCompanyGuideline(_ctx.model?.cargoSecuringMaterial?.lashing?.carrierToEnsureSufficientNumberOfLashingsToSecureCargo))
                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                        _createElementVNode("div", _hoisted_82, [
                            _createElementVNode("div", _hoisted_83, _toDisplayString(_ctx.translate("min-number-per-pallet")), 1),
                            _createElementVNode("div", _hoisted_84, _toDisplayString(_ctx.getNumericValue(_ctx.model?.cargoSecuringMaterial?.lashing?.minimumNumberPerPallet)), 1)
                        ]),
                        _createElementVNode("div", _hoisted_85, [
                            _createElementVNode("div", _hoisted_86, _toDisplayString(_ctx.translate("min-number-per-truck")), 1),
                            _createElementVNode("div", _hoisted_87, _toDisplayString(_ctx.getNumericValue(_ctx.model?.cargoSecuringMaterial?.lashing?.minimumNumberPerTruck)), 1)
                        ]),
                        _createElementVNode("div", _hoisted_88, [
                            _createElementVNode("div", _hoisted_89, _toDisplayString(_ctx.translate("min-length")), 1),
                            _createElementVNode("div", _hoisted_90, _toDisplayString(_ctx.getNumericValueWithUnit(_ctx.model?.cargoSecuringMaterial?.lashing?.minimumLength, "global.measurement.meter")), 1)
                        ]),
                        _createElementVNode("div", _hoisted_91, [
                            _createElementVNode("div", _hoisted_92, _toDisplayString(_ctx.translate("min-lashing-capacity")), 1),
                            _createElementVNode("div", _hoisted_93, _toDisplayString(_ctx.getNumericValueWithUnit(_ctx.model?.cargoSecuringMaterial?.lashing?.minimumLashingCapacity, "global.measurement.decanewton")), 1)
                        ]),
                        _createElementVNode("div", _hoisted_94, [
                            _createElementVNode("div", _hoisted_95, _toDisplayString(_ctx.translate("min-tension-force")), 1),
                            _createElementVNode("div", _hoisted_96, _toDisplayString(_ctx.getNumericValueWithUnit(_ctx.model?.cargoSecuringMaterial?.lashing?.minimumTensionForceSTF, "global.measurement.decanewton")), 1)
                        ])
                    ], 64))
                    : _createCommentVNode("", true),
                _createElementVNode("div", _hoisted_97, [
                    _createElementVNode("div", _hoisted_98, _toDisplayString(_ctx.translate("disposal-of-cargo-securing-material")), 1)
                ]),
                _createElementVNode("div", _hoisted_99, [
                    _createElementVNode("div", _hoisted_100, _toDisplayString(_ctx.translate("carrier-ensures-disposal-instructions")), 1),
                    _createElementVNode("div", _hoisted_101, _toDisplayString(_ctx.getDictionaryValue(_ctx.model?.cargoSecuringMaterial?.disposalOfCargoSecuringMaterial
                        ?.instructionsToDisposeTheSecuringMaterialAfterUnloading, "DisposalOfSecuringMaterial")), 1)
                ]),
                _createElementVNode("div", _hoisted_102, [
                    _createElementVNode("div", _hoisted_103, [
                        _createTextVNode(_toDisplayString(_ctx.translate("additional-information")) + " ", 1),
                        _createVNode(_component_GlobeIcon, { size: "md" })
                    ]),
                    _createElementVNode("div", _hoisted_104, [
                        _createElementVNode("div", null, _toDisplayString(_ctx.getLocaleValue(_ctx.model?.cargoSecuringMaterial?.disposalOfCargoSecuringMaterial?.additionalInformation)), 1)
                    ])
                ])
            ]))
    ]));
}
