import { domainTokensFactory } from "@/app/modular-content/factories/domain-tokens.factory";
import { RestClientModule } from "@/app/http/modules/rest-client.module";
import _ from "lodash";
export const DomainRestApiModule = (options) => {
    const tokens = domainTokensFactory(options.id);
    const baseUrlCallback = _.isFunction(options.baseUrl)
        ? options.baseUrl
        : (() => options.baseUrl);
    return RestClientModule({ token: tokens.restApiClient, baseUrlCallback });
};
