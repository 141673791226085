import { suffixComponentName } from "@/app/modular-content/functions/suffix-component-name";
import { SubModuleModule } from "@/app/modular-content/modules/sub-module.module";
import { TranslationPrefixToken } from "@/app/translation/tokens/translation-prefix.token";
import { createModule } from "@/di/module";
import EditTrafficRelatedRulesComponent from "@/app/sub-modules/traffic-related-rules/components/edit-traffic-related-rules.component.vue";
import DisplayTrafficRelatedRulesComponent from "@/app/sub-modules/traffic-related-rules/components/display-traffic-related-rules.component.vue";
import { SafetyAndSecurityModuleId } from "@/app/modules/safety-and-security/safety-and-security.module";
export const TrafficRelatedRulesSubModule = (domainId) => {
    const id = "trafficRules";
    const moduleId = SafetyAndSecurityModuleId;
    const componentSuffixes = [domainId, moduleId, id];
    const editComponent = suffixComponentName(EditTrafficRelatedRulesComponent, ...componentSuffixes);
    const displayComponent = suffixComponentName(DisplayTrafficRelatedRulesComponent, ...componentSuffixes);
    return createModule({
        components: [editComponent, displayComponent],
        imports: [
            SubModuleModule({
                id,
                moduleId,
                domainId,
                icon: "traffic",
                dataLocalised: true,
                components: {
                    edit: editComponent,
                    display: displayComponent,
                },
            }),
        ],
        providers: [
            {
                token: TranslationPrefixToken,
                useValue: "sub-modules.traffic-rules",
            },
        ],
    });
};
