import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-59b8c8b6"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "cargo-securing-material-input" };
const _hoisted_2 = { class: "title" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_CertifiedCargoSecuringSystem = _resolveComponent("CertifiedCargoSecuringSystem");
    const _component_SlatBoards = _resolveComponent("SlatBoards");
    const _component_AntiSlipMats = _resolveComponent("AntiSlipMats");
    const _component_Lashing = _resolveComponent("Lashing");
    const _component_DisposalOfCargoSecuringMaterial = _resolveComponent("DisposalOfCargoSecuringMaterial");
    const _component_AppForm = _resolveComponent("AppForm");
    return (_openBlock(), _createBlock(_component_AppForm, {
        modelValue: _ctx.model,
        "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event) => ((_ctx.model) = $event)),
        initial: _ctx.initial
    }, {
        default: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
                _createElementVNode("h5", _hoisted_2, _toDisplayString(_ctx.translate("cargo-securing-material")), 1),
                _createVNode(_component_CertifiedCargoSecuringSystem, {
                    initial: _ctx.initial?.certifiedCargoSecuringSystem,
                    modelValue: _ctx.model.certifiedCargoSecuringSystem,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => ((_ctx.model.certifiedCargoSecuringSystem) = $event)),
                    loadSecuringMaterialProvidedBy: _ctx.loadSecuringMaterialProvidedBy,
                    certifiedCargoSecuringSystemRequired: _ctx.certifiedCargoSecuringSystemRequired,
                    systemSpecification: _ctx.systemSpecification
                }, null, 8, ["initial", "modelValue", "loadSecuringMaterialProvidedBy", "certifiedCargoSecuringSystemRequired", "systemSpecification"]),
                _createVNode(_component_SlatBoards, {
                    initial: _ctx.initial?.slatBoards,
                    modelValue: _ctx.model.slatBoards,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event) => ((_ctx.model.slatBoards) = $event)),
                    loadSecuringMaterialProvidedBy: _ctx.loadSecuringMaterialProvidedBy
                }, null, 8, ["initial", "modelValue", "loadSecuringMaterialProvidedBy"]),
                _createVNode(_component_AntiSlipMats, {
                    initial: _ctx.initial?.antiSlipMats,
                    modelValue: _ctx.model.antiSlipMats,
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event) => ((_ctx.model.antiSlipMats) = $event)),
                    loadSecuringMaterialProvidedBy: _ctx.loadSecuringMaterialProvidedBy,
                    carrierDecidesAboutNumberOfToSecuringCargo: _ctx.carrierDecidesAboutNumberOfToSecuringCargo,
                    onClear: _cache[3] || (_cache[3] = ($event) => (_ctx.$emit('clearAntiSlip')))
                }, null, 8, ["initial", "modelValue", "loadSecuringMaterialProvidedBy", "carrierDecidesAboutNumberOfToSecuringCargo"]),
                _createVNode(_component_Lashing, {
                    initial: _ctx.initial?.lashing,
                    modelValue: _ctx.model.lashing,
                    "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event) => ((_ctx.model.lashing) = $event)),
                    loadSecuringMaterialProvidedBy: _ctx.loadSecuringMaterialProvidedBy,
                    carrierDecidesAboutNumberOfToSecuringCargo: _ctx.carrierDecidesAboutNumberOfToSecuringCargo,
                    onClear: _cache[5] || (_cache[5] = ($event) => (_ctx.$emit('clearLashing')))
                }, null, 8, ["initial", "modelValue", "loadSecuringMaterialProvidedBy", "carrierDecidesAboutNumberOfToSecuringCargo"]),
                _createVNode(_component_DisposalOfCargoSecuringMaterial, {
                    initial: _ctx.initial?.disposalOfCargoSecuringMaterial,
                    modelValue: _ctx.model.disposalOfCargoSecuringMaterial,
                    "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event) => ((_ctx.model.disposalOfCargoSecuringMaterial) = $event)),
                    disposalOfSecuringMaterial: _ctx.disposalOfSecuringMaterial
                }, null, 8, ["initial", "modelValue", "disposalOfSecuringMaterial"])
            ])
        ]),
        _: 1
    }, 8, ["modelValue", "initial"]));
}
