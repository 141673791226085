import { createModule } from "@/di/module";
import { AppInitServiceToken } from "@/app/shared/tokens/app-init-service.token";
import { DataLocalisationService } from "./services/data-localisation-service.service";
export const DataLocalisationModule = createModule({
    providers: [
        DataLocalisationService,
        {
            token: AppInitServiceToken,
            useToken: DataLocalisationService,
        },
    ],
});
