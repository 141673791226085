import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = { class: "edit-vehicle-and-ppe" };
const _hoisted_2 = { key: 1 };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_LoadingSpinner = _resolveComponent("LoadingSpinner");
    const _component_EditVehicleAndPPESiteVew = _resolveComponent("EditVehicleAndPPESiteVew");
    const _component_EditVehicleAndPPEUlpVew = _resolveComponent("EditVehicleAndPPEUlpVew");
    const _component_EditVehicleAndPPEApVew = _resolveComponent("EditVehicleAndPPEApVew");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_ctx.isLoading)
            ? (_openBlock(), _createBlock(_component_LoadingSpinner, {
                key: 0,
                center: ""
            }))
            : (_openBlock(), _createElementBlock("div", _hoisted_2, [
                (_ctx.isSitesDomain)
                    ? (_openBlock(), _createBlock(_component_EditVehicleAndPPESiteVew, {
                        key: 0,
                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => (_ctx.updateModel($event))),
                        initial: _ctx.initial,
                        dictionary: _ctx.ppeRequirementDictionary
                    }, null, 8, ["initial", "dictionary"]))
                    : _createCommentVNode("", true),
                (_ctx.isUlpDomain)
                    ? (_openBlock(), _createBlock(_component_EditVehicleAndPPEUlpVew, {
                        key: 1,
                        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event) => (_ctx.updateModel($event))),
                        initial: _ctx.initial,
                        dictionary: _ctx.ppeRequirementDictionary
                    }, null, 8, ["initial", "dictionary"]))
                    : _createCommentVNode("", true),
                (_ctx.isApDomain)
                    ? (_openBlock(), _createBlock(_component_EditVehicleAndPPEApVew, {
                        key: 2,
                        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event) => (_ctx.updateModel($event))),
                        initial: _ctx.initial,
                        dictionary: _ctx.ppeRequirementDictionary
                    }, null, 8, ["initial", "dictionary"]))
                    : _createCommentVNode("", true)
            ]))
    ]));
}
