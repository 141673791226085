import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-103ddd28"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "disposal-of-cargo-securing-material-input element" };
const _hoisted_2 = { class: "mt-4" };
const _hoisted_3 = { class: "sub-element" };
const _hoisted_4 = { class: "globe-icon-wrapper-sm" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_InputLabel = _resolveComponent("InputLabel");
    const _component_RadioButton = _resolveComponent("RadioButton");
    const _component_FormField = _resolveComponent("FormField");
    const _component_GlobeIcon = _resolveComponent("GlobeIcon");
    const _component_LocalisedInput = _resolveComponent("LocalisedInput");
    const _component_AppForm = _resolveComponent("AppForm");
    return (_openBlock(), _createBlock(_component_AppForm, {
        modelValue: _ctx.model,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event) => ((_ctx.model) = $event)),
        initial: _ctx.initial
    }, {
        default: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
                _createElementVNode("h6", _hoisted_2, _toDisplayString(_ctx.translate("disposal-of-cargo-securing-material")), 1),
                _createVNode(_component_InputLabel, null, {
                    default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.translate("carrier-ensures-disposal-instructions")), 1)
                    ]),
                    _: 1
                }),
                _createVNode(_component_FormField, null, {
                    default: _withCtx(() => [
                        _createVNode(_component_RadioButton, {
                            modelValue: _ctx.model.instructionsToDisposeTheSecuringMaterialAfterUnloading,
                            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => ((_ctx.model.instructionsToDisposeTheSecuringMaterialAfterUnloading) = $event)),
                            dictionary: _ctx.disposalOfSecuringMaterial,
                            dictionaryId: "DisposalOfSecuringMaterial"
                        }, null, 8, ["modelValue", "dictionary"])
                    ]),
                    _: 1
                }),
                _createElementVNode("div", _hoisted_3, [
                    _createElementVNode("div", _hoisted_4, [
                        _createVNode(_component_InputLabel, null, {
                            default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.translate("additional-info")), 1)
                            ]),
                            _: 1
                        }),
                        _createVNode(_component_GlobeIcon, { size: "sm" })
                    ]),
                    _createVNode(_component_FormField, { class: "col-7" }, {
                        default: _withCtx(() => [
                            _createVNode(_component_LocalisedInput, {
                                modelValue: _ctx.model.additionalInformation,
                                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event) => ((_ctx.model.additionalInformation) = $event)),
                                placeholder: _ctx.translate('enter-additional-info')
                            }, null, 8, ["modelValue", "placeholder"])
                        ]),
                        _: 1
                    })
                ])
            ])
        ]),
        _: 1
    }, 8, ["modelValue", "initial"]));
}
