import { createModule } from "@/di/module";
import { LocalStorageService } from "@/app/shared/services/local-storage.service";
import { ToastService } from "@/app/shared/services/toast.service";
import { RouterService } from "@/app/shared/services/router.service";
import { RouterConfigurationService } from "@/app/shared/services/router-configuration.service";
import { UserStorageService } from "@/app/shared/services/user-storage.service";
import { SessionStorageService } from "@/app/shared/services/session-storage.service";
import { InjectorService } from "@/app/shared/services/injector.service";
import TitleButtonComponent from "@/app/shared/components/title-button.component.vue";
import PanelTopNavigationComponent from "@/app/shared/components/panel-top-navigation.component.vue";
import IdComponent from "@/app/shared/components/id.component.vue";
import TextButtonComponent from "@/app/shared/components/text-button.component.vue";
import ButtonGroupComponent from "@/app/shared/components/button-group.component.vue";
import TableComponent from "@/app/shared/components/table.component.vue";
import UserInitialsComponent from "@/app/shared/components/user-initials.component.vue";
import IconComponent from "@/app/shared/components/icon.component.vue";
import ButtonComponent from "@/app/shared/components/button.component.vue";
import CollapsiblePanelComponent from "@/app/shared/components/collapsible-panel.component.vue";
import InputLabelComponent from "@/app/shared/components/input-label.component.vue";
import EmptyComponent from "@/app/shared/components/empty.component.vue";
import ErrorMessageComponent from "@/app/shared/components/error-message.component.vue";
import PanelComponent from "@/app/shared/components/panel.component.vue";
import PageHeaderComponent from "@/app/shared/components/page-header.component.vue";
import ThumbnailComponent from "@/app/shared/components/thumbnail.component.vue";
import InlineSvgComponent from "@/app/shared/components/inline-svg.component.vue";
import LoadingSpinnerComponent from "@/app/shared/components/loading-spinner.component.vue";
import ModalComponent from "@/app/modals/components/modal.component.vue";
import BadgeComponent from "@/app/shared/components/badge/badge.component.vue";
import { TemporaryDataService } from "@/app/shared/services/temporary-data.service";
import { ProcessingItemsService } from "@/app/shared/services/processing-items.service";
import { ModalModule } from "@/app/modals/modules/modal.module";
import { SubModuleChangeHistoryModalId, TimeRangeModalId } from "@/app/shared/shared.constants";
import TimeRangeModalComponent from "@/app/shared/components/time-range-modal.component.vue";
import SubModuleChangeHistoryModalComponent from "@/app/shared/components/sub-module-change-history-modal.component.vue";
export const SharedModule = createModule({
    components: [
        ButtonComponent,
        ButtonGroupComponent,
        BadgeComponent,
        CollapsiblePanelComponent,
        EmptyComponent,
        ErrorMessageComponent,
        IconComponent,
        IdComponent,
        InlineSvgComponent,
        InputLabelComponent,
        LoadingSpinnerComponent,
        ModalComponent,
        PageHeaderComponent,
        PanelComponent,
        PanelTopNavigationComponent,
        TableComponent,
        TextButtonComponent,
        ThumbnailComponent,
        TitleButtonComponent,
        UserInitialsComponent,
    ],
    imports: [
        ModalModule(SubModuleChangeHistoryModalId, SubModuleChangeHistoryModalComponent),
        ModalModule(TimeRangeModalId, TimeRangeModalComponent),
    ],
    providers: [
        LocalStorageService,
        SessionStorageService,
        UserStorageService,
        ToastService,
        RouterConfigurationService,
        RouterService,
        InjectorService,
        TemporaryDataService,
        ProcessingItemsService,
    ],
});
