import { createModule } from "@/di/module";
import { TranslationPrefixToken } from "@/app/translation/tokens/translation-prefix.token";
import { CleaningRequirementsModuleId } from "@/app/modules/cleaning-requirements/cleaning-requirements.module";
import { suffixComponentName } from "@/app/modular-content/functions/suffix-component-name";
import EditEuropeanCleaningDocumentComponent from "@/app/sub-modules/european-cleaning-document/components/edit-european-cleaning-document.component.vue";
import DisplayEuropeanCleaningDocumentComponent from "@/app/sub-modules/european-cleaning-document/components/display-european-cleaning-document.component.vue";
import DisplayEuropeanCleaningDocumentViewComponent from "@/app/sub-modules/european-cleaning-document/components/display-european-cleaning-document-view.component.vue";
import { SubModuleModule } from "@/app/modular-content/modules/sub-module.module";
export const EuropeanCleaningDocumentSubModule = (domainId) => {
    const id = "europeanCleaningDocument";
    const moduleId = CleaningRequirementsModuleId;
    const componentSuffixes = [domainId, moduleId, id];
    const editComponent = suffixComponentName(EditEuropeanCleaningDocumentComponent, ...componentSuffixes);
    const displayComponent = suffixComponentName(DisplayEuropeanCleaningDocumentComponent, ...componentSuffixes);
    const displayViewComponent = suffixComponentName(DisplayEuropeanCleaningDocumentViewComponent, ...componentSuffixes);
    return createModule({
        components: [editComponent, displayComponent],
        imports: [
            SubModuleModule({
                id,
                moduleId,
                domainId,
                icon: "document_scanner",
                dataLocalised: true,
                components: {
                    edit: editComponent,
                    display: displayComponent,
                    displayView: displayViewComponent,
                },
            }),
        ],
        providers: [
            {
                token: TranslationPrefixToken,
                useValue: "sub-modules.european-cleaning-document",
            },
        ],
    });
};
