import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-21873706"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "display-connections" };
const _hoisted_2 = {
    key: 0,
    class: "column-description mb-0"
};
const _hoisted_3 = { key: 1 };
const _hoisted_4 = { class: "row" };
const _hoisted_5 = { class: "col-12 column-header" };
const _hoisted_6 = { class: "col-12" };
const _hoisted_7 = { class: "row" };
const _hoisted_8 = { class: "col-6 column-header" };
const _hoisted_9 = { class: "col-6 column-description" };
const _hoisted_10 = { key: 0 };
const _hoisted_11 = { key: 1 };
const _hoisted_12 = { class: "row" };
const _hoisted_13 = { class: "col-6 column-header" };
const _hoisted_14 = { class: "col-6 column-description" };
const _hoisted_15 = { key: 0 };
const _hoisted_16 = { key: 1 };
const _hoisted_17 = { class: "row" };
const _hoisted_18 = { class: "col-6 column-header" };
const _hoisted_19 = { class: "col-6 column-description" };
const _hoisted_20 = { class: "row" };
const _hoisted_21 = { class: "col-6 column-header" };
const _hoisted_22 = { class: "col-6 column-description" };
const _hoisted_23 = { key: 0 };
const _hoisted_24 = { key: 1 };
const _hoisted_25 = { class: "row" };
const _hoisted_26 = { class: "col-6 column-header" };
const _hoisted_27 = {
    key: 0,
    class: "col-6 tags"
};
const _hoisted_28 = {
    key: 1,
    class: "col-6 column-description"
};
const _hoisted_29 = { class: "row" };
const _hoisted_30 = { class: "col-6 column-header" };
const _hoisted_31 = { class: "col-6 column-description" };
const _hoisted_32 = { class: "row" };
const _hoisted_33 = { class: "col-6 column-header" };
const _hoisted_34 = { class: "col-6 column-description" };
const _hoisted_35 = { class: "row" };
const _hoisted_36 = { class: "col-6 column-header globe-icon-wrapper" };
const _hoisted_37 = { class: "col-6 column-description" };
const _hoisted_38 = { class: "row" };
const _hoisted_39 = { class: "col-6 column-header" };
const _hoisted_40 = { class: "col-6 column-description" };
const _hoisted_41 = { key: 0 };
const _hoisted_42 = { key: 1 };
const _hoisted_43 = { class: "row" };
const _hoisted_44 = { class: "col-6 column-header" };
const _hoisted_45 = { class: "col-6 column-description" };
const _hoisted_46 = { class: "row" };
const _hoisted_47 = { class: "col-6 column-header" };
const _hoisted_48 = { class: "col-6 column-description" };
const _hoisted_49 = { class: "row" };
const _hoisted_50 = { class: "col-6 column-header" };
const _hoisted_51 = { class: "col-6 column-description" };
const _hoisted_52 = { class: "row" };
const _hoisted_53 = { class: "col-6 column-header globe-icon-wrapper" };
const _hoisted_54 = { class: "col-6 column-description" };
const _hoisted_55 = { class: "row" };
const _hoisted_56 = { class: "col-6 column-header" };
const _hoisted_57 = { class: "col-6 column-description" };
const _hoisted_58 = { key: 0 };
const _hoisted_59 = { key: 1 };
const _hoisted_60 = { class: "row" };
const _hoisted_61 = { class: "col-6 column-header" };
const _hoisted_62 = { class: "col-6 column-description" };
const _hoisted_63 = { class: "row" };
const _hoisted_64 = { class: "col-6 column-header globe-icon-wrapper" };
const _hoisted_65 = { class: "col-6 column-description" };
const _hoisted_66 = { class: "row" };
const _hoisted_67 = { class: "col-6 column-header" };
const _hoisted_68 = { class: "col-6 column-description" };
const _hoisted_69 = { key: 0 };
const _hoisted_70 = { key: 1 };
const _hoisted_71 = { class: "row" };
const _hoisted_72 = { class: "col-6 column-header globe-icon-wrapper" };
const _hoisted_73 = { class: "col-6 column-description" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_TruckWidget = _resolveComponent("TruckWidget");
    const _component_GlobeIcon = _resolveComponent("GlobeIcon");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_ctx.model.noSpecialRequirements)
            ? (_openBlock(), _createElementBlock("p", _hoisted_2, _toDisplayString(_ctx.translate("phrases.no-special-requirements")), 1))
            : (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createElementVNode("div", _hoisted_4, [
                    _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.translate("truck-connections-title")), 1),
                    _createElementVNode("div", _hoisted_6, [
                        _createVNode(_component_TruckWidget, {
                            "truck-connections": _ctx.model.truckConnections,
                            "display-view": true
                        }, null, 8, ["truck-connections"])
                    ])
                ]),
                _createElementVNode("div", _hoisted_7, [
                    _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.translate("clearly-labelled-connections-title")), 1),
                    _createElementVNode("div", _hoisted_9, [
                        (_ctx.model.clearlyLabelledConnections !== null)
                            ? (_openBlock(), _createElementBlock("span", _hoisted_10, _toDisplayString(_ctx.model.clearlyLabelledConnections ? _ctx.translate("phrases.required") : _ctx.translate("phrases.not-required")), 1))
                            : (_openBlock(), _createElementBlock("span", _hoisted_11, "-"))
                    ])
                ]),
                _createElementVNode("div", _hoisted_12, [
                    _createElementVNode("div", _hoisted_13, _toDisplayString(_ctx.translate("point-locked-connection-title")), 1),
                    _createElementVNode("div", _hoisted_14, [
                        (_ctx.model.pointLockedConnection !== null)
                            ? (_openBlock(), _createElementBlock("span", _hoisted_15, _toDisplayString(_ctx.model.pointLockedConnection ? _ctx.translate("phrases.required") : _ctx.translate("phrases.not-required")), 1))
                            : (_openBlock(), _createElementBlock("span", _hoisted_16, "-"))
                    ])
                ]),
                _createElementVNode("div", _hoisted_17, [
                    _createElementVNode("div", _hoisted_18, _toDisplayString(_ctx.translate("connection-sealing-is-done-by-whom-title")), 1),
                    _createElementVNode("div", _hoisted_19, _toDisplayString(_ctx.getValue(_ctx.model.connectionSealingIsDoneByWhom, "ConnectionSealingIsDoneByWhom")), 1)
                ]),
                _createElementVNode("div", _hoisted_20, [
                    _createElementVNode("div", _hoisted_21, _toDisplayString(_ctx.translate("coupling-title")), 1),
                    _createElementVNode("div", _hoisted_22, [
                        (_ctx.model.coupling !== null)
                            ? (_openBlock(), _createElementBlock("span", _hoisted_23, _toDisplayString(_ctx.model.coupling ? _ctx.translate("phrases.required") : _ctx.translate("phrases.not-required")), 1))
                            : (_openBlock(), _createElementBlock("span", _hoisted_24, "-"))
                    ])
                ]),
                _createElementVNode("div", _hoisted_25, [
                    _createElementVNode("div", _hoisted_26, _toDisplayString(_ctx.translate("coupling-type-label")), 1),
                    (_ctx.model?.couplingTypes?.length)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_27, [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.model.couplingTypes, (tag, index) => {
                                return (_openBlock(), _createElementBlock("span", {
                                    key: tag + index,
                                    class: "tag"
                                }, _toDisplayString(_ctx.getValue(tag, "CouplingType")), 1));
                            }), 128))
                        ]))
                        : (_openBlock(), _createElementBlock("div", _hoisted_28, "-"))
                ]),
                _createElementVNode("div", _hoisted_29, [
                    _createElementVNode("div", _hoisted_30, _toDisplayString(_ctx.translate("coupling-size-label")), 1),
                    _createElementVNode("div", _hoisted_31, _toDisplayString(_ctx.getValue(_ctx.model.couplingSize, "CouplingSize")), 1)
                ]),
                _createElementVNode("div", _hoisted_32, [
                    _createElementVNode("div", _hoisted_33, _toDisplayString(_ctx.translate("configuration")), 1),
                    _createElementVNode("div", _hoisted_34, _toDisplayString(_ctx.getValue(_ctx.model.configuration, "ConnectionConfiguration")), 1)
                ]),
                _createElementVNode("div", _hoisted_35, [
                    _createElementVNode("div", _hoisted_36, [
                        _createTextVNode(_toDisplayString(_ctx.translate("phrases.additional-information")) + " ", 1),
                        _createVNode(_component_GlobeIcon, { size: "md" })
                    ]),
                    _createElementVNode("div", _hoisted_37, _toDisplayString(_ctx.filterByLocale(_ctx.model.couplingAdditionalInformation)), 1)
                ]),
                _createElementVNode("div", _hoisted_38, [
                    _createElementVNode("div", _hoisted_39, _toDisplayString(_ctx.translate("flange-title")), 1),
                    _createElementVNode("div", _hoisted_40, [
                        (_ctx.model.flange !== null)
                            ? (_openBlock(), _createElementBlock("span", _hoisted_41, _toDisplayString(_ctx.model.flange ? _ctx.translate("phrases.required") : _ctx.translate("phrases.not-required")), 1))
                            : (_openBlock(), _createElementBlock("span", _hoisted_42, "-"))
                    ])
                ]),
                _createElementVNode("div", _hoisted_43, [
                    _createElementVNode("div", _hoisted_44, _toDisplayString(_ctx.translate("flange-type-label")), 1),
                    _createElementVNode("div", _hoisted_45, _toDisplayString(_ctx.getValue(_ctx.model.flangeType, "FlangeType")), 1)
                ]),
                _createElementVNode("div", _hoisted_46, [
                    _createElementVNode("div", _hoisted_47, _toDisplayString(_ctx.translate("flange-size-label")), 1),
                    _createElementVNode("div", _hoisted_48, _toDisplayString(_ctx.getValue(_ctx.model.flangeSize, "FlangeSize")), 1)
                ]),
                _createElementVNode("div", _hoisted_49, [
                    _createElementVNode("div", _hoisted_50, _toDisplayString(_ctx.translate("minimum-stud-bolt-length-label")), 1),
                    _createElementVNode("div", _hoisted_51, _toDisplayString(_ctx.model.minimumStudBoltLength || "-"), 1)
                ]),
                _createElementVNode("div", _hoisted_52, [
                    _createElementVNode("div", _hoisted_53, [
                        _createTextVNode(_toDisplayString(_ctx.translate("phrases.additional-information")) + " ", 1),
                        _createVNode(_component_GlobeIcon, { size: "md" })
                    ]),
                    _createElementVNode("div", _hoisted_54, _toDisplayString(_ctx.filterByLocale(_ctx.model.flangeAdditionalInformation)), 1)
                ]),
                _createElementVNode("div", _hoisted_55, [
                    _createElementVNode("div", _hoisted_56, _toDisplayString(_ctx.translate("heating-system-connection-title")), 1),
                    _createElementVNode("div", _hoisted_57, [
                        (_ctx.model.heating !== null)
                            ? (_openBlock(), _createElementBlock("span", _hoisted_58, _toDisplayString(_ctx.model.heating ? _ctx.translate("phrases.required") : _ctx.translate("phrases.not-required")), 1))
                            : (_openBlock(), _createElementBlock("span", _hoisted_59, "-"))
                    ])
                ]),
                _createElementVNode("div", _hoisted_60, [
                    _createElementVNode("div", _hoisted_61, _toDisplayString(_ctx.translate("heating-system-connection-type-label")), 1),
                    _createElementVNode("div", _hoisted_62, _toDisplayString(_ctx.getValue(_ctx.model.heatingType, "ConnectionHeatingType")), 1)
                ]),
                _createElementVNode("div", _hoisted_63, [
                    _createElementVNode("div", _hoisted_64, [
                        _createTextVNode(_toDisplayString(_ctx.translate("phrases.additional-information")) + " ", 1),
                        _createVNode(_component_GlobeIcon, { size: "md" })
                    ]),
                    _createElementVNode("div", _hoisted_65, _toDisplayString(_ctx.filterByLocale(_ctx.model.heatingAdditionalInformation)), 1)
                ]),
                _createElementVNode("div", _hoisted_66, [
                    _createElementVNode("div", _hoisted_67, _toDisplayString(_ctx.translate("adaptors-title")), 1),
                    _createElementVNode("div", _hoisted_68, [
                        (_ctx.model.adaptors !== null)
                            ? (_openBlock(), _createElementBlock("span", _hoisted_69, _toDisplayString(_ctx.model.adaptors ? _ctx.translate("phrases.required") : _ctx.translate("phrases.not-required")), 1))
                            : (_openBlock(), _createElementBlock("span", _hoisted_70, "-"))
                    ])
                ]),
                _createElementVNode("div", _hoisted_71, [
                    _createElementVNode("div", _hoisted_72, [
                        _createTextVNode(_toDisplayString(_ctx.translate("phrases.additional-information")) + " ", 1),
                        _createVNode(_component_GlobeIcon, { size: "md" })
                    ]),
                    _createElementVNode("div", _hoisted_73, _toDisplayString(_ctx.filterByLocale(_ctx.model.adaptorsAdditionalInformation)), 1)
                ])
            ]))
    ]));
}
