import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-7f4e1a60"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "col-name" };
const _hoisted_2 = { class: "name" };
const _hoisted_3 = { class: "label text-wrap" };
const _hoisted_4 = { class: "d-flex flex-column" };
const _hoisted_5 = { key: 0 };
const _hoisted_6 = { key: 1 };
const _hoisted_7 = { class: "d-flex flex-column" };
const _hoisted_8 = { key: 0 };
const _hoisted_9 = { key: 1 };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_Thumbnail = _resolveComponent("Thumbnail");
    const _component_Id = _resolveComponent("Id");
    const _component_Column = _resolveComponent("Column");
    const _component_DataTable = _resolveComponent("DataTable");
    const _component_AppTable = _resolveComponent("AppTable");
    return (_openBlock(), _createBlock(_component_AppTable, {
        delegate: _ctx.delegate,
        onReload: _ctx.loadMethod,
        isLoading: _ctx.isLoading,
        isError: _ctx.isError,
        error: _ctx.errorResponse,
        contextTranslate: _ctx.translate,
        class: "company-groups-list"
    }, {
        table: _withCtx((slotProps) => [
            _createVNode(_component_DataTable, {
                value: slotProps.data.results,
                columnsToggleDefault: _ctx.columnsToggleDefault,
                totalRecords: slotProps.data.count,
                tableFilters: _ctx.filters,
                filters: _ctx.filters,
                "onUpdate:filters": [
                    _cache[0] || (_cache[0] = ($event) => ((_ctx.filters) = $event)),
                    _ctx.onUpdateFilters
                ],
                visibleColumns: _ctx.visibleColumns,
                "onUpdate:visibleColumns": [
                    _cache[1] || (_cache[1] = ($event) => ((_ctx.visibleColumns) = $event)),
                    _ctx.delegate.setColumns
                ],
                lazy: true,
                rowHover: false,
                loading: _ctx.isLoading,
                onPage: _cache[2] || (_cache[2] = ($event) => (_ctx.onPage($event))),
                first: _ctx.lazyParams.first,
                ref: "companyGroupsList"
            }, {
                default: _withCtx(() => [
                    _createVNode(_component_Column, {
                        field: "companyGroupName",
                        header: _ctx.translate('columns.company-group-name'),
                        style: { "min-width": "16rem" }
                    }, {
                        body: _withCtx(({ data }) => [
                            _createElementVNode("div", _hoisted_1, [
                                _createVNode(_component_Thumbnail, {
                                    src: data.logoPath,
                                    icon: "location_city",
                                    size: "lg"
                                }, null, 8, ["src"]),
                                _createElementVNode("div", _hoisted_2, [
                                    _createElementVNode("div", _hoisted_3, _toDisplayString(data.name), 1),
                                    _createVNode(_component_Id, { "with-prefix": false }, {
                                        default: _withCtx(() => [
                                            _createTextVNode(_toDisplayString(data.id), 1)
                                        ]),
                                        _: 2
                                    }, 1024)
                                ])
                            ])
                        ]),
                        _: 1
                    }, 8, ["header"]),
                    (_ctx.isColumnVisible('entityCreationDate'))
                        ? (_openBlock(), _createBlock(_component_Column, {
                            key: 0,
                            field: "entityCreationDate",
                            header: _ctx.getColumnHeader('entityCreationDate'),
                            dataType: "date",
                            style: { "min-width": "12rem" }
                        }, {
                            body: _withCtx(({ data }) => [
                                _createElementVNode("div", _hoisted_4, [
                                    (data.createdTime && data.createdBy)
                                        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                                            _createElementVNode("div", null, _toDisplayString(_ctx.$utils.formatDate(data.createdTime)), 1),
                                            _createElementVNode("div", null, _toDisplayString(_ctx.getByUserTranslation(data.createdBy)), 1)
                                        ]))
                                        : (_openBlock(), _createElementBlock("div", _hoisted_6, "-"))
                                ])
                            ]),
                            _: 1
                        }, 8, ["header"]))
                        : _createCommentVNode("", true),
                    (_ctx.isColumnVisible('lastUpdate'))
                        ? (_openBlock(), _createBlock(_component_Column, {
                            key: 1,
                            field: "lastUpdate",
                            header: _ctx.getColumnHeader('lastUpdate'),
                            style: { "min-width": "12rem" }
                        }, {
                            body: _withCtx(({ data }) => [
                                _createElementVNode("div", _hoisted_7, [
                                    (data.updatedTime && data.updatedBy)
                                        ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                                            _createElementVNode("div", null, _toDisplayString(_ctx.$utils.formatDate(data.updatedTime)), 1),
                                            _createElementVNode("div", null, _toDisplayString(_ctx.getByUserTranslation(data.updatedBy)), 1)
                                        ]))
                                        : (_openBlock(), _createElementBlock("div", _hoisted_9, "-"))
                                ])
                            ]),
                            _: 1
                        }, 8, ["header"]))
                        : _createCommentVNode("", true)
                ]),
                _: 2
            }, 1032, ["value", "columnsToggleDefault", "totalRecords", "tableFilters", "filters", "onUpdate:filters", "visibleColumns", "onUpdate:visibleColumns", "loading", "first"])
        ]),
        _: 1
    }, 8, ["delegate", "onReload", "isLoading", "isError", "error", "contextTranslate"]));
}
