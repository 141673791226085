import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-23b39383"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "display-temperature-date-range" };
const _hoisted_2 = { class: "temperature-info" };
const _hoisted_3 = {
    key: 0,
    class: "date-info"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.modelValue, (dateRange, index) => {
            return (_openBlock(), _createElementBlock("div", {
                key: index,
                class: "column-description"
            }, [
                _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.temperatureRangeInfo(dateRange)), 1),
                (_ctx.isDateRangeValid(index))
                    ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.parseDate(dateRange.datesRange)), 1))
                    : _createCommentVNode("", true)
            ]));
        }), 128))
    ]));
}
