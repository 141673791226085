import { __decorate } from "tslib";
import { service } from "@/di/decorators/service.decorator";
import { Subject } from "rxjs";
let ToastService = class ToastService {
    messages = new Subject();
    showToast(message) {
        this.messages.next(message);
    }
    onToastMessage() {
        return this.messages;
    }
};
ToastService = __decorate([
    service()
], ToastService);
export { ToastService };
