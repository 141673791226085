import DisplayFillingView from "@/app/sub-modules/filling/components/display-filling-view.component.vue";
import DisplayGeneralInstructionsView from "@/app/sub-modules/general-instructions/components/display-general-instructions-view.component.vue";
import DisplaySamplingView from "@/app/sub-modules/sampling/components/display-sampling-view.component.vue";
import DisplaySealingView from "@/app/sub-modules/sealing/components/display-sealing-view.vue";
import DisplayWeighingView from "@/app/sub-modules/weighing/components/display-weighing-view.component.vue";
import DisplayDocumentsView from "@/app/sub-modules/documents/components/display-documents-view.component.vue";
import DisplayPackagingView from "@/app/sub-modules/packaging/components/display-packaging-view.component.vue";
import DisplayPalletsView from "@/app/sub-modules/pallets/components/display-pallets-view.component.vue";
import DisplayVehicleAndPPESitesView from "@/app/sub-modules/vehicle-and-ppe/components/site-view/display-vehicle-and-ppe-site-view.component.vue";
import DisplayVehicleAndPPEUlpView from "@/app/sub-modules/vehicle-and-ppe/components/un-loading-points-view/display-vehicle-and-ppe-ulp-view.component.vue";
import DisplayVehicleAndPPEApView from "@/app/sub-modules/vehicle-and-ppe/components/assigned-products-view/display-vehicle-and-ppe-ap-view.component.vue";
import DisplayEquipmentPackedView from "@/app/sub-modules/equipment-packed/components/display-equipment-packed-view.vue";
import DisplayCompressorView from "@/app/sub-modules/compressor/components/display-compressor-view.component.vue";
import DisplayConnectionsView from "@/app/sub-modules/connections/components/display-connections-view.vue";
import DisplayHoseView from "@/app/sub-modules/hose/components/display-hose-view.component.vue";
import DisplayPumpView from "@/app/sub-modules/pump/components/display-pump-view.component.vue";
import DisplayTankView from "@/app/sub-modules/tank/components/display-tank-view.vue";
import DisplayTemperatureRequirementsView from "@/app/sub-modules/temperature-requirements/components/display-temperature-requirements-view.vue";
import DisplayCargoSecuringView from "@/app/sub-modules/cargo-securing/components/display-cargo-securing-view.vue";
import DisplayVapourReturnView from "@/app/sub-modules/vapour-return/components/display-vapour-return-view.component.vue";
import DisplayVehicleView from "@/app/sub-modules/vehicle/components/display-vehicle-view.component.vue";
import DisplayCheckInView from "@/app/sub-modules/check-in/components/display-check-in-view.component.vue";
import DisplayGatesView from "@/app/sub-modules/gates/components/display-gates-view.component.vue";
import DisplayAccessRestrictionsView from "@/app/sub-modules/access-restrictions/components/display-access-restrictions-view.component.vue";
import DisplaySiteAdditionalInformationView from "@/app/sub-modules/additional-information/site/components/display-site-additional-information-view.component.vue";
import DisplayPostalAddressView from "@/app/sub-modules/postal-address/components/display-postal-address-view.component.vue";
import DisplayProcessesView from "@/app/sub-modules/processes/components/display-processes-view.component.vue";
import DisplayOpeningHoursView from "@/app/sub-modules/opening-hours/components/display-opening-hours-view.component.vue";
import DisplayContactDataView from "@/app/sub-modules/contact-data/components/display-contact-data-view.component.vue";
import DisplayRegistrationView from "@/app/sub-modules/registration/components/display-registration-view.component.vue";
import DisplaySlotBookingView from "@/app/sub-modules/slot-booking/components/display-slot-booking-view.vue";
import DisplayUnloadingShipmentPreNotificationView from "@/app/sub-modules/unloading-shipment-pre-notification/components/display-unloading-shipment-pre-notification.view.vue";
import DisplayProductBasicInformationView from "@/app/sub-modules/basic-information/product/components/display-product-basic-information-view.component.vue";
import DisplayAttachments from "@/app/shared/components/attachment-widget/display-attachments.component.vue";
import DisplayUnLoadingAreaView from "@/app/sub-modules/un-loading-area/components/display-un-loading-area-view.component.vue";
import DisplayWorkingOnTopView from "@/app/sub-modules/working-on-top/components/display-working-on-top-view.component.vue";
import DisplayAvailableEquipmentView from "@/app/sub-modules/available-equipment/components/display-available-equipment-view.component.vue";
import DisplayAvailableEquipmentPackedView from "@/app/sub-modules/available-equipment-packed/components/display-available-equipment-packed-view.component.vue";
import DisplayReceivingTankView from "@/app/sub-modules/receiving-tank/components/display-receiving-tank-view.component.vue";
import DisplayParkingSpaceBasicView from "@/app/sub-modules/parking-space-basic/components/display-parking-space-basic-view.component.vue";
import DisplayInfrastructuresNearbyView from "@/app/sub-modules/infrastructures-nearby/components/display-infrastructures-nearby-view.component.vue";
import DisplayWeighingBridgesView from "@/app/sub-modules/weighing-bridges/components/display-weighing-bridges-view.component.vue";
import DisplayGeneralSafetyRulesView from "@/app/sub-modules/general-safety-rules/components/display-general-safety-rules-view.component.vue";
import DisplayTrafficRelatedRulesView from "@/app/sub-modules/traffic-related-rules/components/display-traffic-related-rules-view.component.vue";
import DisplaySafetyTestView from "@/app/sub-modules/safety-test/components/display-safety-test-view.component.vue";
import DisplayAlarmsAndEmergencyView from "@/app/sub-modules/alarms-and-emargency/components/display-alarms-and-emergency-view.component.vue";
import DisplaySiteIncidentManagementView from "@/app/sub-modules/site-incident-management/components/display-site-incident-management-view.component.vue";
import DisplayULPBasicInformationView from "@/app/sub-modules/basic-information/un-loading-point/components/display-ulp-basic-information-view.component.vue";
import DisplayCompanyBasicInformation from "@/app/shared/components/company-basic-information-widget/company-basic-information.component.vue";
import DisplayReferencesView from "@/app/sub-modules/references/components/display-references-view.component.vue";
import DisplayGeneralCleaningInfoView from "@/app/sub-modules/general-cleaning-info/components/display-general-cleaning-info-view.component.vue";
import DisplayEuropeanCleaningDocumentView from "@/app/sub-modules/european-cleaning-document/components/display-european-cleaning-document-view.component.vue";
import DisplayPreloadConfirmationView from "@/app/sub-modules/preload-confirmation/components/display-preload-confirmation-view.component.vue";
import DisplayVacuumTestConfirmationView from "@/app/sub-modules/vacuum-test-confirmation/components/display-vacuum-test-confirmation-view.component.vue";
import DisplayRequiredEquipmentStateView from "@/app/sub-modules/required-equipment-state/components/display-required-equipment-state-view.component.vue";
import DisplayCleaningOperatorsView from "@/app/sub-modules/cleaning-operators/components/display-cleaning-operators-view.component.vue";
import DisplayProductParametersView from "@/app/sub-modules/product-parameters/components/display-product-parameters-view.component.vue";
import DisplayProductGroupAssignmentView from "@/app/sub-modules/product-group-assignment/components/display-product-group-assignment-view.component.vue";
import DisplayPrlRestrictionsSettingsView from "@/app/sub-modules/prl-restrictions-settings/components/display-prl-restrictions-settings-view.component.vue";
import DisplayDgClassificationView from "@/app/sub-modules/dg-classification/components/display-dg-classification-view.vue";
import DisplayProductAdditionalInformationView from "@/app/sub-modules/additional-information/product/components/display-product-additional-information-view.component.vue";
import DisplaySiteNavigationView from "@/app/sub-modules/site-navigation/components/display-site-navigation-view.component.vue";
import DisplaySpecialProductRequirementsView from "@/app/sub-modules/special-product-requirements/components/display-special-product-requirements-view.vue";
import DisplaySafetyDataSheetView from "@/app/sub-modules/safety-data-sheet/components/display-safety-data-sheet-view.vue";
import DisplaySiteInformation from "@/app/sub-modules/company-information/components/display-site-information-view.component.vue";
export default {
    DisplayFillingView,
    DisplayGeneralInstructionsView,
    DisplaySamplingView,
    DisplaySealingView,
    DisplayWeighingView,
    DisplayDocumentsView,
    DisplayPackagingView,
    DisplayPalletsView,
    DisplayVehicleAndPPESitesView,
    DisplayVehicleAndPPEUlpView,
    DisplayVehicleAndPPEApView,
    DisplayEquipmentPackedView,
    DisplayCompressorView,
    DisplayConnectionsView,
    DisplayHoseView,
    DisplayPumpView,
    DisplayTankView,
    DisplayTemperatureRequirementsView,
    DisplayCargoSecuringView,
    DisplayVapourReturnView,
    DisplayVehicleView,
    DisplayCheckInView,
    DisplayGatesView,
    DisplaySiteNavigationView,
    DisplayAccessRestrictionsView,
    DisplaySiteAdditionalInformationView,
    DisplayPostalAddressView,
    DisplayProcessesView,
    DisplayOpeningHoursView,
    DisplayContactDataView,
    DisplayRegistrationView,
    DisplaySlotBookingView,
    DisplayUnloadingShipmentPreNotificationView,
    DisplayProductBasicInformationView,
    DisplayAttachments,
    DisplayUnLoadingAreaView,
    DisplayWorkingOnTopView,
    DisplayAvailableEquipmentView,
    DisplayAvailableEquipmentPackedView,
    DisplayReceivingTankView,
    DisplayParkingSpaceBasicView,
    DisplayInfrastructuresNearbyView,
    DisplayWeighingBridgesView,
    DisplayGeneralSafetyRulesView,
    DisplayTrafficRelatedRulesView,
    DisplaySafetyTestView,
    DisplayAlarmsAndEmergencyView,
    DisplaySiteIncidentManagementView,
    DisplayULPBasicInformationView,
    DisplayCompanyBasicInformation,
    DisplayReferencesView,
    DisplayGeneralCleaningInfoView,
    DisplayEuropeanCleaningDocumentView,
    DisplayPreloadConfirmationView,
    DisplayVacuumTestConfirmationView,
    DisplayRequiredEquipmentStateView,
    DisplayCleaningOperatorsView,
    DisplayProductParametersView,
    DisplayProductGroupAssignmentView,
    DisplayPrlRestrictionsSettingsView,
    DisplayDgClassificationView,
    DisplayProductAdditionalInformationView,
    DisplaySpecialProductRequirementsView,
    DisplaySafetyDataSheetView,
    DisplaySiteInformation,
};
