import { GeneralInformationModuleId } from "@/app/modules/general-information/general-information.module";
import { suffixComponentName } from "@/app/modular-content/functions/suffix-component-name";
import { createModule } from "@/di/module";
import { SubModuleModule } from "@/app/modular-content/modules/sub-module.module";
import EditOpeningHoursComponent from "@/app/sub-modules/opening-hours/components/edit-opening-hours.component.vue";
import DisplayOpeningHoursComponent from "@/app/sub-modules/opening-hours/components/display-opening-hours.component.vue";
import { ModalModule } from "@/app/modals/modules/modal.module";
import { OpeningHoursModalId } from "@/app/shared/components/opening-hours-widget/opening-hours.constants";
import EditOpeningHoursModalComponent from "@/app/shared/components/opening-hours-widget/edit-opening-hours-modal.component.vue";
import { TranslationPrefixToken } from "@/app/translation/tokens/translation-prefix.token";
import { paramCase } from "change-case";
import { OpeningHoursSubModuleId } from "@/app/sub-modules/opening-hours/opening-hours.constants";
export const OpeningHoursSubModule = (domainId, options) => {
    const id = OpeningHoursSubModuleId;
    const moduleId = GeneralInformationModuleId;
    const componentSuffixes = [domainId, moduleId, id];
    const editComponent = suffixComponentName(EditOpeningHoursComponent, ...componentSuffixes);
    const displayComponent = suffixComponentName(DisplayOpeningHoursComponent, ...componentSuffixes);
    const modalComponent = suffixComponentName(EditOpeningHoursModalComponent, ...componentSuffixes);
    return createModule({
        components: [editComponent, displayComponent, modalComponent],
        imports: [
            ModalModule(OpeningHoursModalId, EditOpeningHoursModalComponent),
            SubModuleModule({
                id,
                moduleId,
                domainId,
                displayTitle: `sub-modules.opening-hours.${paramCase(domainId)}.name`,
                editTitle: `sub-modules.opening-hours.${paramCase(domainId)}.name`,
                configurationTranslationPrefix: `sub-modules.opening-hours.${paramCase(domainId)}`,
                icon: "access_time",
                dataLocalised: false,
                publicInformation: options?.publicInformation,
                editGuards: options?.editGuards,
                components: {
                    edit: editComponent,
                    display: displayComponent,
                },
            }),
        ],
        providers: [{ token: TranslationPrefixToken, useValue: "sub-modules.opening-hours" }],
    });
};
