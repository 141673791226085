import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-e52b9848"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "content-wrapper" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_AppButton = _resolveComponent("AppButton");
    const _component_ButtonGroup = _resolveComponent("ButtonGroup");
    const _component_Modal = _resolveComponent("Modal");
    return (_openBlock(), _createBlock(_component_Modal, {
        class: "sub-module-info-modal",
        "modal-id": _ctx.modalId,
        header: _ctx.headerText,
        showCloseIcon: false
    }, {
        default: _withCtx(() => [
            _createElementVNode("p", _hoisted_1, _toDisplayString(_ctx.subModuleDescription), 1),
            _createVNode(_component_ButtonGroup, null, {
                default: _withCtx(() => [
                    _createVNode(_component_AppButton, { onClick: _ctx.close }, {
                        default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.translate("global.buttons.close")), 1)
                        ]),
                        _: 1
                    }, 8, ["onClick"])
                ]),
                _: 1
            })
        ]),
        _: 1
    }, 8, ["modal-id", "header"]));
}
