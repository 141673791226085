export const updateLocalisedModel = (modelValue, value, selectedDataLanguage) => {
    const model = modelValue ?? [];
    const currentLocalisedText = model?.find((it) => it.languageCode === selectedDataLanguage);
    let updated;
    if (currentLocalisedText) {
        currentLocalisedText.value = value;
        updated = [...model];
    }
    else {
        updated = [...model, { languageCode: selectedDataLanguage, value }];
    }
    return updated;
};
