import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-167eced4"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "display-traffic-related-rules" };
const _hoisted_2 = { class: "section" };
const _hoisted_3 = { class: "row section" };
const _hoisted_4 = { class: "col-6 column-header" };
const _hoisted_5 = { class: "col-6 column-description" };
const _hoisted_6 = { class: "section" };
const _hoisted_7 = { class: "row section" };
const _hoisted_8 = { class: "col-6 column-header" };
const _hoisted_9 = { class: "col-6 column-description" };
const _hoisted_10 = { class: "row section" };
const _hoisted_11 = { class: "col-6 column-header" };
const _hoisted_12 = { class: "col-6 column-description" };
const _hoisted_13 = { class: "row section" };
const _hoisted_14 = { class: "col-6 column-header" };
const _hoisted_15 = { class: "col-6 column-description" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_DisplayAttachments = _resolveComponent("DisplayAttachments");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
                _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.translate("road-traffic-regulations")), 1),
                _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.roadTrafficValidInfo()), 1)
            ]),
            _createElementVNode("div", _hoisted_6, [
                _createVNode(_component_DisplayAttachments, {
                    attachments: _ctx.otherTrafficRegulationsLocalised,
                    title: _ctx.translate('other-regulations')
                }, null, 8, ["attachments", "title"])
            ]),
            _createElementVNode("div", _hoisted_7, [
                _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.translate("dash-cam-restrictions")), 1),
                _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.getValue(_ctx.model?.dashCamRestriction, "DashCamRestriction")), 1)
            ]),
            _createElementVNode("div", _hoisted_10, [
                _createElementVNode("div", _hoisted_11, _toDisplayString(_ctx.translate("speed-monitoring.title")), 1),
                _createElementVNode("div", _hoisted_12, _toDisplayString(_ctx.speedMonitoringInfo), 1)
            ]),
            _createElementVNode("div", _hoisted_13, [
                _createElementVNode("div", _hoisted_14, _toDisplayString(_ctx.translate("speed-monitoring.max-speed-title")), 1),
                _createElementVNode("div", _hoisted_15, _toDisplayString(_ctx.maxSpeedInfo), 1)
            ])
        ])
    ]));
}
