import { resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, createVNode as _createVNode, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = { class: "localised-input" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_InputText = _resolveComponent("InputText");
    const _component_FormError = _resolveComponent("FormError");
    const _component_FormField = _resolveComponent("FormField");
    const _component_AppForm = _resolveComponent("AppForm");
    const _directive_localised_validation = _resolveDirective("localised-validation");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_AppForm, {
            modelValue: _ctx.modelLocalised,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event) => ((_ctx.modelLocalised) = $event))
        }, {
            default: _withCtx(() => [
                _createVNode(_component_FormField, null, {
                    default: _withCtx(() => [
                        _withDirectives(_createVNode(_component_InputText, {
                            modelValue: _ctx.model,
                            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => ((_ctx.model) = $event)),
                            name: "value",
                            placeholder: _ctx.placeholder ?? '',
                            disabled: _ctx.disabled
                        }, null, 8, ["modelValue", "placeholder", "disabled"]), [
                            [_directive_localised_validation]
                        ]),
                        (!_ctx.disabled)
                            ? (_openBlock(), _createBlock(_component_FormError, {
                                key: 0,
                                name: "value"
                            }))
                            : _createCommentVNode("", true)
                    ]),
                    _: 1
                })
            ]),
            _: 1
        }, 8, ["modelValue"])
    ]));
}
